import React from 'react';
import Home from './app/home/index'
import Dashboard from './app/dashboard/dashboard';
import Subscription from './app/subscription/index';
import Login from './app/login/index'
import Signup from './app/login/signup'
// @ts-ignore
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import ChangePassword from './app/changePassword';
import Users from './app/users';
import TermsOfUse from "./app/footer/TermsOfUse";
import TermsOfService from './app/footer/TermsOfService';
import './App.css';
import TermsAndConditions from './app/footer/TermsandCondtions';
import CDTFILES from './app/cdtFiles/indesx';
import ManageCDTUsers from './app/users/cdtusers';
import CDTTransactionsForAdmin from './app/cdtTransactions';
import UpgradeAccount from './app/subscription/upgradeaccount';
// import WEBTransactionsForAdmin from './app/webTransactions/indes';
import UserTransactions from './app/singleuserTransactions/indesx';

const App = (): JSX.Element => {

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <ProtectedRoute path="/dashboard" component={Dashboard} />
          <Route path="/subscription">
            <Subscription />
          </Route>
          <ProtectedRoute path="/home" component={Home} />
          <Route exact path="/" key="login">
            <Redirect to={"/login"} />
          </Route>
          <ProtectedRoute path="/change-password" component={ChangePassword} />
          <ProtectedRoute path="/users" component={Users} />
          <ProtectedRoute path="/cdtfiles" component={CDTFILES} />
          <ProtectedRoute path="/managecdtusers" component={ManageCDTUsers} />
          <ProtectedRoute path="/transactions" component={CDTTransactionsForAdmin} />
          <ProtectedRoute path="/payment-page" component={UpgradeAccount} />
          {/* <ProtectedRoute path="/webtransactions" component={WEBTransactionsForAdmin} /> */}
          <ProtectedRoute path="/usertransactions" component={UserTransactions} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="*">
            <Redirect to={"/login"} />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
