import { SUBSCRIPTION_CONFIG,FILE_SIZE_TYPES } from "../constants/commonConstants";

export const getTheme = () => {
  let theme = "";
  let today = new Date().getHours();
  // If current time is between 7AM to 7PM
  if (today >= 7 && today <= 18) {
    theme = "light";
  } else {
    theme = "light";
  }
  return theme;
};

export const getTimeImage = (val: number) => {
  var value = "";

  if (val >= 0 && val <= 30) {
    value = "red";
  }
  if (val >= 31 && val <= 90) {
    value = "orange";
  }
  if (val > 90) {
    value = "green";
  }

  return value;
};

  export const getUser = () =>
  JSON.parse(sessionStorage.getItem("user_details") || "{}");

  export const capitalizeFirstLetter = (details: string) =>
  details?.charAt(0)?.toUpperCase() + details?.slice(1);

    export const calculateFileSize = (data: any, subscriptionType: any) => {
      const subscriptionDeatils = SUBSCRIPTION_CONFIG.plans.find(
        (record) => record.subscriptionType === subscriptionType
      );
      const subsriptionInput: any = subscriptionDeatils?.totalInputLimit;
      const inputSizeLimit = parseInt(subsriptionInput?.split(" ")[0]);
      const inputSizeType = subsriptionInput?.split(" ")[1];
      let totalSize = 0;
      for (let i = 0; i < data.file.length; i++) {
        let fileSize = getFileSizeInRequireFormat(data.file[i].size, inputSizeType);
        totalSize += fileSize;
      }
      const isLimitExceed = totalSize > inputSizeLimit ? true : false;
      return { isLimitExceed, usedInputLimit: totalSize.toFixed(5) };
    };

    export const getFileSizeInRequireFormat = (sizeInByte: any, type: any) => {
      let fileSize = 0;
      switch (type) {
        case FILE_SIZE_TYPES.TYPE_KB:
          fileSize = sizeInByte / 1024;
          break;
        case FILE_SIZE_TYPES.TYPE_MB:
          fileSize = sizeInByte / (1024 * 1024);
          break;
        case FILE_SIZE_TYPES.TYPE_GB:
          fileSize = sizeInByte / (1024 * 1024 * 1024);
          break;
        case FILE_SIZE_TYPES.TYPE_TB:
          fileSize = sizeInByte / (1024 * 1024 * 1024 * 1024);
          break;
        default:
          break;
      }
      return fileSize;
    };
