
import React from "react";
import './barstyle.css';


export const ProgressBar = (props: { containerbgcolor: any; usedLimit: any; total: any; available: any }) => {
    const { containerbgcolor, usedLimit, total } = props;
    const exceed = (usedLimit / total * 100) > 100;

    const filler = {
        width: (exceed ? '100%' : `${usedLimit / total * 100}%`),
    }
    const container = {
        backgroundColor: containerbgcolor,
    }

    return (
        <div className="containerStyles" style={container} >
            <div className="fillerStyles" style={filler} >
                {!(usedLimit === 0) && <span className='labelStyles'></span>}
                {!exceed && <span className='labelStyles'></span>}
            </div>
        </div>
    );
};




