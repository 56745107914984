import React from "react";
import FooterLogin from ".";
import logo from "../../assets/logo/logo-beta.png";
import "./TermsandConditions.css";

const  TermsAndConditions = () => {
    return (
        <div className="parent-container">
            <header className="header loggedout-header">
                <div className="header-container">
                    <div className="logo">
                        <img alt="header-logo" src={logo} />
                    </div>
                </div>
            </header>
            <div className="terms-container">
            <h1>Terms and Conditions for Freemium Online Subscription</h1>
            <ul className="terms-points">
                <li>
                    <p> <u> Introduction</u>: These terms and conditions govern the use of our online subscription service (the "Service"). By using the Service, you agree to be bound by these terms and conditions.
                         If you do not agree to these terms, please do not use the Service.
                    </p>
                </li>
                <li>
                    <p><u>Types of Accounts</u>: The Service offers three types of accounts: Basic Account (Free), Premium Account, and Custom/Consulting Account.
                          </p>
                </li>
                <li>
                    <p>
                    <u>Basic Account (Free)</u>: The Basic Account is free of charge and is best for curious Evaluators. It provides access to 50 MB of storage for input files and 10 MB of storage for output files, with a status bar showing usage of each storage quota. The Basic Account also has a monthly upload limit on images and along with a monthly analysis limit on images, with a status bar and file count showing usage of the monthly analysis quota. BrainScanology reserves the right to modify these limits as necessary. The monthly analysis limit is calculated every 30 days starting from the day the account is created
                    </p>
                </li>
                <li>
                    <p>
                    <u>Premium Account</u>: The Premium Account is best for Research Labs, Biotech/Healthtech Institutions and incurs a fee. It provides access to 500 MB of storage for input files and 100 MB of storage for output files, with a status bar showing usage of each storage quota. The Premium Account also has a monthly upload limit of 5,000 images and a monthly analysis limit of 5,000 images, with a status bar and file count showing usage of the monthly analysis quota. If the analysis limit is surpassed, an automatic extra charge will incur for incremental units of 500 more analyses. BrainScanology reserves the right to modify these limits as necessary.
                    </p>
                </li>
                <li>
                    <p>
                    <u>Custom/Consulting Account</u>: The Custom/Consulting Account is a paid account that includes a consulting service. It provides access to 1 GB of storage for input files and unlimited analysis, with a status bar and file count showing usage of the monthly analysis quota. The account also includes a 1 GB storage locker for output files, with the option to purchase more, and a status bar showing usage of the storage locker. The consulting service includes weekly meetings, advising, data pre-processing, data analysis, and machine learning development. The admin reserves the right to suspend the account if necessary. BrainScanology reserves the right to modify these limits as necessary. 
                    </p>
                </li>
                <li>
                    <p>
                    <u>Auto-billing</u>: The Service includes auto-billing provisions for late or recurring payments. Overdue charges will incur interest or penalty
                    </p>
                </li>
                <li>
                    <p>
                    <u>Compliance</u>: The Service complies with HIPAA and other medical scanning industry-specific regulations. 
                    </p>
                </li>
                <li>
                    <p>
                    <u>Changes to Terms and Conditions</u>: These terms and conditions may be updated from time to time, and you will receive appropriate notice. The current version of these terms and conditions can be found on our website.  
                    </p>
                </li>
                <li>
                    <p>
                    <u> Refund Policy</u>: No refunds will be issued for consulting tier clients. Subscribers may cancel their subscription at any time with proper advance notice of 30 days.   
                    </p>
                </li>
                <li>
                    <p>
                    <u>  Global Users</u>: The Service has users worldwide, and we comply with the necessary data and privacy standards of different regions and countries.  
                    </p>
                </li>
                <li>
                    <p>
                    <u>User Code of Conduct</u>: Users of the Service are expected to conduct themselves in a manner that is respectful of others and in accordance with the law and may be removed or banned from use at the sole and absolute discretion of the Company.   
                    </p>
                </li>
                <li>
                    <p>
                    <u>Privacy Policy</u>: By using the Service, you agree to our privacy policy, which can be found on our website.
                    </p>
                </li>
                <li>
                    <p>
                    <u>Governing Law</u>: These terms and conditions are governed by the laws of the state of Delaware.  
                    </p>
                </li>
                <li>
                    <p>
                    <u>Entire Agreement</u>: These terms and conditions constitute the entire agreement between you and us regarding the use of the Service.
                    </p>
                </li>
            </ul>
            <h1>Privacy Policy for Freemium Service</h1>
            <p>Last Revised: February 2, 2023</p>
            <ul className="terms-points">
                <li>
                    <p>
                    <u>Introduction</u>: Our freemium service (hereinafter referred to as the "Service") is committed to protecting the privacy and security of personal information of all our users. This Privacy Policy describes how we collect, use, disclose, and protect the personal information of our users. Our Service is compliant with the Health Insurance Portability and Accountability Act (HIPAA). This Privacy Policy should be read in conjunction with our Terms of Service, which governs the overall use of our Service
                    </p>
                </li>
                <li>
                    <p>
                    <u> Scope of this Privacy Policy</u>: This Privacy Policy applies to personal information collected by our Service, regardless of how it is collected or stored. By using our Service, you consent to the collection, use, disclosure, and protection of your personal information as described in this Privacy Policy.
                    </p>
                </li>
                <li>
                    <p>
                    <u> Definition of Personal Information</u>: For the purposes of this Privacy Policy, "personal information" means information that can be used to identify an individual, including but not limited to a person's name, address, email address, telephone number, health information, and any other information that can be linked to a specific individual.
                    </p>
                </li>
                <li>
                    <p>
                    <u>Collection of Personal Information</u>: We collect personal information from our users in a variety of ways, including, but not limited to, the following
                  <ul className="ul-inside">
                  <li>   When a user creates an account with our Service.</li>

                  <li>  When a user submits personal information through our Service in order to receive certain services.</li>

                  <li>  When a user voluntarily provides personal information through our Service.</li>

                 <li>When a user participates in surveys, contests, or promotions offered by our Service.</li>
                </ul>
                    </p>
                </li>
                <li>
                    <p>
                    <u>Use of Personal Information</u>: We use personal information collected through our Service for the following purposes:
<ul className="ul-inside">
<li> To provide the Service to our users.</li>

<li> To improve the Service.</li>

<li> To communicate with our users about the Service, including sending updates, security alerts, and administrative messages.</li>

<li> To send marketing communications to our users about products and services offered by our Service and its partners.</li>

<li> To comply with legal obligations, including but not limited to HIPAA.</li>
</ul>
                    </p>
                </li>
                <li>
                    <p>
                    <u> Disclosure of Personal Information</u>: We do not sell, rent, or otherwise disclose personal information collected through our Service to third parties for their own marketing purposes. We may disclose personal information to third parties in the following limited circumstances:
                 <ul className="ul-inside">
                    <li> To contractors and service providers who need access to such information to carry out work on our behalf.</li>

                    <li>To comply with legal obligations, including but not limited to HIPAA.</li>

                    <li>In the event of a merger, acquisition, reorganization, bankruptcy, or other similar event, personal information may be transferred to a third party as part of the transaction.</li>
                    </ul> </p>
                </li>
                <li>
                    <p>
                    <u>Protection of Personal Information</u>: We take appropriate technical and organizational measures to protect personal information against unauthorized or unlawful processing and against accidental loss, destruction, damage, alteration, or disclosure. These measures include, but are not limited to, encryption, secure socket layer (SSL) technology, and secure storage in controlled facilities.
                    </p>
                </li>
                <li>
                    <p>
                    <u> Accessing and Updating Personal Information</u>: You have the right to access, review, update, and delete your personal information collected through our Service. You may do so by logging into your account and making the necessary changes. If you are unable to make the changes, please contact us and we will assist you.
                    </p>
                </li>
                <li>
                    <p>
                    <u> Retention of Personal Information</u>: We retain personal information for as long as necessary to fulfill the purposes for which it was collected and as required by law.
                    </p>
                </li>
                <li>
                    <p>
                    <u>Changes to this Privacy Policy</u>: We may update this Privacy Policy from time to time. When we do, we will revise the "Last Revised" date at the top of this Privacy Policy.
                    </p>
                </li>
            </ul>
              
            </div>
            <footer >
            <FooterLogin />
            </footer>
        </div>
    );
}

export default TermsAndConditions;
