import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fileCDTCLOCKSCOREOutputUpload,  fileCDTNumberingSCOREOutputUpload,  fileCDTOutputUpload, sendMail, updateCDTDetails } from "../../api/login";
import openNotification from "../../common/Notifications";
import { API_BRAINSCANOLOGY_URL } from "../../constants/apiBaseUrl";
import { IApplicationState } from "../../duck/reducers";
import { UPLOAD_FAILED, UPLOAD_STARTED, UPLOAD_SUCCESS } from "../../duck/types/types";
import { capitalizeFirstLetter } from "../../utils/commonUtils";
import { EMAILS } from "../../constants/commonConstants";



const UploadCDTOutPutFile = (props: any) => {
  
    const { item } = props;

    const { username } =
        useSelector((state) => (state as IApplicationState).app.userData);


    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const buttonDisabled = false;


    const onSubmit = async (data: any) => {
        console.log(data, "@@@@")

        console.log(data.file, "33333333333333", data.file.length)

        let dateofUpload = new Date();
        //request to create data-entry
        const inputFile = document.getElementById(
            "fileDetails"
        ) as HTMLInputElement | null;
        console.log(inputFile)
        if (data.file.length > 0) {
            const inputFile = document.getElementById(
                "fileDetails"
            ) as HTMLInputElement | null;
            console.log(inputFile)
            //API to delete files
            await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFiles`, {
                method: "DELETE",
            }).then((res) => res);

            //delete tmp folder
            await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFolder`, {
                method: "DELETE",
            }).then((res) => res);

            const input = document.getElementById(
                "submit-btn-form"
            ) as HTMLInputElement | null;
            if (input != null) input.disabled = true;

            props.onSubmit();
            dispatch({ type: UPLOAD_STARTED });

            // fileProcessing and upload the file
            if (data.file.length === 1) {
                const formData = new FormData();
                let uName = username;
                //store the user and file and append it
                formData.append("file", data.file[0]);
                formData.append("name", uName);
                //calling the fileProcessing functionality

                await fetch(`${API_BRAINSCANOLOGY_URL}/fileProcessing`, {
                    method: "POST",
                    body: formData,
                }).then(async (res) => res);

                const inputFile = document.getElementById(
                    "fileDetails"
                ) as HTMLInputElement | null;
                if (inputFile != null) inputFile.value = "";

                setTimeout(async () => {
                    await fetch(
                        `${API_BRAINSCANOLOGY_URL}/getFilesAfterProcessing`,
                        {
                            method: "GET",
                        }
                    ).then(async (res) => res);

                   
                    if (props.uploading === "single-cdt-output") {
                        const request = {
                            containerName: item.userName,
                            username: username,
                            folderPath: item.folder_path,
                        };
                        const response = await fileCDTOutputUpload(request);

                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });

                            let obj = { id: item.id, is_output_uploaded : true };
                            await updateCDTDetails(obj);
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "uploaded to cdt with multiple file")
                    }


                    if (props.uploading === "clock-score-output") {
                        const request = {
                            containerName:  item.username,
                            username: username,
                            dateofUpload: dateofUpload
                        };
                       const response = await fileCDTCLOCKSCOREOutputUpload(request);
                        
                        let mailRequest = {};
                        mailRequest = {
                            from: EMAILS.Notifications_id,
                            to: item.email,
                            subject:
                                "CDT OpenMax - Graphed Clock Scores",
                            text: "Hello world ",
                            html:
                                "<p>Dear " +
                                capitalizeFirstLetter(item.username) +
                                ",</p><p>Your CDT OpenMax Clock Analysis Report is available for download. Please login to the CDT Open App to view or download the report. </p><p>Best Regards</p><p>CDT Open Team</p>",
                        };

                        //api for sending mail
                        await sendMail(mailRequest);
                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "uploaded to cdt with multiple file")
                    }


                    if (props.uploading === "Numbering-score-output") {
                        const request = {
                            containerName: item.username,
                            username: username,
                            dateofUpload: dateofUpload
                        };
                       const response = await fileCDTNumberingSCOREOutputUpload(request);
                        
                        let mailRequest = {};
                        mailRequest = {
                            from: EMAILS.Notifications_id,
                            to: item.email,
                            subject:
                                "CDT OpenMax - Graphed Number Writing Scores",
                            text: "Hello world ",
                            html:
                                "<p>Dear " +
                                capitalizeFirstLetter(item.username) +
                                ",</p><p>Your CDT OpenMax Number Writing Analysis Report is available for download. Please login to the CDT Open App to view or download the report. </p><p>Best Regards</p><p>CDT Open Team</p>",
                        };

                        //api for sending mail
                        await sendMail(mailRequest);
                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "single")
                    }
                    console.log("single file last line")

                    if (input != null) input.disabled = false as any;
                }, 4500);
            } else {
                props.onSubmit();
                const formData = new FormData();
                for (var i = 0; i < data.file.length; i++) {
                    formData.append("file", data.file[i]);
                }
                formData.append("name", username);

                await fetch(`${API_BRAINSCANOLOGY_URL}/fileProcessing`, {
                    method: "POST",
                    body: formData,
                }).then(async (res) => res);
                const inputFile = document.getElementById(
                    "fileDetails"
                ) as HTMLInputElement | null;
                if (inputFile != null) inputFile.value = "";

                setTimeout(async () => {
                    await fetch(
                        `${API_BRAINSCANOLOGY_URL}/getFilesAfterProcessing`,
                        {
                            method: "GET",
                        }
                    ).then(async (res) => res);
                  
                    if (props.uploading === "single-cdt-output") {
                        const request = {
                            containerName: item.userName,
                            username: username,
                            folderPath: item.folder_path,
                        };
                        const response = await fileCDTOutputUpload(request);

                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });
                            
                           let obj = { id: item.id, is_output_uploaded : true };
                           await updateCDTDetails(obj);
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "uploaded to cdt with multiple file")
                    }


                    if (props.uploading === "clock-score-output") {
                        const request = {
                            containerName: item.username,
                            username: username,
                            dateofUpload: dateofUpload
                        };
                       const response = await fileCDTCLOCKSCOREOutputUpload(request);
                        
                        let mailRequest = {};
                        mailRequest = {
                            from: EMAILS.Notifications_id,
                            to: item.email,
                            subject:
                                "CDT OpenMax - Graphed Clock Scores",
                            text: "Hello world ",
                            html:
                                "<p>Dear " +
                                capitalizeFirstLetter(item.username) +
                                ",</p><p>Your CDT OpenMax Clock Analysis Report is available for download. Please login to the CDT Open App to view or download the report. </p><p>Best Regards</p><p>CDT Open Team</p>",
                        };

                        //api for sending mail
                        await sendMail(mailRequest);
                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "uploaded to cdt with multiple file")
                    }


                    if (props.uploading === "Numbering-score-output") {
                        const request = {
                            containerName: item.username,
                            username: username,
                            dateofUpload: dateofUpload
                        };
                       const response = await fileCDTNumberingSCOREOutputUpload(request);
                        
                        let mailRequest = {};
                        mailRequest = {
                            from: EMAILS.Notifications_id,
                            to: item.email,
                            subject:
                                "CDT OpenMax - Graphed Number Writing Scores",
                            text: "Hello world ",
                            html:
                                "<p>Dear " +
                                capitalizeFirstLetter(item.username) +
                                ",</p><p>Your CDT OpenMax Number Writing Analysis Report is available for download. Please login to the CDT Open App to view or download the report. </p><p>Best Regards</p><p>CDT Open Team</p>",
                        };

                        //api for sending mail
                        await sendMail(mailRequest);
                        if (response) {
                            dispatch({ type: UPLOAD_SUCCESS });
                        }
                        if (response.isError === true) {
                            dispatch({
                                type: UPLOAD_FAILED,
                                payload: response.message,
                            });
                        }
                        let listBlobs = response.blobArray.filter(
                            (item: any, index: any) => {
                                let obj = "";
                                if (item.includes(".pdf") === true) {
                                    obj = item;
                                }
                                return obj;
                            }
                        );
                        console.log(listBlobs, "uploaded to cdt with multiple file")
                    }
                    
                    console.log( "last line file multiple")

                    if (input != null) input.disabled = false as any;
                }, 2000);
            }
        }
        else {
            let errorObj = {
                type: "error-batch",
                message: "Error",
                description: "Kindly select the files and then submit",
            };
            openNotification(errorObj);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit as any)}>

                <input
                    id="fileDetails"
                    type="file"
                    {...register("file")}
                    accept=".pdf,.png"
                />

                <input
                    type="submit"
                    id="submit-btn-form"
                    disabled={buttonDisabled}
                />
            </form>
        </div>
    )
}


export default UploadCDTOutPutFile;
