/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style.css";
import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer/footer";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../duck/reducers";
import { getCDTRecordsForAdmin, sendMail, updateCDTDetails } from "../../api/login";
import {
    Button,
    Modal,
    Pagination,
    Popconfirm,
    Spin,
} from "antd";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import parallel from "../../assets/logo/parallel.jpeg";
import { ROLES } from "../../constants/users";
import { capitalizeFirstLetter } from "../../utils/commonUtils";
import openNotification from "../../common/Notifications";
import { DELETE_CDT_DASHBOARD_NOTSTARTED, UPLOAD_NOTSTARTED } from "../../duck/types/types";
import { loadState } from "../../duck/reducers/commonReducer";
import { deleteCDTDashBoard, getCDTBlobsItem, getCDTOutputBlobsItem } from "../../duck/actions/dashboardActions";
import CDTDOWNLOADIMAGE from "./downloadimage";
import UploadCDTOutPutFile from "./uploadcdtoutput";
import { EMAILS } from "../../constants/commonConstants";
import CDTFILESOUTPUTFILES from "./manageoutputfiles";


const CDTFILES = () => {

    const {
        deleteCDTDashboardLoadState,
        error,
        uploadFileLoadState
    } = useSelector((state) => (state as IApplicationState).app);

    const dispatch = useDispatch()
    const [loader, setLoader] = useState<any>(true);
    const history = useHistory();
    const [cdtFilesData, setcdtFilesData] = useState([]);
    const [key, setKey] = useState<any>([]);
    const [minIndex, setMinIndex] = useState<any>(0);
    const [maxIndex, setMaxIndex] = useState<any>(0);
    const [current, setCurrent] = useState<any>(1);
    const [isDownloadImageModalVisible, setIsDownloadImageModalVisible] = useState<any>(false);
    const [selectedBlob, setSelectedBlob] = useState({});
    const [isUploadOutputVisible, setIsUploadOutputModalVisible] = useState<any>(false);
    const [uploadPath, setUploadPath] = useState({});
    const [isManageOutputModalVisible, setIsManageOutputModalVisible] = useState<any>(false);
    const pageSize = 10;

    const { username, roles } =
        useSelector((state) => (state as IApplicationState).app.userData);

    const sortedData = (arr: any) => {
        return arr.sort((x: any, y: any) => {
            return new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1;
        });
    };

    const handlePageChange = (page: any) => {
        setCurrent(page + 0);
        setMinIndex((page - 1) * pageSize);
        setMaxIndex(page * pageSize);
        let keyDetails = key.map((item: boolean) =>
            item === true ? false : false
        );
        setKey([...keyDetails]);
    };

    const handleDeleteConfirm = (item: any) => {
        dispatch(deleteCDTDashBoard(item))
    };

    // create Date
    const createDate = (d: any) => {
        let string = "";
        let dateDet = new Date(d);
        let year = dateDet.getFullYear();
        let month = dateDet.getMonth();
        month = month + 1;
        let monthDet = month >= 10 ? month.toString() : "0" + month.toString();
        let date = dateDet.getDate();
        let dateDetails = date >= 10 ? date.toString() : "0" + date.toString();
        let hours = dateDet.getHours();
        let hourDetails = hours >= 10 ? hours.toString() : "0" + hours.toString();
        let min = dateDet.getMinutes();
        let minDetails = min >= 10 ? min.toString() : "0" + min.toString();
        let sec = dateDet.getSeconds();
        let secDetails = sec >= 10 ? sec.toString() : "0" + sec.toString();
        string =
            monthDet +
            "-" +
            dateDetails +
            "-" +
            year +
            " " +
            hourDetails +
            ":" +
            minDetails +
            ":" +
            secDetails;
        return string;
    };

    const approve = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        index: any,
        item: any
    ) => {

        let obj = { id: item.id, isProcessed: true, is_output_uploaded: item.is_output_uploaded };
        await updateCDTDetails(obj);

        let mailRequest = {};
        mailRequest = {
            from: EMAILS.Notifications_id,
            to: item.email,
            subject:
                "Brainscanology - CDT Report ",
            text: "Hello world ",
            html:
                "<p>Dear " +
                capitalizeFirstLetter(item.userName) +
                ",</p><p>Your CDT report for  " +
                item.fileLocation +
                " is  available for download. Please Login to ShapeGenie Mobile App to view the report. </p><p>Best Regards</p><p>Shapegenie Team</p>",
        };

        let successObj = {
            type: "success",
            message: "File marked as Processed. Sent mail to user. ",
        };
        openNotification(successObj);
        //api for sending mail
        await sendMail(mailRequest);
        getCdtData();
    };

    const getCdtData = async () => {

        if (!username) return;
        const filteredCDTDataDetails = await getCDTRecordsForAdmin({
            username: username,
            role: roles ? roles[0] : "ROLE_USER",
        });

        let responseData = sortedData(filteredCDTDataDetails);


        setKey([...key]);
        setMinIndex(0);
        setMaxIndex(pageSize);
        setcdtFilesData(sortedData(responseData));
        setLoader(false);
    };

    const handleDownloadImage = (item: any) => {
        try {
            setIsDownloadImageModalVisible(true);
            setSelectedBlob(item)
            const requestBody = {
                containerName: item.userName,
                folderPath: item.folder_path,
            };
            dispatch(getCDTBlobsItem(requestBody));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
        if (isCopyPasted) {
            history.push("/login");
        }
    }, []);

    useEffect(() => {
        getCdtData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    useEffect(() => {
        getCdtData();
        if (deleteCDTDashboardLoadState === loadState.SUCCESS) {
            let successObj = {
                type: "success",
                message: "File deleted successfully",
            };
            openNotification(successObj);
            dispatch({ type: DELETE_CDT_DASHBOARD_NOTSTARTED });
        } else if (deleteCDTDashboardLoadState === loadState.FAILED) {
            let errorObj = {
                type: "error",
                message: error,
            };
            openNotification(errorObj);
            dispatch({ type: DELETE_CDT_DASHBOARD_NOTSTARTED });
        }
    }, [deleteCDTDashboardLoadState]);

    useEffect(() => {
        getCdtData();
        if (uploadFileLoadState === loadState.SUCCESS) {

            let successObj = {
                type: "success",
                message: "File Uploaded Successfully. Kindly refresh the page",
            };
            openNotification(successObj);
            dispatch({ type: UPLOAD_NOTSTARTED });

        } else if (uploadFileLoadState === loadState.FAILED) {

            let errorObj = {
                type: "error",
                message: error,
            };
            openNotification(errorObj);
            dispatch({ type: UPLOAD_NOTSTARTED });
        }
    }, [uploadFileLoadState]);

    const handleDownloadImageModalCancel = () => {
        setIsDownloadImageModalVisible(false);
    };

    const handleCancel = () => {
        //setBatchValue("");
        setIsUploadOutputModalVisible(false);
        const inputDetails = document.getElementById(
            "fileDetails"
        ) as HTMLInputElement | null;
        if (inputDetails != null) {
            inputDetails.value = "";
        }
    };

    const handleOk = () => {
        setIsUploadOutputModalVisible(false);
    };

    const fileUploadSubmitHandler = () => {
        setIsUploadOutputModalVisible(false);
    }

    const handleUploadCDTOutput = (item: any) => {
        setUploadPath(item)
        setIsUploadOutputModalVisible(true);
    }

    const handleManageOutputModalOpen = (item: any) => {
        try {
            setIsManageOutputModalVisible(true)
            setSelectedBlob(item)
            const requestBody = {
                containerName: item.userName,
                folderPath: item.folder_path,
            };
            dispatch(getCDTOutputBlobsItem(requestBody));
        } catch (err) {
            console.log(err);
        }
    }

    const handleManageOutputModalCancel = () => {
        setSelectedBlob(" ")
        setIsManageOutputModalVisible(false)
    };

    const cdtOutputFileDeleteHandler = () => {
        setIsManageOutputModalVisible(false)
    }

    return (
        <>
            <Header prop={loader} />

            <Modal
                visible={isDownloadImageModalVisible}
                onCancel={handleDownloadImageModalCancel}
                footer={null}
                width="60%"
                centered
                bodyStyle={{ height: "40%" }}
            >
                <CDTDOWNLOADIMAGE item={selectedBlob} />
            </Modal>

            <Modal
                title="Upload CDT OUTPUT File"
                visible={isUploadOutputVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <UploadCDTOutPutFile onSubmit={fileUploadSubmitHandler} item={uploadPath} uploading="single-cdt-output" />
            </Modal>

            <Modal
                visible={isManageOutputModalVisible}
                onCancel={handleManageOutputModalCancel}
                footer={null}
                width="60%"
                centered
                bodyStyle={{ height: "40%" }}
            >
                <CDTFILESOUTPUTFILES item={selectedBlob} onDelete={cdtOutputFileDeleteHandler} />
            </Modal>

            <div className="cdt-files-container">
                <div className="breadcrumb-container">
                    <div className="breadcrumb">
                        <a
                            onClick={() => {
                                history.push("/dashboard");
                            }}
                        >
                            Dashboard
                        </a>
                        &nbsp;/ CDT Files
                    </div>
                    {(loader || deleteCDTDashboardLoadState === loadState.STARTED) && (
                        <div className="loading">
                            <Spin
                                size={"large"}
                                spinning={
                                    loader || deleteCDTDashboardLoadState === loadState.STARTED
                                }
                            />
                            <div className="loading-text">Loading...</div>
                        </div>
                    )}
                    {(uploadFileLoadState === loadState.STARTED) && (
                        <div className="loading-cdt-text">File is getting uploaded. Please do not refresh the page.
                            <div className="spin-cdt">
                                <Spin
                                    size={"large"}
                                    spinning={
                                        uploadFileLoadState === loadState.STARTED
                                    }
                                /></div>
                        </div>
                    )}
                </div>

                {!loader &&
                    cdtFilesData &&
                    cdtFilesData.length > 0 &&
                    cdtFilesData.map(
                        (item: any, index: any) =>
                            index >= minIndex &&
                            index < maxIndex && (
                                <div className="dashboard-row-container">
                                    <div className="thumb-container">
                                        <img
                                            alt="details"
                                            src={
                                                item.fileLocation
                                                    ? item.fileLocation.includes("nii") ||
                                                        item.fileLocation.includes("tif")
                                                        ? item.fileLocation.includes("nii")
                                                            ? nii
                                                            : tif
                                                        : "https://mvpimagesa.blob.core.windows.net/" +
                                                        item.userName +
                                                        "/CDT/" +
                                                        item.folder_path +
                                                        "_input" +
                                                        "/" +
                                                        item.fileLocation
                                                    : parallel
                                            }
                                        />
                                    </div>
                                    <div className="file-detail-container">
                                        <div className="file-details">
                                            <div>
                                                <span className="heading">Batch Name: </span>
                                                <span className="file-text">{item.folder_path}</span>
                                                <span className="heading">Uploaded by: </span>
                                                <span className="file-text">{item.userName}</span>
                                                <span className="heading">Uploaded on: </span>
                                                <span className="file-text">
                                                    {createDate(item.createdAt)}
                                                </span>
                                                <span className="heading">First Name: </span>
                                                <span className="file-text">{item?.firstname ? item?.firstname : '---'}</span>
                                                {/* <span className="file-text">{item.userName}/CDT/{item.folder_path}_output</span> */}
                                                <span className="heading">Email: </span>
                                                <span className="file-text">{item.email}</span>
                                                <span className="heading">Type: </span>
                                                <span className="file-text">{item.type}</span>
                                            </div>
                                        </div>
                                        <div className="button-container">

                                            {roles?.includes(ROLES.ROLE_ADMIN) ? (
                                                <>
                                                    {/* Mark Processing Complete button /? item.type : "Clock"*/}
                                                    {!item.isProcessed && item.is_output_uploaded && (

                                                        <Button
                                                            type="primary"
                                                            className="process-button bs-common-button"
                                                            key={"mark-processing-done-btn-" + index}
                                                            onClick={(e) => {
                                                                approve(e, index, item);
                                                            }}
                                                        >
                                                            Mark Processing Complete
                                                        </Button>
                                                    )}
                                                </>
                                            ) : ""
                                            }
                                            {item.id && !item.isProcessed && (

                                                <Button
                                                    key={"download-image-btn-" + index}
                                                    type="primary"
                                                    className="process-button bs-common-button download-img-btn"
                                                    onClick={(e) => {
                                                        handleDownloadImage(item);
                                                    }}
                                                >
                                                    Download Image
                                                </Button>
                                            )}
                                            {!item.is_output_uploaded && (
                                                <Button
                                                    key={"upload-output-btn-" + index}
                                                    type="primary"
                                                    className="process-button bs-common-button download-img-btn upload-output-bttn"
                                                    onClick={(e) => {
                                                        handleUploadCDTOutput(item);
                                                    }}
                                                >
                                                    Upload Output Files
                                                </Button>
                                            )}

                                            {item.id && (
                                                <Popconfirm
                                                    title="Are you sure to delete this file?"
                                                    className="popcon-no-style"
                                                    onConfirm={(e) =>
                                                        handleDeleteConfirm(item)
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="topLeft"
                                                >
                                                    <Button
                                                        key={"delete-cdt-record-btn-" + index}
                                                        type="primary"
                                                        className="button-focus delete-button ant-btn css-1s3dcof ant-btn-dangerous"
                                                    >
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            )}
                                            {item.id && !item.isProcessed && (
                                                <Button
                                                    key={"manage-output-button-demo-" + index}
                                                    type="primary"
                                                    className="process-button bs-common-button download-img-btn"
                                                    onClick={(e) => {
                                                        handleManageOutputModalOpen(item);
                                                    }}
                                                    id={"manage-output_" + item.id}
                                                >
                                                    Manage Output Files
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}

                {!loader && cdtFilesData && cdtFilesData.length <= 0 && (
                    <div className="para">
                        No Records Found
                    </div>
                )}
                {/* pagination*/}
                {loader === false && cdtFilesData.length > 0 && (
                    <div className="pagination-container">
                        <Pagination
                            pageSize={pageSize}
                            showSizeChanger={false}
                            current={current}
                            total={cdtFilesData.length}
                            onChange={handlePageChange}
                            className="ant-pagination-dashboard"
                        />
                    </div>
                )}
            </div>
            {!loader && <Footer />}
        </>
    );
}

export default CDTFILES;