/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FileExcelOutlined } from "@ant-design/icons";
import  "./style.css"
import { loadState } from "../../duck/reducers/commonReducer";
import { Spin } from "antd";
import { IApplicationState } from "../../duck/reducers";
import { useSelector } from "react-redux";
//import zipIcon from "../../assets/images/zip-icon-light.svg";

const PostProcessResults = (props: any) => {
  const { item } = props;
  const [csvData, setCSVData] = useState<any>([]);
  //const [zipData, setZipData] = useState<any>([]);

  const { postProcessBlobs,postprocessBlobLoadState } = useSelector(
    (state) => (state as IApplicationState).app
  );

  useEffect(() => {
          getPostProcessBlobsData();
  }, item);
 
  useEffect(() => {
    if ( postprocessBlobLoadState === loadState.SUCCESS) getPostProcessBlobsData();
  }, [postprocessBlobLoadState]);

 const getPostProcessBlobsData = async () => {
 
  let responseData = postProcessBlobs.filter(
    (item: any) =>
      !item.includes(".pdf") &&
      !item.includes(".zip") &&
      !item.includes(item.fileLocation+"_postprocess/postprocessFolderCretionFile")
  );
  if (responseData.length === 0) return;


  let csvDataDetails = postProcessBlobs.filter((item: any) =>
    item.includes(".xlsx")
  );

  // let zipDataDetails = postProcessBlobs.filter((item: any) =>
  // item.includes(".zip")
  // );

  //setZipData(zipDataDetails);
  setCSVData(csvDataDetails)

};


  return (
    <>
     {postprocessBlobLoadState === loadState.STARTED && (
        <div className="main_container spinner-container">
          <Spin size={"large"} />
           <p>Loading...</p>
        </div>
      )}
    {postprocessBlobLoadState === loadState.SUCCESS && (
    <div className="post-process-download-container">
      <div className="msg-container">
        {
            csvData && csvData.length > 0 &&
            (<div className="post-page-message">  
            You can download Data Analysis results of file: {item.fileLocation} by clicking on the icon.
           </div>)
        }
      {
        csvData.length === 0 &&
        (<div className="post-page-message">  
          You haven't done data analysis for this file. Please analyze them and come back for the results.
        </div>)
        }
            <div className="xl-download-container">
                  <a
                     title="Download CSV"
                     href={
                       "https://mvpimagesa.blob.core.windows.net/" +
                       item.userName +
                       "/" +
                       csvData[0]
                     }
                     style={{
                       display:
                         csvData && csvData.length > 0 ? "block" : "none",
                     }}
                     download={csvData[0]}
                  >
                    <FileExcelOutlined />
           
                  </a>
                  {/* <a
                      title="Download Zip"
                      href={
                        "https://mvpimagesa.blob.core.windows.net/" +
                        item.userName +
                        "/" +
                        zipData[0]
                      }
                      style={{
                        display:
                          zipData && zipData.length > 0 ? "block" : "none",
                        margin: "2px",
                      }}
                      download={zipData[0]}
                    >
                      <img
                        alt="downloadIcon"
                        src={zipIcon}
                      ></img>
                  </a> */}
                </div>
    </div>  
 
    </div>  
    )} 
    </>
  );
};

export default PostProcessResults;
