/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Col, DatePicker, Pagination, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import "./style.css";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import pdf from "../../assets/images/icons8-pdf.png";
import parallel from "../../assets/logo/parallel.jpeg";
import { getCDTUserFilesToAdminBlobs, getCDTUserFilesToAdminByDate } from "../../api/login";
import { FilePdfOutlined } from "@ant-design/icons";
import PDFPreview from "./PDFviewer";
import openNotification from "../../common/Notifications";
import { CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED } from "../../duck/types/types";
import moment from "moment";
import type { DatePickerProps } from 'antd';


const CDTFilesofaUserToAdmin = (props: any) => {

    const dispatch = useDispatch()
    const { item } = props;
    const [enlargeImage, setEnlargeImage] = useState<string>();
    const blobpageSize = 10;
    const [currentblob, setCurrentblob] = useState<any>(1);
    const [minIndexblob, setMinIndexblob] = useState<any>(0);
    const [maxIndexblob, setMaxIndexblob] = useState<any>(0);
    const [selecteddropdownvalue, setSelectedDropDownValue] = useState<any>('');
    const [dropdownoptionselected, setDropdownOptionSelected] = useState<any>();
    const [dataToDisplay, setDataToDisplay] = useState<any>([]);
    const [apiCalling, setApiCalling] = useState(false);
    const [pdfPath, setPdfPath] = useState<string>();
    const [currentblobinmap, setCurrentblobinmap] = useState<string>();
    const [selectedFromDate, setSelectedFromDate] = useState<any>(new Date())
    const [selectedToDate, setSelectedToDate] = useState<any>(new Date())

    const { userCdtFilesBlobs, usercdtFilesBlobLoadState, error } = useSelector(
        (state) => (state as IApplicationState).app
    );

    useEffect(() => {
        if (usercdtFilesBlobLoadState === loadState.SUCCESS) {
            setDataToDisplay(userCdtFilesBlobs);
            getBlobsData()
        } else if (usercdtFilesBlobLoadState === loadState.FAILED) {
            let errorObj = {
                type: "error",
                message: error,
            };
            openNotification(errorObj);
            dispatch({ type: CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED });
        }
    }, [usercdtFilesBlobLoadState]);

    const getBlobsData = () => {

        setMinIndexblob(0);
        setMaxIndexblob(blobpageSize);
        setEnlargeImage(" ");
        setCurrentblob(1);

        if (dataToDisplay.length !== 0) {
            if (dataToDisplay[0].includes("pdf")) {
                let enlargedImage = userCdtFilesBlobs && userCdtFilesBlobs.length > 0
                    ? "https://mvpimagesa.blob.core.windows.net/" +
                    item.username +
                    "/" +
                    dataToDisplay[0]
                    : parallel;
                setEnlargeImage(enlargedImage);
            }
            else {
                let enlargedImage = dataToDisplay[0].includes("nii")
                    ? nii
                    : dataToDisplay[0].includes("tif")
                        ? tif
                        : userCdtFilesBlobs && userCdtFilesBlobs.length > 0
                            ? "https://mvpimagesa.blob.core.windows.net/" +
                            item.username +
                            "/" +
                            dataToDisplay[0]
                            : parallel;
                setEnlargeImage(enlargedImage);
            }
        }
    };

    const onImageClick = async (
        event: React.MouseEvent<HTMLElement>,
        index: any
    ) => {
        setEnlargeImage(" ");
        let imagePath: string = event.currentTarget.getAttribute("src") || "";
        setEnlargeImage(imagePath);
    };

    const handlePageChangeBlob = (page: any) => {
        setCurrentblob(page + 0);
        setMinIndexblob((page - 1) * blobpageSize);
        setMaxIndexblob(page * blobpageSize);
    };

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        // { value: "1", key: "1", label: "Cdt Open upload" }

        setSelectedDropDownValue(value.value)
        setDropdownOptionSelected(value.label)
    };

    const handlefilerequest = async () => {
        setSelectedToDate(moment(new Date()))
        setSelectedFromDate(moment(new Date()))
        setApiCalling(true);
        setPdfPath("")
        const response = await getCDTUserFilesToAdminBlobs({
            typeofFile: dropdownoptionselected,
            containerName: item.username,
        });

        let responseData = response.fileArray.filter(
            (item: any) =>
                !item.includes(".csv") &&
                !item.includes(".zip") &&
                !item.includes("/cdtoutputFolderCretionFile")
        );

        if (responseData.length === 0) {
            setDataToDisplay(responseData)
            getBlobsData();
            setApiCalling(false);
            setEnlargeImage(" ");
            return;
        }
        setDataToDisplay(responseData)
        getBlobsData();
        setApiCalling(false);
        setEnlargeImage(" ");
    }

    const handleDateSubmit = async () => {
        
        if (dropdownoptionselected) {
            let startDate = selectedFromDate;
            let endDate: Date = selectedToDate;
            let datePlusone = new Date(endDate);
            datePlusone.setDate(datePlusone.getDate() + 1);
            let b = new Date(datePlusone)
            endDate = b;

            if (startDate === endDate) {
                let dateDet = new Date(endDate);
                dateDet.setDate(dateDet.getDate() + 1);
                let b = new Date(dateDet)
                endDate = b;
            }

            let obj3 = {
                containerName: item.username,
                typeofFile: dropdownoptionselected,
                Fromdate: startDate,
                Todate: endDate
            }

            setApiCalling(true);
            setPdfPath("")
            const response = await getCDTUserFilesToAdminByDate(obj3);

            let responseData = response.fileArray.filter(
                (item: any) =>
                    !item.includes(".csv") &&
                    !item.includes(".zip") &&
                    !item.includes("/cdtoutputFolderCretionFile")
            );

            if (responseData.length === 0) {
                setDataToDisplay(responseData)
                getBlobsData();
                setApiCalling(false);
                setEnlargeImage(" ");
                return;
            }
            setDataToDisplay(responseData)
            getBlobsData();
            setApiCalling(false);
            setEnlargeImage(" ");
        }
        else {
            let errorObj = {
                type: "error",
                message: "Kindly select a File Type from dropdown. ",
            };
            openNotification(errorObj);
        }
    }

    const dateFromHandleChange: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedFromDate(new Date(dateString))
    };

    const dateToHandleChange: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedToDate(new Date(dateString))
    };

    return (
        <>

            {(usercdtFilesBlobLoadState === loadState.STARTED) && (
                <div className="main_container spinner-container">
                    <Spin size={"large"} />
                    <p>Loading...</p>
                </div>
            )}
            {usercdtFilesBlobLoadState === loadState.SUCCESS && (
                <div className="main_container preview-pre-process-container">
                    <div className="msg-cdt-container">
                        <div className="result-page-message">
                            Please select type of files you want to be dispalyed.
                            <Select
                                labelInValue
                                className="reset-dropdown"
                                defaultValue={{ value: '0', label: 'Select from dropdown' }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: 'CDT Open upload',
                                    },
                                    {
                                        value: '2',
                                        label: 'Graphed Clock Score request',
                                    }, {
                                        value: '3',
                                        label: 'Graphed Numbering Score request',
                                    }
                                ]}
                            />
                            <Button className="request-button" onClick={() => handlefilerequest()}>Request Files</Button>
                        </div>
                        <div className="calendar-container">
                            <div className="datepicker-container">
                                <div>
                                    <DatePicker
                                        value={moment(selectedFromDate)}
                                        onChange={dateFromHandleChange}
                                        allowClear={false}
                                    />
                                </div>
                                <div>
                                    <DatePicker
                                        value={moment(selectedToDate)}
                                        onChange={dateToHandleChange}
                                        allowClear={false}
                                    />
                                </div>

                            </div>
                            <div className="calendar-button">
                                <Button type="primary" id="calendarBtn" onClick={handleDateSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </div>

                        {enlargeImage !== " " && (
                            <div className="zipdownload-container">
                                {/* <a
                    title="Download Zip"
                    href={zipPath}
                    download={
                      item.fileLocation +
                      "_preprocess/zip/" +
                      item.fileLocation +
                      "_preprocess.zip"
                    }
                    style={{
                      display:
                        zipData && zipData.length > 0 ? "block" : "none",
                    }}
                    target="_self"
                  >
                       <img
                        alt="downloadIcon"
                        style={{ width: "30px", height: "28px" }}
                        src={zipIcon}
                      ></img>
                  </a> */}
                            </div>
                        )}
                    </div>
                    <Row id="main_page_div">
                        {apiCalling && (
                            <div className="main_container spinner-container">
                                <Spin size={"large"} />
                                <p>Loading...</p>
                            </div>
                        )}
                        {!apiCalling && <Col
                            style={{ border: "1px solid #63666A" }}
                            id="first_main_Card"
                            span={8}
                        >
                            <div>
                                <div
                                    style={{
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                        fontWeight: "bold",
                                        color: "#63666A",
                                    }}
                                    className="textStyle textDarkGary"
                                >
                                    Files of this user
                                </div>
                                {(dataToDisplay.length === 0) &&
                                    <p> There are no files under this section/within the selected dates for this user.</p>
                                }
                                {(dataToDisplay.length !== 0) &&
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {dataToDisplay.map(
                                            (blob: any, index: any) =>

                                                index >= minIndexblob &&
                                                index < maxIndexblob && (
                                                    <Row
                                                        style={{ marginBottom: "5px", cursor: "pointer" }}
                                                        key={index}
                                                    >
                                                        <Col>
                                                            <img
                                                                alt="nii/pdf"
                                                                src={
                                                                    blob.includes("nii") || blob.includes("tif") || blob.includes(".pdf")
                                                                        ? (blob.includes("nii")
                                                                            ? nii
                                                                            : blob.includes(".tif") ? tif : pdf)
                                                                        : "https://mvpimagesa.blob.core.windows.net/" +
                                                                        item.username +
                                                                        "/" +
                                                                        blob
                                                                }
                                                                id="imageStyle"
                                                                onClick={(e) => {
                                                                    setCurrentblobinmap(blob)
                                                                    setPdfPath(" ")
                                                                    setTimeout(() => {
                                                                        let currentpdf = "https://mvpimagesa.blob.core.windows.net/" +
                                                                            item.username +
                                                                            "/" +
                                                                            blob;
                                                                        setPdfPath(currentpdf);
                                                                    })
                                                                    onImageClick(e, index);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                        )}
                                    </div>
                                }
                            </div>
                            <div>
                                <Pagination
                                    pageSize={blobpageSize}
                                    showSizeChanger={false}
                                    current={currentblob}
                                    total={dataToDisplay.length}
                                    onChange={handlePageChangeBlob}
                                    style={{ bottom: "0px" }}
                                />
                            </div>
                        </Col>
                        }
                        {/*2nd  card column */}
                        <Col id="secondCardPreprocess" span={15}>

                            {(dataToDisplay.length !== 0) &&
                                <div style={{ padding: "11px", textAlign: "center" }}>

                                    <div className="download-pdf">
                                        <a
                                            title="Download Image/pdf"
                                            href={pdfPath}
                                            download={
                                                "https://mvpimagesa.blob.core.windows.net/" +
                                                item.username +
                                                "/" +
                                                currentblobinmap
                                            }
                                            style={{
                                                display:
                                                    pdfPath && pdfPath.length > 0 ? "block" : "none",
                                            }}
                                            target="_self"
                                        >
                                            <FilePdfOutlined />
                                        </a>
                                    </div>

                                    {enlargeImage && !enlargeImage?.includes("pdf") && enlargeImage !== " " && !currentblobinmap?.includes("pdf") && (
                                        <img
                                            alt="enlargeImage"
                                            src={enlargeImage}
                                            id="enlargeImage"
                                        />
                                    )}
                                    {!enlargeImage && <Spin size={"large"} />}
                                    {enlargeImage === " " && (
                                        <span>
                                            Please Select a file to take a preview.
                                        </span>
                                    )}
                                    &emsp;

                                    {pdfPath?.includes("pdf") &&
                                        <div className="pdf-preview-container">
                                            <PDFPreview pdfURL={pdfPath} />
                                        </div>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CDTFilesofaUserToAdmin;
