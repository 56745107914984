/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Pagination, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";

import downloadIcon from "../../assets/images/export-csv.svg";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getListBlobs } from "../../api/login";
import parallel from "../../assets/logo/parallel.jpeg";
import zipIcon from "../../assets/images/zip-icon-light.svg";

import "./s_style.css";
import Header from "../header";
import Footer from "../footer/footer";
import { VIEW_FILE_DETAILS } from "../../duck/types/types";

const { Title } = Typography;

const Home = () => {
  const [fileDetails] = useSelector(
    (state: any) => [state?.app?.fileDetails],
    shallowEqual
  );

  const dispatch = useDispatch()
  const [enlargeImage, setEnlargeImage] = useState<string>(parallel);
  const [fileName, setFileName] = useState<any>("");
  const [blobData, setBlobData] = useState<any>([]);
  const [pdfData, setPdfData] = useState<any>([]);
  const [csvData, setCSVData] = useState<any>([]);
  const [zipData, setZipData] = useState<any>([]);
  const [current, setCurrent] = useState<any>(1);
  const [minIndex, setMinIndex] = useState<any>(0);
  const [maxIndex, setMaxIndex] = useState<any>(0);
  const [currentPdf, setCurrentPdf] = useState<any>(1);
  const [minIndexPdf, setMinIndexPdf] = useState<any>(0);
  const [maxIndexPdf, setMaxIndexPdf] = useState<any>(0);
  const [loader] = useState<any>(true);
  const history = useHistory();
  const pageSize = 8;
  const pageSizeResult = 1;
  

  // create Date
  const createDate = (d: any) => {
    let string = "";
    let dateDet = new Date(d);
    let year = dateDet.getFullYear();
    let month = dateDet.getMonth();
    month = month + 1;
    let monthDet = month >= 10 ? month.toString() : "0" + month.toString();
    let date = dateDet.getDate();
    let dateDetails = date >= 10 ? date.toString() : "0" + date.toString();
    let hours = dateDet.getHours();
    let hourDetails = hours >= 10 ? hours.toString() : "0" + hours.toString();
    let min = dateDet.getMinutes();
    let minDetails = min >= 10 ? min.toString() : "0" + min.toString();
    let sec = dateDet.getSeconds();
    let secDetails = sec >= 10 ? sec.toString() : "0" + sec.toString();
    string =
      monthDet +
      "-" +
      dateDetails +
      "-" +
      year +
      " " +
      hourDetails +
      ":" +
      minDetails +
      ":" +
      secDetails;
    return string;
  };

  const getBlobData = async (brainData: any) => {
    const response = await getListBlobs({
      fileLocation: brainData.fileLocation,
      containerName: brainData.userName,
    });
    let responseData = response.fileArray.filter(
      (item: any) =>
        !item.includes(".pdf") &&
        !item.includes(".csv") &&
        !item.includes(".zip") &&
        !item.includes(brainData.fileLocation+"_output/output_details")
    );
    if (responseData.length === 0) return;

    let pdfDataDetails = response.fileArray.filter((item: any) =>
      item.includes(".pdf")
    );
    let csvDataDetails = response.fileArray.filter((item: any) =>
      item.includes(".csv")
    );
    let zipDataDetails = response.fileArray.filter((item: any) =>
      item.includes(".zip")
    );

    setFileName(responseData[0]);
    setBlobData(responseData);
    
    setMinIndex(0);
    setMaxIndex(pageSize);
    setPdfData(pdfDataDetails);
    setZipData(zipDataDetails);
    setMinIndexPdf(0);
    setMaxIndexPdf(pageSizeResult);
    setCSVData(csvDataDetails);

    let enlargedImage = responseData[0].includes("nii")
      ? nii
      : responseData[0].includes("tif")
      ? tif
      : responseData[0].includes("pdf")
      ? parallel
      : responseData && responseData.length > 0
      ? "https://mvpimagesa.blob.core.windows.net/" + 
        brainData.userName + "/" +
        responseData[0]
      : parallel;
    setEnlargeImage(enlargedImage);
  };

  let  brainDataDetails:any = localStorage.getItem("filedata");
  const fileFromLocal = JSON.parse(brainDataDetails)
  const file = fileFromLocal.brainDataDetails

  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isCopyPasted) {
      history.push("/login");
    }else{
      dispatch({ type: VIEW_FILE_DETAILS, payload: file });
      getBlobData(file);
    }

  }, []);

  const handlePageChange = (page: any) => {
    setCurrent(page + 0);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };
  const handlePdfChange = (page: any) => {
    setCurrentPdf(page);
    setMinIndexPdf((page - 1) * pageSizeResult);
    setMaxIndexPdf(page * pageSizeResult);
  };

  const onImageClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: any
  ) => {
    setEnlargeImage(" ");
    setFileName(blobData[index]);
    let imagePath: string = event.currentTarget.getAttribute("src") || "";
    setEnlargeImage(imagePath);
  };
  
  return (
    <>
      <Header prop={loader} />
      <div className="dashboard-container">
        <div className="breadcrumb-container">
          <div className="breadcrumb">
            <a
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </a>
            &nbsp;/ {fileDetails?.fileLocation ?? ""}
          </div>
        </div>
        <div className="result-page-message">In this preview window, images may be skewed and not look exactly as they are.</div>
        <div className="main_container view-result-container">
          <Row id="main_page_div">
            {/*first card column */}

            <Col
              style={{ border: "1px solid #63666A" }}
              id="first_main_Card"
              span={6}
            >
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#63666A",
                  }}
                  className="textStyle textDarkGary"
                >
                  Original Uploaded Images (Not the Pre-Processed Images)
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {blobData.map(
                    (item: any, index: any) =>
                      index >= minIndex &&
                      index < maxIndex && (
                        <Row
                          style={{ marginBottom: "5px", cursor: "pointer" }}
                          key={index}
                        >
                          <Col>
                            <img
                              alt="nii"
                              src={
                                item.includes("nii") || item.includes("tif")
                                  ? item.includes("nii")
                                    ? nii
                                    : tif
                                  :
                                   "https://mvpimagesa.blob.core.windows.net/" +
                                   fileDetails?.userName +
                                    "/" +
                                    item
                              }
                              id="imageStyle"
                              onClick={(e) => {
                                onImageClick(e, index);
                              }}
                            />
                          </Col>
                        </Row>
                      )
                  )}
                </div>
              </div>
              <div>
                <Pagination
                  pageSize={pageSize}
                  showSizeChanger={false}
                  current={current}
                  total={blobData.length}
                  onChange={handlePageChange}
                  style={{ bottom: "0px" }}
                />
              </div>
            </Col>

            {/*2nd  card column */}
            <Col id="secondCard" span={8}>
              <div style={{ padding: "11px" }}>
                <img alt="enlargeImage" src={enlargeImage} id="enlargeImage" />
                {enlargeImage.includes("NII") && (
                  <div className="text"> This above file is Nifti file.</div>
                )}
                {enlargeImage.includes("TIF") && (
                  <div className="text"> This above file is TIF file.</div>
                )}
                &emsp;
                <div className="textStyle ">
                  <span>
                    <strong style={{ color: "#4D4D4D" }}>File Name: </strong>{" "}
                    <strong style={{ color: "#3468B8" }}>{fileName}</strong>
                  </span>
                  <br />
                  <span>
                    <strong style={{ color: "#4D4D4D" }}>
                      Processing Type:
                    </strong>{" "}
                    <strong style={{ color: "#3468B8" }}>
                      {fileDetails?.processingType}
                    </strong>
                  </span>
                  <br></br>
                  <span>
                    <strong style={{ color: "#4D4D4D" }}>Uploaded By: </strong>
                    {fileDetails?.userName ?? ""}
                  </span>
                  <br></br>
                  <span>
                    <strong style={{ color: "#4D4D4D" }}>Uploaded On:</strong>{" "}
                    {createDate(fileDetails?.createdAt)}
                  </span>
                </div>
              </div>
            </Col>
            {/*3nd  card column */}
            <Col
              id="thirdColumn"
              style={{ border: "1px solid #63666A" }}
              span={8}
            >
              <Card
                title={
                  <Title level={1} style={{ fontSize: "20px", color: "white" }}>
                    Results
                  </Title>
                }
                key="1"
                style={{
                  backgroundColor: "white",
                  color: "#63666A",
                  position: "relative",
                }}
                extra={
                  <div style={{ display: "flex" }}>
                    <a
                      title="Download Zip"
                      href={
                        "https://mvpimagesa.blob.core.windows.net/" +
                        fileDetails.userName +
                        "/" +
                        zipData[0]
                      }
                      style={{
                        display:
                          zipData && zipData.length > 0 ? "block" : "none",
                        margin: "2px",
                      }}
                      download={zipData[0]}
                    >
                      <img
                        alt="downloadIcon"
                        src={zipIcon}
                      ></img>
                    </a>
                    <a
                      title="Download CSV"
                      href={
                        "https://mvpimagesa.blob.core.windows.net/" +
                        fileDetails.userName +
                        "/" +
                        csvData[0]
                      }
                      style={{
                        display:
                          csvData && csvData.length > 0 ? "block" : "none",
                      }}
                      download={csvData[0]}
                    >
                      <img
                        alt="downloadIcon"
                        style={{ width: "32px", height: "32px" }}
                        src={downloadIcon}
                      ></img>
                    </a>
                  </div>
                }
              >
                {pdfData && pdfData.length > 0 ? (
                  <div>
                    {pdfData.map(
                      (item: any, index: any) =>
                        index >= minIndexPdf &&
                        index < maxIndexPdf && (
                          <div key={index}>
                            <embed
                              type="text/html"
                              src={
                                "https://mvpimagesa.blob.core.windows.net/" +
                                fileDetails.userName +
                                "/" +
                                item
                              }
                              width="400px"
                              height="550px"
                            ></embed>
                          </div>
                        )
                    )}
                  </div>
                ) : (
                  <div>
                   Click on the above icons to download the files.
                  </div>
                )}

                {pdfData.length > 0 && (
                  <Pagination
                    pageSize={pageSizeResult}
                    showSizeChanger={false}
                    current={currentPdf}
                    total={pdfData.length}
                    onChange={handlePdfChange}
                    style={{ bottom: "0px" }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
