/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Input, Space, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import "./styles.css";
import Header from "../header";
import { useHistory } from "react-router-dom";
import { getAllUSERTransactions } from "../../duck/actions/commonActions";
import Footer from "../footer/footer";
import { format } from 'date-fns';
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";


const UserTransactions = (props: any) => {
    const [loader] = useState<any>(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const [allTransactions, setAllTransactions] = useState(false)


    const { userTransactionsLoadState, userTransactions, userData } =
        useSelector((state) => (state as IApplicationState).app);

    // Custom render function for 'date' columns
    const renderDate = (date: any) => {
        if (date === null || date === '') {
            return <div style={{ fontSize: '12px' }}>{" "}</div>;

        } else {
            let formattedDate = new Date(date * 1000)
            const dateformat = format(new Date(formattedDate), 'yyyy/MM/dd');
            return <div style={{ fontSize: '12px', width: '70px' }}>{dateformat}</div>;
        }
    };

    // Custom render function for 'amount' column to display with currency
    const renderAmount = (record: any) => {
        if (record.amount === null) {
            return <div style={{ fontSize: '12px' }}>{"no date available"}</div>;
        } else {
            let amountWithCurrency = (record.currency === "usd") ? "\u0024 " :
                (record.currency === "inr" ? "\u20B9 " : record.currency)

            amountWithCurrency = amountWithCurrency + record.amount
            return <div style={{ fontSize: '12px' }}>{amountWithCurrency}</div>;
        }
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username', //  key of  data from Redux
            sorter: (a: { username: string; }, b: { username: string; }) => a.username.localeCompare(b.username),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search  UserName"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => {
                            clearFilters()
                            return confirm();
                        }} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : "black" }} />,
            onFilter: (value: any, record: { username: any }) => record.username.toLowerCase().includes(value.toLowerCase()),
            // The onFilter function is used to perform the search based on the 'username' column
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (record: any) => record?.toLowerCase(),
        },
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            width: 100,
        },
        {
            title: 'Order Date',
            dataIndex: 'transaction_time',
            render: (date: any, record: any) => renderDate(date),
            sorter: (a: { transaction_time: moment.MomentInput; }, b: { transaction_time: moment.MomentInput; }) =>
                moment(a.transaction_time).unix() - moment(b.transaction_time).unix(),
        },
        {
            title: 'Application',
            dataIndex: 'subscription_type'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount: any, record: any) => renderAmount(record),

        },
        {
            title: 'Confirmation',
            dataIndex: 'receipt_url',
            render: (text: any) => (
                <Tooltip title={`Receipt: ${text}`}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Purchase Date',
            dataIndex: 'current_period_start',
            render: (date: any, record: any) => record?.status === 'cancelled' ? renderDate('') : renderDate(date),
        },
        {
            title: 'Renewal date',
            dataIndex: 'current_period_end',
            render: (date: any, record: any) => record?.status === 'cancelled' ? renderDate('') : renderDate(date),
        },

    ];

    useEffect(() => {
        const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
        if (isCopyPasted) {
            history.push("/login");
        }
    }, []);

    useEffect(() => {
        if (userData.username) {
            dispatch(getAllUSERTransactions({ username: userData.username }));
        }
    }, [userData]);


    return (
        <>
            <Header prop={loader} />
            <div className="users-container">
                <div className="breadcrumb-container">
                    <div className="breadcrumb">
                        <a
                            onClick={() => {
                                history.push("/dashboard");
                            }}
                        >
                            Dashboard
                        </a>
                        &nbsp;/ Transactions

                    </div>
                </div>
                {userTransactionsLoadState === loadState.STARTED && (
                    <div className="loading">
                        <Spin size={"large"} />
                        <div className="loading-text">Loading...</div>
                    </div>
                )}

                <div className="grid-container">
                    {userTransactionsLoadState === loadState.SUCCESS && userTransactions.length > 0 && (
                        <Table
                            className="cdt-transactions-table"
                            scroll={{ x: true }}
                            pagination={{ showSizeChanger: true }}
                            columns={columns}
                            dataSource={userTransactions}
                        />
                    )}

                    {userTransactionsLoadState === loadState.SUCCESS && userTransactions.length === 0 && (
                        <span className="not-found-error-msg">No Records</span>
                    )}
                </div>
            </div>
            {userTransactionsLoadState === loadState.SUCCESS &&
                <Footer />
            }

        </>
    );
}
export default UserTransactions;
