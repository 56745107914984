import { AnyAction } from "redux";
import { userLogin, userSignup, getCurrentUserProfile, changeUserPassword, getUsers, updateUserDetails, updateUserAdmin, removeUserAdmin, getUsersByDate, getCdtTransactions, getUSERTransactions, getWEBTransactions } from "../../api/login";
import Auth from "../../utils/auth";
import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ROLES,
  LOGIN_SUCCESS,
  LOGIN_STARTED,
  UPDATE_SUBSCRIPTION,
  LOGIN_FAILED,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  USER_PROFILE_FETCH_SUCCESS,
  USER_PROFILE_FETCH_FAILED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_STARTED,
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_ACTIVE_STATUS_STARTED,
  UPDATE_USER_ACTIVE_STATUS_SUCCESS,
  UPDATE_USER_ACTIVE_STATUS_FAILED,
  UPDATE_REDUX_ACTIVE_STATE,
  UPDATE_USER_ASADMIN_STARTED,
  UPDATE_USER_ASADMIN_SUCCESS,
  UPDATE_USER_ASADMIN_FAILED, 
  REMOVE_USER_ASADMIN_STARTED,
  REMOVE_USER_ASADMIN_SUCCESS,
  REMOVE_USER_ASADMIN_FAILED,
  UPDATE_REDUX_USER_SUBSCRIPTION,
  UPDATE_USER_CDT_ACCOUNT_STARTED,
  UPDATE_USER_CDT_ACCOUNT_SUCCESS,
  UPDATE_USER_CDT_ACCOUNT_FAILED,
  UPDATE_REDUX_CDT_ACCOUNT_STATE,
  GET_CDT_TRANSACTIONS_STARTED,
  GET_CDT_TRANSACTIONS_SUCCESS,
  GET_CDT_TRANSACTIONS_FAILED,
  GET_WEB_TRANSACTIONS_STARTED,
  GET_WEB_TRANSACTIONS_SUCCESS,
  GET_WEB_TRANSACTIONS_FAILED,
  GET_USER_TRANSACTIONS_STARTED,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILED,
} from "../types/types";

export const loginUser =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: LOGIN_STARTED, payload: false });

    userLogin(values)
      .then((res) => {
        Auth.login(res);
        dispatch({ type: LOGIN_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: LOGIN_FAILED, payload: error.message });
        else if (error.name === "LoginError")
          dispatch({ type: LOGIN_FAILED, payload: error.message });
        else dispatch({ type: LOGIN_FAILED, payload: error.message });
      });
  };

// @ts-ignore
export const getProfile = (): AnyAction => (dispatch: any, getState: any) => {
  getCurrentUserProfile()
    .then((res: any) => {
      dispatch({ type: USER_PROFILE_FETCH_SUCCESS, payload: res });
    })
    .catch((error: any) => {
      dispatch({ type: USER_PROFILE_FETCH_FAILED, payload: error.message });
    });
};

export const signupUser =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: SIGNUP_STARTED, payload: false });

    userSignup(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: SIGNUP_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: SIGNUP_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: SIGNUP_FAILED, payload: error.message });
        else dispatch({ type: SIGNUP_FAILED, payload: error.message });
      });
  };

export const updateSubcription =
  (values: string): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_SUBSCRIPTION, payload: values });
  };

export const showLoader =
  () => (dispatch: (arg0: { type: string; payload: boolean }) => void) => {
    dispatch({
      type: SHOW_LOADING,
      payload: true,
    });
  };

export const hideLoader =
  () => (dispatch: (arg0: { type: string; payload: boolean }) => void) => {
    dispatch({
      type: HIDE_LOADING,
      payload: false,
    });
  };

export const setRoles =
  (data: any) => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
      type: SHOW_ROLES,
      payload: data,
    });
  };
export const changePassword =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: CHANGE_PASSWORD_STARTED, payload: false });

    changeUserPassword(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/
        else dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });
      });
  };
  export const getAllUsers =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: GET_USERS_STARTED, payload: false });

    getUsers(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_USERS_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_USERS_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/
        else dispatch({ type: GET_USERS_FAILED, payload: error.message });
      });
  };
 
  export const  getAllUsersByDate =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: GET_USERS_STARTED, payload: false });

    getUsersByDate(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_USERS_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_USERS_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/
        else dispatch({ type: GET_USERS_FAILED, payload: error.message });
      });
  };

  export const updateUserActiveStatus =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_USER_ACTIVE_STATUS_STARTED, payload: false });

    updateUserDetails(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: UPDATE_USER_ACTIVE_STATUS_SUCCESS, payload: res });
        dispatch({ type: UPDATE_REDUX_ACTIVE_STATE, payload: values });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_USER_ACTIVE_STATUS_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/
        else dispatch({ type: UPDATE_USER_ACTIVE_STATUS_FAILED, payload: error.message });
      });
  };

  export const updateSubscription =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_USER_STARTED, payload: false });

    updateUserDetails(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: UPDATE_USER_SUCCESS, payload: res });
        dispatch({ type: UPDATE_REDUX_USER_SUBSCRIPTION, payload: values });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_USER_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/
        else dispatch({ type: UPDATE_USER_FAILED, payload: error.message });
      });
  };

  export const updateUserAsAdmin =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_USER_ASADMIN_STARTED, payload: false });

    updateUserAdmin(values)
      .then((res: any) => {
        dispatch({ type: UPDATE_USER_ASADMIN_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_USER_ASADMIN_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_USER_ASADMIN_FAILED, payload: error.message });
      });
  };

  export const removeUserAsAdmin =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: REMOVE_USER_ASADMIN_STARTED, payload: false });

    removeUserAdmin(values)
      .then((res: any) => {
        dispatch({ type: REMOVE_USER_ASADMIN_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type:   REMOVE_USER_ASADMIN_FAILED, payload: error.message });
        else dispatch({ type:   REMOVE_USER_ASADMIN_FAILED, payload: error.message });
      });
  };

  export const CDTUserUpgrade =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_USER_CDT_ACCOUNT_STARTED, payload: false });

    updateUserDetails(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: UPDATE_USER_CDT_ACCOUNT_SUCCESS, payload: res });
        dispatch({ type: UPDATE_REDUX_CDT_ACCOUNT_STATE, payload: values });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_USER_CDT_ACCOUNT_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_USER_CDT_ACCOUNT_FAILED, payload: error.message });
      });
  };

  export const CDTUserReset =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_USER_CDT_ACCOUNT_STARTED, payload: false });

    updateUserDetails(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: UPDATE_USER_CDT_ACCOUNT_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_USER_CDT_ACCOUNT_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_USER_CDT_ACCOUNT_FAILED, payload: error.message });
      });
  };
  
  export const getAllCDTTransactions =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: GET_CDT_TRANSACTIONS_STARTED, payload: false });

    getCdtTransactions(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_CDT_TRANSACTIONS_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_CDT_TRANSACTIONS_FAILED, payload: error.message });
        else dispatch({ type: GET_CDT_TRANSACTIONS_FAILED, payload: error.message });
      });
  };

  export const getAllWEBTransactions =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: GET_WEB_TRANSACTIONS_STARTED, payload: false });

    getWEBTransactions(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_WEB_TRANSACTIONS_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_WEB_TRANSACTIONS_FAILED, payload: error.message });
        else dispatch({ type: GET_WEB_TRANSACTIONS_FAILED, payload: error.message });
      });
  };

  export const getAllUSERTransactions =
  (values: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: GET_USER_TRANSACTIONS_STARTED, payload: false });

    getUSERTransactions(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_USER_TRANSACTIONS_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_USER_TRANSACTIONS_FAILED, payload: error.message });
        else dispatch({ type: GET_USER_TRANSACTIONS_FAILED, payload: error.message });
      });
  };