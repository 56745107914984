/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { ROLES } from "../../constants/users";
import { IApplicationState } from "../../duck/reducers";
import Auth from "../../utils/auth";
import { capitalizeFirstLetter } from "../../utils/commonUtils";
import { SUBSCRIPTION_CONFIG } from "../../constants/commonConstants";
import { checkPlanAvailability } from "../../api/login";

const Infobox = (props: any) => {
  const { subscriptionType } = useSelector((state) => (state as IApplicationState).app.userData);

  const subscriptionDetailsConfig = SUBSCRIPTION_CONFIG;
  const userSubscription = subscriptionType;
  const userSubscriptionDetails = subscriptionDetailsConfig.plans.find(
    ({ subscriptionType }) => subscriptionType === userSubscription
  );

  const ref = useRef(null);
  const history = useHistory();
  const { onClickOutside } = props;

  const { userData } = useSelector((state) => (state as IApplicationState).app);
  const [planDetails, setPlanDetails] = useState('Buy Now')

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.stopPropagation();
      const current = ref.current as any;
      if (current && !current.contains(event.target)) {
        onClickOutside && onClickOutside();
      } else {
        if (event.target.innerHTML === "Logout") {
          Auth.logout(() => {
            history.push("/login");
            window.location.reload();
          });
        } else if (event.target.innerHTML === "Change Password") {
          history.push("/change-password");
        } else if (event.target.innerHTML === "Manage Users") {
          history.push("/users");
        } else if (event.target.innerHTML === "CDT Files") {
          history.push("/cdtfiles");
        } else if (event.target.innerHTML === "Manage CDT Users") {
          history.push("/managecdtusers");
        } else if (event.target.innerHTML === "Billing History") {
          history.push("/transactions");
        }
        // else if (event.target.innerHTML === "ShapeGenie Billing History") {
        //   history.push("/webtransactions");
        // }else if (event.target.innerHTML === "Your Billing History") {
        //   history.push("/usertransactions");
        // } 

      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside, history]);

  const planCheck = async (username: any) => {
    let data = await checkPlanAvailability({ username: username });
    setPlanDetails(data?.plan_status)
  }

  useEffect(() => {
    planCheck(userData.username)
  }, [])

  if (!props.show) return null;
  return (
    <ul ref={ref} className="header-user-details">
      <li className="bold">ACCOUNT</li>
      <li className="header-info-separator">{capitalizeFirstLetter(userData.username)}</li>
      {!userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <>
          <li className="bold">Subscription</li>
          <li className="header-info-separator">{userSubscriptionDetails?.plan}</li>
          {/* <li className="header-info-separator">{userData.subscription}</li> */}
          <li className="bold">Organization</li>
          <li className="header-info-separator">{userData.organization}</li>
          {/* <li><a href="javascript:void(0)">Your Billing History</a></li> */}
          {/* <li className="bold"><a href="mailto:feedback@shapegenie.com?subject=Upgrade Request&body=Hello,%0D%0A%0D%0A
            In my ShapeGenie account I have reached my user plan limit.
            Hence I would like to upgrade my subscription plan.%0D%0A%0D%0A
            Thank You" target="_blank" rel="noopener noreferrer">Request Upgrade</a></li> */}
        </>
      )}
      {userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li className="bold"><a href="javascript:void(0)">Manage Users</a></li>
      )}
      {userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li className="bold"><a href="javascript:void(0)">CDT Files</a></li>
      )}
      {userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li className="bold"><a href="javascript:void(0)">Manage CDT Users</a></li>
      )}
      {userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li className="bold"><a href="javascript:void(0)">Billing History</a></li>
      )}
      {!userData.roles.includes(ROLES.ROLE_ADMIN) && props?.showBillingDetails &&
        <li><a href="/usertransactions">Your Billing History</a></li>
      }
      {!userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li><a href="/payment-page">{planDetails === 'Buy Now' ? 'Upgrade Plan' : 'Cancel Plan'}</a></li>
      )}
      {/* {userData.roles.includes(ROLES.ROLE_ADMIN) && (
            <li className="bold"><a href="javascript:void(0)">Billing History</a></li>
      )} */}
      {/* {userData.roles.includes(ROLES.ROLE_ADMIN) && (
        <li className="bold"><a href="javascript:void(0)">ShapeGenie Billing History</a></li>
      )} */}
      <li><a href="javascript:void(0)">Change Password</a></li>
      <li className="logoutLink"><a href="javascript:void(0)">Logout</a></li>
    </ul>
  );
};
export default Infobox;
