import React from "react";
import { Switch, Popconfirm } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { updateUserActiveStatus } from "../../../duck/actions/commonActions";
import { useDispatch } from "react-redux";

const StatusRenderer = (record :any ) => {

  const dispatch = useDispatch();

  const handleActiveUsers = (item: any) => {
    console.log(item)
    let obj = { is_account_active: !item.is_account_active, id: item.id };
    dispatch(updateUserActiveStatus(obj));
  };
  
  return (
    <>
      <Popconfirm
      className="popcon-no-style"
        title={`Are you sure to ${!record.is_account_active ? 'activate' : 'deactivate'} account of this user`}
        onConfirm={(e) => handleActiveUsers(record)}
        okText="Yes"
        cancelText="No"
        placement="topLeft"
      >
        <Switch style={{  backgroundColor:  !!record.is_account_active ? '#32CD32' : '#C0C0C0'  }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!record.is_account_active}
        />
      </Popconfirm>
    </>
  );
};

export default StatusRenderer;
