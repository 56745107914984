import React from 'react';
import { format } from 'date-fns';

const DateRenderer = (daterecieved: any) => {

  const date = format(new Date(daterecieved), 'yyyy/MM/dd');
  return <div style={{ fontSize: '12px', width: "80px" }}>{date}</div>;
};

export default DateRenderer;
