/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FileJpgOutlined } from "@ant-design/icons";
import "./style.css"
import { loadState } from "../../duck/reducers/commonReducer";
import { Spin } from "antd";
import { IApplicationState } from "../../duck/reducers";
import { useSelector } from "react-redux";
var FileSaver = require('file-saver');

const CDTDOWNLOADIMAGE = (props: any) => {
  const { item } = props;
  const [imageData, setImageData] = useState<any>([]);

  const { itemCDTBlobs, itemCDTBlobsLoadState } = useSelector(
    (state) => (state as IApplicationState).app
  );

  useEffect(() => {
    getCDTBlobsData();
  }, item);

  useEffect(() => {
    if (itemCDTBlobsLoadState === loadState.SUCCESS) getCDTBlobsData();
  }, [itemCDTBlobsLoadState]);


  const getCDTBlobsData = async () => {

    let responseData = itemCDTBlobs;
    if (responseData.length === 0) return;

    let imageDataDetails = itemCDTBlobs;
    setImageData(imageDataDetails)
  };


  return (
    <>
      {itemCDTBlobsLoadState === loadState.STARTED && (
        <div className="main_container spinner-container">
          <Spin size={"large"} />
          <p>Loading...</p>
        </div>
      )}
      {itemCDTBlobsLoadState === loadState.SUCCESS && (
        <div className="post-process-download-container">
          <div className="msg-zip-container">
            {
              imageData && imageData.length > 0 &&
              (<div className="result-page-message">
                You can download CDT Image file: {item.folder_path} by clicking on the icon.
              </div>)
            }
            {
              imageData.length === 0 &&
              (<div className="result-page-message">
                There is some issue with file. Please wait
              </div>)
            }
            <div className="zipdownload-container">
              <a
                title="Download CDT Image"
                // href={
                //   "https://mvpimagesa.blob.core.windows.net/" +
                //   item.userName +
                //   "/" +
                //   imageData
                // }
                style={{
                  display:
                    imageData && imageData.length > 0 ? "block" : "none",
                }}
                // download={imageData}
                onClick={() => {
                  let formate1 = imageData[0]?.split('/')[1];
                  let formate2 = imageData[0]?.split('.')[1];

                  return FileSaver.saveAs("https://mvpimagesa.blob.core.windows.net/" +
                    item.userName +
                    "/" +
                    imageData, formate1 + '.' + formate2);
                  // console.log('_props',item.userName,'__________',imageData)
                }}
              >
                <FileJpgOutlined />
              </a>
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default CDTDOWNLOADIMAGE;
