/* eslint-disable no-throw-literal */
import { API_BRAINSCANOLOGY_URL } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";
//api for login

export const userLogin = async (_queryParam: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/auth/signin",
      JSON.stringify(_queryParam)
    );
    if (response.data) {
      await createContainer({ containerName: (_queryParam?.username).toLowerCase() });
      return response.data;
    }
    else throw { name: "LoginError", message: response.response.data };
  } catch (error: any) {
    if (error.response.data.message && error.response.status !== 500)
      throw { name: "ServerError", message: error.response.data.message };
    else throw { name: "ServerError", message: "Server error" };
  }
};

export const getCurrentUserProfile = async () => {
  try {
    const response: any = await AjaxService.get(API_BRAINSCANOLOGY_URL + "/me");
    if (response.data) return response.data;
    else throw { name: "APIError", message: response.response.data };
  } catch (error: any) {
    if (error.response.data.message && error.response.status !== 500)
      throw { name: "ServerError", message: error.response.data.message };
    else throw { name: "ServerError", message: "Server error" };
  }
};

//api for sign up
export const userSignup = async (_queryParam: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/auth/signup",
      JSON.stringify(_queryParam)
    );
    if (response.data) {
      return response.data;
    } else throw { name: "SignupError", message: response.response.data };
  } catch (error: any) {
    if (error.response.data.message && error.response.status !== 500)
      throw { name: "ServerError", message: error.response.data.message };
    else throw { name: "ServerError", message: "Server error" };
  }
};

// api for creating records
export const createRecord = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/",
    _queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};
// api to update record
export const updateRecordDetails = (_queryParam?: any) => {
  return AjaxService.put(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/" + _queryParam.id,
    _queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const fileUpload = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/upload",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};


export const createContainer = (_queryParam?: any) => {
  try {
    const response: any = AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/createContainer",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error?.response?.data ?? error);
  }
};

export const updateRecords = (_queryParam?: any) => {
  return AjaxService.put(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/" + _queryParam.id,
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getBrainRecordById = (_queryParam?: any) => {
  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/" + _queryParam.id,
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const updateCDTDetails = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/updateCdtDbbyID",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getListBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/listOfBlobs",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const checkPreProcessBlobSZip = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/preprocessedBlobsZip",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getRecordsByUserName = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/getRecordsByUsername",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getCDTRecordsForAdmin = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/getCDTRecordsByUsername",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const sendMail = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/sendMail",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};
export const getUser = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/User",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getUsers = (_queryParam?: any) => {
  _queryParam = _queryParam ? "?text=" + _queryParam.text : "";

  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/Users" + _queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getUsersByDate = (_queryParam?: any) => {
  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/UsersByDate",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const changeUserPassword = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/auth/change-password",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};

export const updateUserDetails = (_queryParam?: any) => {
  return AjaxService.put(
    API_BRAINSCANOLOGY_URL + "/" + _queryParam.id,
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};
export const updateUserAdmin = async (_queryParam?: any) => {
  try {
    const updateUserAsAdmin: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/updateUserAsAdmin",
      JSON.stringify(_queryParam))
    return updateUserAsAdmin.data
  } catch (error: any) {
    throw new Error("Error on updating user ", error);
  }
};
export const removeUserAdmin = async (_queryParam?: any) => {
  try {
    const removeUserAsAdmin: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/removeUserasAdmin",
      JSON.stringify(_queryParam))
    return removeUserAsAdmin.data

  } catch (error: any) {
    throw new Error("Error on updating user ", error);
  }
}
export const updateUser = async (_queryParam?: any) => {
  try {
    const updateUser: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/updateUser",
      JSON.stringify(_queryParam))
    return updateUser.data

  } catch (error: any) {
    throw new Error("Error on updating user details", error);
  }

};


export const fetchToken = async (_queryParam: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/auth/createToken",
      JSON.stringify(_queryParam)
    );
    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    if (error.response.data.message && error.response.status !== 500)
      throw { name: "ServerError", message: error.response.data.message };
    else throw { name: "ServerError", message: "Server error" };
  }
};

export const fileCDTOutputUpload = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/CDTOutputFileUpload",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const fileCDTCLOCKSCOREOutputUpload = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/ClockScoreOutputFileUpload",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const fileCDTNumberingSCOREOutputUpload = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/NumberingScoreOutputFileUpload",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const getCDTUserFilesToAdminBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/getCDTUserFilesToAdminBlobs",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const tifpreviewApi = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/tifTopngForPreview",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const getCdtTransactions = (_queryParam?: any) => {
  _queryParam = _queryParam ? "?text=" + _queryParam.text : "";

  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/getAllWEBTransactions" + _queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getCDTUserFilesToAdminByDate = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/getFilteredCDTOutputFileforAdmin",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const tiffoldersdelete = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/deleteFoldersOfTmp",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const createSessionCheckout = async (_queryParam?: any) => {
  return AjaxService.post(
    `${API_BRAINSCANOLOGY_URL}/api/brainscanology/create-checkout`,
    JSON.stringify(_queryParam),
 ).then(
     (res) => res,
     (error) => {
         throw error.response.data;
     }
 );
 

  // try {
  //   const response: any = await AjaxService.post(
  //     API_BRAINSCANOLOGY_URL + "/api/brainscanology/create-checkout",
  //     JSON.stringify(_queryParam)
  //   );
  //   if (response.data) {
  //     return response.data;
  //   } 
  //   // else throw { name: "SignupError", message: response.response.data };
  // } catch (error: any) {
  //   console.log('__error',error)
  //   // if (error.response.data.message && error.response.status !== 500)
  //   //   throw { name: "ServerError", message: error.response.data.message };
  //   // else throw { name: "ServerError", message: "Server error" };
  // }
};

export const cancelSubscriptionWebAtEnd = async (_queryParam?: any) => {
  return AjaxService.post(
    `${API_BRAINSCANOLOGY_URL}/api/brainscanology/cancel-subscription-at-end`,
    JSON.stringify(_queryParam),
 ).then(
     (res) => res,
     (error) => {
         throw error.response.data;
     }
 );
}

export const getWEBTransactions = (_queryParam?: any) => {
  _queryParam = _queryParam ? "?text=" + _queryParam : "";

  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/getAllWEBTransactions" + _queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw new Error("Error", error?.response?.data ?? error);
    }
  );
};

export const getUSERTransactions = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/getUSERTransactions",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const webIsSubscriptionCheck = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/webSubscriptionCheck",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const mailIsUserdayWebSubscriptionPlanExipry = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/mailUser3dayWebSubscriptionPlanExipry",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};

export const checkPlanAvailability = async (_queryParam?: any) => {
  try {
    const response: any = await AjaxService.post(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/check-plan-availability",
      JSON.stringify(_queryParam)
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error", error);
  }
};
