export const SUBSCRIPTION_CONFIG = {
    plans: [
      {
        plan: "Premium Account",
        planPrice :"$49.99",
        subplan: "Best for Research Labs, Biotech/Healthtech Institutions",
        details: [
          {
            details1: "500 MB of Storage for Input Files",
            details2: "100 MB of Storage for Output Files",
            details3: "Analysis Limit: 5,000 images/30 days",
            details4:  "Price: USD $49.99/month North America/EU/East Asia (Other Countries: Contact Us)",
            details5: "Dedicated Support",
            details6:  "Access to Advising: 2 hours/month"
          },
        ],
        costing: "$49.99",
        disabled: true,
        subscriptionType: 2,
        totalInputLimit: '500 MB',
        totalOutputLimit: '100 MB',
        totalAnalysisLimit: '5000',
        analysisDurationDays:30,
        strikeText:"$199",
      }
    ],
  };