/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Modal,
  Pagination,
  Select,
  Spin,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
// @ts-ignore
import { useHistory } from "react-router-dom";

import "./d_style.css";

import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import parallel from "../../assets/logo/parallel.jpeg";
import uploadIcon from "../../assets/logo/upload BScan.png";

import { ROLES } from "../../constants/users";
import PreProcess from "../iframe";
import PostProcessIFrame from "../iframe/postprocessiframe";

import {
  createRecord,
  fileUpload,
  getRecordsByUserName,
  sendMail,
  updateRecordDetails,
  updateRecords,
  updateUser,
  getBrainRecordById,
  fetchToken,
} from "../../api/login";
import {
  createPostprocessFolder,
  createPreprocessFolder,
  getSpecificFile,
} from "../../api/dashboardApi";

import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import openNotification from "../../common/Notifications";
import {
  API_BRAINSCANOLOGY_URL,
  API_FLASK_URL,
} from "../../constants/apiBaseUrl";
import { EMAILS, PROCESS_STATUS } from "../../constants/commonConstants";
import {
  getTotalCount,
  deleteDashBoard,
  getBlobsPreProcess,
  createZipFile,
  createZipFileForPostProcess,
  getBlobsPostProcess,
} from "../../duck/actions/dashboardActions";
import { IApplicationState } from "../../duck/reducers";
import {
  DELETE_DASHBOARD_NOTSTARTED,
  IFRAME_LOAD_NOTSTARTED,
  IFRAME_LOAD_STARTED,
  VIEW_FILE_DETAILS,
} from "../../duck/types/types";
import {
  capitalizeFirstLetter,
  calculateFileSize,
} from "../../utils/commonUtils";
import {
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  UPLOAD_NOTSTARTED,
} from "../../duck/types/types";
import { API_URL, DATATURE_APP_POST_PROCESS_URL } from "../../config/index";
import { loadState } from "../../duck/reducers/commonReducer";
import Header from "../header";
import { SUBSCRIPTION_CONFIG } from "../../constants/commonConstants";
import Storage from "../progressBar/storageBar";
import { getProfile } from "../../duck/actions/commonActions";
import {webIsSubscriptionCheck,mailIsUserdayWebSubscriptionPlanExipry} from '../../api/login';
import Footer from "../footer/footer";
import PreProcessResults from "../preprocessresults";
import PostProcessResults from "../postprocessresults";
import TIFFPreview from "./tifpreview";
import { tr } from "date-fns/locale";
import FirstNameModal from './FirstNameModal'

const { Option } = Select;

const Dashboard = () => {
  // TODO: The below line is added for testing on server,
  // Once testing done then we will remove the below line
  // console.log("API_URL", API_URL);

  const [totalRecordsCount] = useSelector(
    (state: any) => [state?.app?.totalRecordsCount],
    shallowEqual
  );
  const { id, username, email, organization, subscriptionType, roles, firstname, lastname } =
    useSelector((state) => (state as IApplicationState).app.userData);

  const {
    deleteDashboardLoadState,
    error,
    uploadFileLoadState,
    iframeLoadState,
  } = useSelector((state) => (state as IApplicationState).app);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredBrainData, setFilteredBrainData] = useState([]);
  const [key, setKey] = useState<any>([]);
  const [dropdownValues, setDropdownValues] = useState<any>([]);
  const { register, handleSubmit } = useForm();
  const [loader, setLoader] = useState<any>(true);
  const buttonDisabled = false;
  const [batchValue, setBatchValue] = useState("");
  const [current, setCurrent] = useState<any>(1);
  const [minIndex, setMinIndex] = useState<any>(0);
  const [maxIndex, setMaxIndex] = useState<any>(0);
  const [isPreprocessModalVisible, setIsPreprocessModalVisible] =
    useState(false);
  const [isPreprocessResultsModalVisible, setIsPreprocessResultsModalVisible] =
    useState(false);
  const [selectedBlob, setSelectedBlob] = useState({});
  const [selectedPreProcessItem, setSelectedPreProcessItem] = useState({});
  const [isPreProcessDiabled, setIsPreProcessDiabled] = useState<boolean>(false);
  const [processSubmitLoader,setProcessSubmitLoader] = useState<boolean>(false);
  const [isFileInUpload,setIsFileInUpload] = useState<boolean>(false);
  const [isFileUploadSuccess,setIsFileUploadSuccess] = useState<boolean>(false);
  const [disabledXUploadModal,setDisabledXUploadModal] = useState<boolean>(false);
  const [fileUploadFailed,setfileUploadFailed] = useState<boolean>(false);
  const [isPostprocessModalVisible, setIsPostprocessModalVisible] =
  useState(false);
const [isPostprocessResultsModalVisible, setIsPostprocessResultsModalVisible] =
  useState(false);
  const [isPostProcessDiabled, setIsPostProcessDiabled] = useState<boolean>(false);
  const [selectedPostProcessItem, setSelectedPostProcessItem] = useState({});
  const pageSize = 10;
  //handling input changes
  const handleInputChange = (e: any) =>
    setBatchValue(e.target.value.toLowerCase());


  const handlePageChange = (page: any) => {
    setCurrent(page + 0);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
    let keyDetails = key.map((item: boolean) =>
      item === true ? false : false
    );
    setKey([...keyDetails]);
  };

  const handlePostprocessCancel = (item:any) => {
    setIsPostprocessModalVisible(false);
    const requestBody = {
      containerName: item.userName,
      fileLocation: item.fileLocation,
    };
    //dispatch(createZipFileForPostProcess(requestBody));
    dispatch({ type: IFRAME_LOAD_NOTSTARTED });
    document.getElementById('postProcess_'+item.id)?.focus();
  };

  const handlePostprocessResultsCancel = () => {
    setIsPostprocessResultsModalVisible(false);
  };

  const handlePreprocessCancel = (item:any) => {
    setIsPreprocessModalVisible(false);
    const requestBody = {
      containerName: item.userName,
      fileLocation: item.fileLocation,
    };
    dispatch(createZipFile(requestBody));
    dispatch({ type: IFRAME_LOAD_NOTSTARTED });
    document.getElementById('preProcess_'+item.id)?.focus();
  };

  const handlePreprocessResultsCancel = () => {
    setIsPreprocessResultsModalVisible(false);
  };

  const displayFileLocation = (props: any) => {
    const oldBatchName = props;
    let batchLength = oldBatchName.length;
    const index = batchLength - 14;
    var newBatchName = [
      oldBatchName.slice(0,index),
      "-",
      oldBatchName.slice(index),
    ].join("");
    return newBatchName;
  };

  const move = () => {
    var elem:any = document.getElementById("myBar");
    var width = 1;
    var id = setInterval(frame, 40);
    function frame() {
      if (width >= 94) {
        clearInterval(id);
      } else {
          width++;
          elem.style.width = width + '%';
        }
    }
  }


  const viewResultDetails = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: any
  ) => {
    let brainDataDetails = filteredBrainData[index];
    dispatch({ type: VIEW_FILE_DETAILS, payload: brainDataDetails });

    history.push("/home");
    localStorage.setItem("filedata",JSON.stringify({brainDataDetails}))
  };
  //
  const sortedData = (arr: any) => {
    return arr.sort((x: any, y: any) => {
      return new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1;
    });
  };

  const deleteDashBoardRecord = (
    itemId: any,
    fileLocation: any,
    username: any,
    uploadedFilesSize: any,
    downloadedFilesSize: any,
    totalFiles: any
  ) => {
    updateUser({
      used_input_limit: uploadedFilesSize,
      userName: username,
      isAdd: false,
      downloadedFilesSize: downloadedFilesSize,
      totalFiles,
    });
    dispatch(deleteDashBoard(itemId));
  };

  useEffect(() => {
    dispatch(getProfile());
    initialCall();
  }, [deleteDashboardLoadState,username]);

  const initialCall = async() =>{
    let isRedirect = localStorage.getItem("paymentRedirect")
    if(!roles?.includes(ROLES.ROLE_ADMIN) && username){
      try {
        let res = await webIsSubscriptionCheck({username:username});
        if(!res?.Subscription_expired){
          await mailIsUserdayWebSubscriptionPlanExipry({username:username});        
        }else if(res?.Subscription_expired && res?.isReload){
          window.location.reload();
        }
      } catch (error) {
        console.log('error while check mails in SubscriptionCheck ')
      }
      if(isRedirect && JSON.stringify(isRedirect)){
        history.push('/payment-page')
      }
    }
  }

  // create Date
  const createDate = (d: any) => {
    let string = "";
    let dateDet = new Date(d);
    let year = dateDet.getFullYear();
    let month = dateDet.getMonth();
    month = month + 1;
    let monthDet = month >= 10 ? month.toString() : "0" + month.toString();
    let date = dateDet.getDate();
    let dateDetails = date >= 10 ? date.toString() : "0" + date.toString();
    let hours = dateDet.getHours();
    let hourDetails = hours >= 10 ? hours.toString() : "0" + hours.toString();
    let min = dateDet.getMinutes();
    let minDetails = min >= 10 ? min.toString() : "0" + min.toString();
    let sec = dateDet.getSeconds();
    let secDetails = sec >= 10 ? sec.toString() : "0" + sec.toString();
    string =
      monthDet +
      "-" +
      dateDetails +
      "-" +
      year +
      " " +
      hourDetails +
      ":" +
      minDetails +
      ":" +
      secDetails;
    return string;
  };

  const getBrainData = async () => {
    if (!username) return;
    const filteredBrainDataDetails = await getRecordsByUserName({
      username: username,
      role: roles ? roles[0] : "ROLE_USER",
    });
    let responseData = sortedData(filteredBrainDataDetails);
    for (let i = 0; i < responseData.length; i++) {
      key[i] = false;

      dropdownValues[i] = responseData[i].processingType;
      // processedKey[i] = responseData[i].processInitiated ? true : false;
    }

    setKey([...key]);
    setDropdownValues([...dropdownValues]);
    setMinIndex(0);
    setMaxIndex(pageSize);
    setFilteredBrainData(sortedData(responseData));
    setLoader(false);
  };

  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isCopyPasted) {
      history.push("/login");
    }else
    dispatch(getTotalCount());
  }, []);

  useEffect(() => {
    getBrainData();
  }, [username,isPreprocessModalVisible,isPostprocessModalVisible]);

  useEffect(() => {
    getBrainData();
    if (deleteDashboardLoadState === loadState.SUCCESS) {
      let successObj = {
        type: "success",
        message: "File deleted successfully",
      };
      openNotification(successObj);
      dispatch({ type: DELETE_DASHBOARD_NOTSTARTED });
    } else if (deleteDashboardLoadState === loadState.FAILED) {
      let errorObj = {
        type: "error",
        message: error,
      };
      openNotification(errorObj);
      dispatch({ type: DELETE_DASHBOARD_NOTSTARTED });
    }
  }, [deleteDashboardLoadState]);

  useEffect(() => {
    getBrainData();
    if(uploadFileLoadState === loadState.STARTED){
      move();
      if(filteredBrainData.length <= 0) {
       }
       setIsFileInUpload(true)
    }
    if (uploadFileLoadState === loadState.SUCCESS  ) {
      setIsFileInUpload(false)
      setIsFileUploadSuccess(true);
        let successObj = {
          type: "success",
          message: "File Uploaded Successfully. Kindly refresh the page",
        };
        openNotification(successObj);  
      dispatch({ type: UPLOAD_NOTSTARTED });
      dispatch(getProfile());
    } else if (uploadFileLoadState === loadState.FAILED) {
      setIsFileInUpload(false)
      setfileUploadFailed(true);
      let errorObj = {
        type: "error",
        message: error,
      };
      openNotification(errorObj);
      dispatch({ type: UPLOAD_NOTSTARTED });
    }
    setTimeout(() =>{
      setIsFileUploadSuccess(false)
    },3000)
    setTimeout(() =>{
      setfileUploadFailed(false)
    },3000)
  }, [uploadFileLoadState]);

  const showModal = (e: any) => {
    setIsModalVisible(true);
    const inputDetails = document.getElementById(
      "fileDetails"
    ) as HTMLInputElement | null;
    if (inputDetails != null) {
      inputDetails.value = "";
    }
    const input = document.getElementById(
      "submit-btn-form"
    ) as HTMLInputElement | null;
    if (input != null) {
      input.disabled = false;
    }
  };


  const handleCancel = () => {
    setBatchValue("");
    setIsModalVisible(false);
    const inputDetails = document.getElementById(
      "fileDetails"
    ) as HTMLInputElement | null;
    if (inputDetails != null) {
      inputDetails.value = "";
    }
  };
  const history = useHistory();

  const dropdown = async (e: any, index: any) => {
    key[index] = true;
    //api to delete files
    setKey([...key]);

    // setShow(true);
  };
  //for file process submit
  const submit = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: any,
    item: any
  ) => {
    const subscriptionDeatils = SUBSCRIPTION_CONFIG.plans.find(
      (record) => record.subscriptionType === subscriptionType
    );

    if (dropdownValues[index] !== "") {
      let obj = { ...item, processingType: dropdownValues[index] };

      setProcessSubmitLoader(true)
      // let id = obj.id;
      if (obj.isProcessed === false) {
        obj.processInitiated = true;
      }
      await updateRecords(obj);
      //run the scripts
      // call the pre-processed-state
      const response = await getSpecificFile(item.id);
      let requestBody = {
        userName: username,
        fileLocation: item.fileLocation,
        id: item.id,
        preProcessedState: response["isPreProcess"],
        totalOutputLimit: subscriptionDeatils?.totalOutputLimit,
        fromAddress:
          EMAILS.Notifications_id,
        toAddress: email,
      };

      if (
        dropdownValues[index].replace(/\s/g, "").toLowerCase() ===
        "parallelgridsystem"
      ) {
        await fetch(API_FLASK_URL + `/parellelProcessing`, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res);
      } else {
        await fetch(API_FLASK_URL + `/radialProcessing`, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res);
      }
      //delete files
      await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFiles`, {
        method: "DELETE",
      }).then((res) => res);
      //delete folder
      await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFolder`, {
        method: "DELETE",
      }).then((res) => res);
   

      const filteredRecords = await getRecordsByUserName({
        username: username,
        role: roles ? roles[0] : "ROLE_USER",
      });
      let responseData = sortedData(filteredRecords);
      key[index] = false;
      dispatch(getTotalCount());
      setFilteredBrainData(sortedData(responseData));
      setProcessSubmitLoader(false)
    } else {
      let errorObj = {
        type: "error",
        message: "Error",
        description: "Please select a process",
      };
      openNotification(errorObj);
    }
  };

  const dropDownChange = (e: any, index: any) => {
    dropdownValues[index] = e;
    setDropdownValues([...dropdownValues]);
  };

  const approve = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: any,
    item: any
  ) => {
    const { processStatus } = await getBrainRecordById(item);
    let obj = { ...item, isProcessed: true };
    let errorObj = {};
    let mailRequest = {};
    if (processStatus !== null) {
      switch (processStatus) {
        case "In-Process":
          errorObj = {
            type: "error",
            description: "In Process!!! Please wait.",
          };
          break;

        case "Success":
          errorObj = {
            type: "success",
            message: "Success!!!",
            description: "File is ready for download.",
          };

          await updateRecords(obj);

          let requestDetails = {
            username: username,
            role: roles ? roles[0] : "ROLE_USER",
          };
          mailRequest = {
            from: EMAILS.Notifications_id,
            to: email,
            subject:
              "Brainscanology - Report " +
              obj.fileLocation +
              " available for download ",
            text: "Hello world ",
            html:
              "<p>Dear " +
              capitalizeFirstLetter(obj.userName) +
              ",</p><p>Your report " +
              obj.fileLocation +
              " is available for download. Please Login to Brainscanology Dashboard to view the report.</p><p>Best Regards</p><p>Shapegenie Team</p>",
          };

          //api for sending mail
          await sendMail(mailRequest);
          //api to delete files
          await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFiles`, {
            method: "DELETE",
          }).then((res) => res);

          //delete to delete folder
          await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFolder`, {
            method: "DELETE",
          }).then((res) => res);

          const filteredBrainDataDetails = await getRecordsByUserName(
            requestDetails
          );
          let responseData = sortedData(filteredBrainDataDetails);
          for (let i = 0; i < responseData.length; i++) {
            key[i] = false;
            dropdownValues[i] = responseData[i].processingType;
          }
          setDropdownValues([...dropdownValues]);
          setFilteredBrainData(sortedData(responseData));
          break;

        case "Limit-Exceeded":
          errorObj = {
            type: "error",
            message:
              "You have reached the file download limit for your account. Please upgrade your subscription plan.",
            description: "Can't Process , Download limit is exceeded.",
          };

          mailRequest = {
            from: EMAILS.Notifications_id,
            to: email,
            subject:
              "Brainscanology - Report " +
              obj.fileLocation +
              " Download Limit Exceeded",
            text: "Hello world ",
            html:
              "<p>Dear " +
              capitalizeFirstLetter(obj.userName) +
              ",</p><p>Your report " +
              obj.fileLocation +
              " is not available for download due to limit exceeded. Please Upgrade your subscription plan.</p><p>Best Regards</p><p>Shapegenie Team</p>",
          };

          //api for sending mail
          await sendMail(mailRequest);

          break;

        case "Failure":
          errorObj = {
            type: "error",
            message: "Error",
            description: "Can't Process ,Due to some download file issue.",
          };
          break;

        default:
          break;
      }
    } else {
      errorObj = {
        type: "error",
        message: "Subscription Error",
        description:
          "Can't Process , subscription details not available for this user.",
      };
    }
    openNotification(errorObj);
  };
  // return a date timestamp
  const returnDateTimestamp = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!

    let dd = today.getDate();
    let hh = today.getHours();
    let min = today.getMinutes();
    let sec = today.getSeconds();
    //converting
    let date = dd < 10 ? "0" + dd.toString() : dd.toString();
    let monthDet = mm < 10 ? "0" + mm.toString() : mm.toString();
    let hours = hh < 10 ? "0" + hh.toString() : hh.toString();
    let minutes = min < 10 ? "0" + min.toString() : min.toString();
    let seconds = sec < 10 ? "0" + sec.toString() : sec.toString();

    const formattedToday = monthDet + date + yyyy + hours + minutes + seconds;
    return formattedToday;
  };

  const onSubmit = async (data: any) => {
    setDisabledXUploadModal(true);
    //request to create data-entry
    if (data.file.length > 0) {
      var regex = new RegExp("^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]$")
      if (batchValue !== "") {
        if (
          regex.test(batchValue) &&
          batchValue.charAt(batchValue.length - 1) !== "-" &&
          batchValue.charAt(0) !== "-"
        ) {
          //API to delete files
          await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFiles`, {
            method: "DELETE",
          }).then((res) => res);

          //delete tmp folder
          await fetch(`${API_BRAINSCANOLOGY_URL}/deleteFolder`, {
            method: "DELETE",
          }).then((res) => res);

          const input = document.getElementById(
            "submit-btn-form"
          ) as HTMLInputElement | null;
          if (input != null) input.disabled = true;
          //TODo:will remove once confirm from client
          // let fileLocation =
          //   username +
          //   "-" +
          //   returnDateTimestamp() +
          //   "-" +
          //   batchValue.toLowerCase();
          let fileLocation = batchValue.toLowerCase() + returnDateTimestamp();
          fileLocation = displayFileLocation(fileLocation);
          const { isLimitExceed, usedInputLimit } = calculateFileSize(
            data,
            subscriptionType
          );
          if (isLimitExceed) {
            let errorObj = {
              type: "error",
              message: "File not uploaded!!!",
              description:
                "File upload limit exceeded, Please upgrade your subscription plan.",
            };
            openNotification(errorObj);
            setDisabledXUploadModal(false);
            setIsModalVisible(false);
            setBatchValue("");
          } else {
            const { error, message } = await updateUser({
              used_input_limit: usedInputLimit,
              userName: username,
              isAdd: true,
              totalFiles: data.file.length,
            });
            if (error) {
              let errorObj = {
                type: "error",
                message: "File not uploaded!!!",
                description: message,
              };
              openNotification(errorObj);
              setDisabledXUploadModal(false);
              setIsModalVisible(false);
              setBatchValue("");
            } else {
              if (fileLocation.length <= 63) {
                let request = {
                  id: totalRecordsCount + 1,
                  userName: username,
                  fileLocation: fileLocation,
                  processingType: "",
                  isProcessed: false,
                  processInitiated: false,
                  isPreProcess:false,
                  uploadStarted: false,
                  uploadFinished: false,
                  uploadedFiles: 0,
                  createdAt: new Date(),
                  uploadedAt: new Date(),
                  organization: organization,
                  uploadedFilesSize: usedInputLimit,
                  processStatus: PROCESS_STATUS.IN_PROCESS,
                };
                const saveRecord = await createRecord(request);
                
                setDisabledXUploadModal(false);
                setIsModalVisible(false);
                setBatchValue("");
                dispatch({ type: UPLOAD_STARTED });
                const filteredBrainDataDetails = await getRecordsByUserName({
                  username: username,
                  role: roles ? roles[0] : "ROLE_USER",
                });
                let responseData = sortedData(filteredBrainDataDetails);

                for (let i = 0; i < responseData.length; i++) {
                  key[i] = false;
                  dropdownValues[i] = responseData[i].processingType;
                }
                setDropdownValues([...dropdownValues]);
                dispatch(getTotalCount());
                setFilteredBrainData(sortedData(responseData));

                // fileProcessing and upload the file
                if (data.file.length === 1) {
                  const formData = new FormData();
                  let uName = username;
                  //store the user and file and append it
                  formData.append("file", data.file[0]);
                  formData.append("name", uName);
                  //calling the fileProcessing functionality
                  await fetch(`${API_BRAINSCANOLOGY_URL}/fileProcessing`, {
                    method: "POST",
                    body: formData,
                  }).then(async (res) => res);

                  const inputFile = document.getElementById(
                    "fileDetails"
                  ) as HTMLInputElement | null;
                  if (inputFile != null) inputFile.value = "";

                  setTimeout(async () => {
                    await fetch(
                      `${API_BRAINSCANOLOGY_URL}/getFilesAfterProcessing`,
                      {
                        method: "GET",
                      }
                    ).then(async (res) => res);

                    const request = {
                      username: fileLocation.toLowerCase(),
                      batchName: batchValue.toLowerCase(),
                      containerName: username.toLowerCase(),
                    };
                    const response = await fileUpload(request);
         
                   //above code for admin upload
                    if (response.isError === false) {
                      dispatch({ type: UPLOAD_SUCCESS });
                    }
                    if (response.isError === true) {
                      dispatch({
                        type: UPLOAD_FAILED,
                        payload: response.message,
                      });
                    }
                    let listBlobs = response.blobArray.filter(
                      (item: any, index: any) => {
                        let obj = "";
                        if (item.includes(".pdf") === false) {
                          if (item.includes(".csv") === false) obj = item;
                        }
                        return obj;
                      }
                    );
                    // setIsModalVisible(false);
                    // update the record
                    const requestBody = {
                      id: saveRecord.id,
                      userName: username,
                      fileLocation: response.fileLocation.toLowerCase(),
                      processingType: "",
                      isProcessed: false,
                      processInitiated: false,
                      isPreProcess:false,
                      uploadStarted: true,
                      uploadFinished: true,
                      uploadedFiles: response.blobArray.length,
                      organization: organization,
                      imagePath: listBlobs[0],
                    };
                    await updateRecordDetails(requestBody);

                    const filteredBrainDataDetails = await getRecordsByUserName(
                      {
                        username: username,
                        role: roles ? roles[0] : "ROLE_USER",
                      }
                    );
                    let responseData = sortedData(filteredBrainDataDetails);

                    for (let i = 0; i < responseData.length; i++) {
                      key[i] = false;
                      dropdownValues[i] = responseData[i].processingType;
                    }
                    setDropdownValues([...dropdownValues]);
                    dispatch(getTotalCount());
                    setFilteredBrainData(sortedData(responseData));

                    if (input != null) input.disabled = false as any;
                  }, 4500);
                } else {
                  setDisabledXUploadModal(false);
                  setIsModalVisible(false);
                  const formData = new FormData();
                  for (var i = 0; i < data.file.length; i++) {
                    formData.append("file", data.file[i]);
                  }
                  formData.append("name", username);
                  await fetch(`${API_BRAINSCANOLOGY_URL}/fileProcessing`, {
                    method: "POST",
                    body: formData,
                  }).then(async (res) => res);
                  const inputFile = document.getElementById(
                    "fileDetails"
                  ) as HTMLInputElement | null;
                  if (inputFile != null) inputFile.value = "";

                  setTimeout(async () => {
                    await fetch(
                      `${API_BRAINSCANOLOGY_URL}/getFilesAfterProcessing`,
                      {
                        method: "GET",
                      }
                    ).then(async (res) => res);
                    const request = {
                      username: fileLocation.toLowerCase(),
                      batchName: batchValue.toLowerCase(),
                      containerName: username.toLowerCase(),
                    };
                    const response = await fileUpload(request);
  
                    if (response.isError === false) {
                      dispatch({ type: UPLOAD_SUCCESS });
                    }
                    if (response.isError === true) {
                      dispatch({
                        type: UPLOAD_FAILED,
                        payload: response.message,
                      });
                    }
                    let listBlobs = response.blobArray.filter(
                      (item: any, index: any) => {
                        let obj = "";
                        if (item.includes(".pdf") === false) {
                          if (item.includes(".csv") === false) obj = item;
                        }
                        return obj;
                      }
                    );
                    // setIsModalVisible(false);
                    // update the record
                    const requestBody = {
                      id: saveRecord.id,
                      userName: username,
                      fileLocation: response.fileLocation.toLowerCase(),
                      processingType: "",
                      isProcessed: false,
                      processInitiated: false,
                      isPreProcess:false,
                      uploadStarted: true,
                      uploadFinished: true,
                      uploadedFiles: response.blobArray.length,
                      organization: organization,
                      imagePath: listBlobs[0],
                    };
                    await updateRecordDetails(requestBody);
                    const filteredBrainDataDetails = await getRecordsByUserName(
                      {
                        username: username,
                        role: roles ? roles[0] : "ROLE_USER",
                      }
                    );
                    let responseData = sortedData(filteredBrainDataDetails);

                    for (let i = 0; i < responseData.length; i++) {
                      key[i] = false;
                      dropdownValues[i] = responseData[i].processingType;
                    }
                    setDropdownValues([...dropdownValues]);
                    dispatch(getTotalCount());
                    setFilteredBrainData(sortedData(responseData));

                    if (input != null) input.disabled = false as any;
                  }, 2000);
                }
              } else {
                if (input != null) input.disabled = false as any;
                let errorObj = {
                  type: "error-batch",
                  message: "Error",
                  description:
                    "The file location is stored as container name in the azure which has range limit in between 3 and 63 characters ",
                };
                openNotification(errorObj);
                setDisabledXUploadModal(false);
              }
            }
          }
        } else {
          let errorObj = {
            type: "error-batch-long",
            message: "Error",
            description:
              "Special characters except hyphens are not allowed in batch names. Hyphens (-) are allowed between the characters. Consecutive hyphens (-) are not allowed. The name should not start and end with a special character.",
          };
          openNotification(errorObj);
          setDisabledXUploadModal(false);
        }
      } else {
        let errorObj = {
          type: "error-batch",
          message: "Error",
          description:
            "The batch name cannot be empty. Please enter a valid batch name.",
        };
        openNotification(errorObj);
        setDisabledXUploadModal(false);
      }
    } else {
      let errorObj = {
        type: "error-batch",
        message: "Error",
        description: "Kindly select the files and then submit",
      };
      openNotification(errorObj);
      setDisabledXUploadModal(false);
    }
  };

  const callPrePreprocess = async (item: any) => {
    setIsPreProcessDiabled(true);
    setSelectedPreProcessItem(item);
    setIsPreprocessModalVisible(true);
    let obj = { isPreProcess: true, id: item.id };
    await updateRecordDetails(obj);
    await createPreprocessFolder({
      containerName: item.userName,
      fileLocation: item.fileLocation,
      isPreProcess: true,
    });
    
    await createPostprocessFolder({
      containerName: item.userName,
      fileLocation: item.fileLocation,
    });

    setIsPreProcessDiabled(false);
  };

  const callPrePreprocessResults = async (item: any) => {
    try {
      setIsPreprocessResultsModalVisible(true);
      const requestBody = {
        containerName: item.userName,
        fileLocation: item.fileLocation,
      };
      setSelectedBlob(item);
      dispatch(getBlobsPreProcess(requestBody));
    } catch (err) {
      console.log(err);
    }
  };

const callPostprocess = async (item: any) => {
    setIsPostProcessDiabled(true)
    setSelectedPostProcessItem(item);
    setIsPostprocessModalVisible(true);
    dispatch({ type: IFRAME_LOAD_STARTED });
    let obj = { isPostProcess: true, id: item.id };
    await updateRecordDetails(obj);
    await createPostprocessFolder({
      containerName: item.userName,
      fileLocation: item.fileLocation,
    });

    const token = await fetchToken({
      userId: id,
      username: item.userName,
      location: `${item.fileLocation}`,
    });

    const res = await fetch(DATATURE_APP_POST_PROCESS_URL, {
      method: "GET",
      headers: {
        // Here we can set any headers
        Authorization: `Bearer ${token}`,
      },
    });

    const blob = await res.blob();
    const urlObject = URL.createObjectURL(blob);
    const iframe = document.querySelector("iframe");
    iframe?.setAttribute("src", urlObject);
    setIsPostProcessDiabled(false)
  };

  const callPostPreprocessResults = async (item: any) => {
    try {
      setIsPostprocessResultsModalVisible(true);
      const requestBody = {
        containerName: item.userName,
        fileLocation: item.fileLocation,
      };
      setSelectedBlob(item);
      dispatch(getBlobsPostProcess(requestBody));

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header prop={loader} />

      {firstname === null && <FirstNameModal />}
      
      <Modal
        className="pre-process-modal"
        visible={isPreprocessModalVisible}
        footer={null}
        closable={false}
        width="84%"
        centered
        maskClosable={false}
      >
        <PreProcess handlePreProcess={(additionalProps: any) => handlePreprocessCancel(additionalProps)} 
         item={selectedPreProcessItem}/>
      </Modal>

      <Modal
        visible={isPreprocessResultsModalVisible}
        onCancel={handlePreprocessResultsCancel}
        footer={null}
        width="90%"
        centered
        bodyStyle={{ height: "60%" }}
      >
        <PreProcessResults item={selectedBlob} />
      </Modal>

      <Modal
        visible={isPostprocessModalVisible}
        footer={null}
        closable={false}
        width="90%"
        centered
        bodyStyle={{ height: "auto" }}
        maskClosable={false}
      >
        {iframeLoadState === loadState.STARTED && (
          <div className="loading-iframe">
            <Spin
              size={"large"}
              spinning={iframeLoadState === loadState.STARTED}
            />
            <div className="loading-text">Loading...</div>
          </div>
        )}
        <PostProcessIFrame />
        <Button
          type="primary"
          onClick={()=>handlePostprocessCancel(selectedPostProcessItem)}
          className="Pre-process-complete rounded-button button-focus"
          disabled={isPostProcessDiabled}
        >
          Post-process Complete
        </Button>
        <Button
          type="primary"
          onClick={()=>handlePostprocessCancel(selectedPostProcessItem)}
          className="Pre-process-cancel rounded-button button-focus"
        >
          Cancel
        </Button>
      </Modal>

      <Modal
        visible={isPostprocessResultsModalVisible}
        onCancel={handlePostprocessResultsCancel}
        footer={null}
        width="60%"
        centered
        bodyStyle={{ height: "40%" }}
      >
       <PostProcessResults item={selectedBlob} />
      </Modal>

      <div className="dashboard-container">
        <div className="breadcrumb-container">
          <div className="breadcrumb">Dashboard</div>
        { uploadFileLoadState === loadState.STARTED && (<div className="progress-file">
            <div id="myBar" className="progress-file-fill" style={{ width:"1px"}}></div>
          </div>
         ) }
          {  isFileUploadSuccess && (
          <div className="progress-file-full">
            <div id="myBar3" className="progress-file-fill-full"></div>
          </div>
          ) }
          { fileUploadFailed && ( <div className="file-fail">
              <div id="myBar2" className="progress-file-fail-fill" ></div>
          </div>)}
          <div className="right-feature">
            <Button
              type="primary"
              onClick={(e) => {
                showModal(e);
              }}
              className="upload-button rounded-button"
            >
              <img
                alt="uploadIcon"
                src={uploadIcon}
                style={{ width: " 18px" }}
              ></img>
              Upload New File
            </Button>
            {/* File upload modal box */}
            <Modal
              title="Upload Scan File"
              visible={isModalVisible}
              closable={false}
              maskClosable={false}
              footer={[
                <Button key="back" onClick={handleCancel} disabled={disabledXUploadModal}>
                Cancel
              </Button>
              ]}
            >
              <form onSubmit={handleSubmit(onSubmit as any)}>
                <label>Batch Name</label>
                <br></br>
                <input
                  id="batchName"
                  value={batchValue}
                  placeholder="Enter Batch Name"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                /> 
      
                <br></br>
                <br></br>
                <p className="file-name-note">
                Note: Please rename your files if they contain special characters, such as ( ) { } [ ] | + = -. 
                Do NOT use periods (.)
                 in filenames except for the file type extension (example of file type extensions: .jpeg, .tif, .png).</p>
                <input
                  id="fileDetails"
                  type="file"
                  multiple
                  {...register("file")}
                  accept=".jpeg,.jpg,.nii,.tif,.png,.gif,.zip"
                />

                <input
                  type="submit"
                  id="submit-btn-form"
                  disabled={buttonDisabled}
                />
              </form>
            </Modal>
          </div>
          {uploadFileLoadState === loadState.STARTED && (
            <div className="loading">
              <div className="loading-file">
              File is being uploaded. Please do not refresh the page.
              </div>
            </div>
          )}
          {(loader || deleteDashboardLoadState === loadState.STARTED) && (
            <div className="loading">
              <Spin
                size={"large"}
                spinning={
                  loader || deleteDashboardLoadState === loadState.STARTED
                }
              />
              <div className="loading-text">Loading...</div>
            </div>
          )}
          { processSubmitLoader && (
             <div className="loading-after-process-submit">
             <Spin
               size={"large"}
               spinning
             />
             <div className="loading-text">Loading...</div>
           </div>
          )}
        </div>
        {!loader && !roles?.includes(ROLES.ROLE_ADMIN) && (
          <div className="progress-bar-container">
            <Storage />
          </div>
        )}

        {!loader &&
          filteredBrainData &&
          filteredBrainData.length > 0 &&
          filteredBrainData.map(
            (item: any, index: any) =>
              index >= minIndex &&
              index < maxIndex && (
                <div className="dashboard-row-container">
                  <div className="thumb-container">
                    {(uploadFileLoadState === loadState.STARTED || uploadFileLoadState === loadState.SUCCESS|| 
                    uploadFileLoadState === loadState.FAILED)&& 
                       <img
                       alt="details"
                       src={
                         item.imagePath
                           ? item.imagePath.includes("nii") ||
                             item.imagePath.includes("tif")
                             ? item.imagePath.includes("nii")
                               ? nii
                               : tif
                             : "https://mvpimagesa.blob.core.windows.net/" +
                               item.userName +
                               "/" +
                               item.fileLocation +
                               "_input" +
                               "/" +
                               item.imagePath
                           : parallel
                       }
                     />}
                     {uploadFileLoadState === loadState.NOTSTARTED  &&
                     ( item.imagePath?.includes("tif") ? 
                     (<div className="tiff-display-container">
                     {uploadFileLoadState === loadState.NOTSTARTED &&  <TIFFPreview file={item} /> }
                      </div>
                      )  :
                    ( <img
                      alt="details"
                      src={
                        item.imagePath
                          ? item.imagePath.includes("nii") ||
                            item.imagePath.includes("tif")
                            ? item.imagePath.includes("nii")
                              ? nii
                              : tif
                            : "https://mvpimagesa.blob.core.windows.net/" +
                              item.userName +
                              "/" +
                              item.fileLocation +
                              "_input" +
                              "/" +
                              item.imagePath
                          : parallel
                      }
                    />
                     ))
                     }
                  </div>
                  <div className="file-detail-container">
                    <div className="file-details">
                      <div>
                        <span className="heading">Batch Name: </span>
                        <span className="file-text">{item.fileLocation}</span>
                        <span className="heading">Uploaded by: </span>
                        <span className="file-text">{item.userName}</span>
                        <span className="heading">Uploaded on: </span>
                        <span className="file-text">
                          {createDate(item.createdAt)}
                        </span>
                        <span className="heading">Organization: </span>
                        <span className="file-text">{item.organization}</span>
                        <span className="heading">Uploaded Files: </span>
                        <span className="file-text">{item.uploadedFiles}</span>
                        {!roles?.includes(ROLES.ROLE_ADMIN) &&
                        item.isProcessed &&
                        item.processInitiated ? (
                          <>
                            <span className="heading">Type: </span>
                            <span className="file-text">
                              {item.processingType}
                            </span>
                          </>
                        ) : !roles?.includes(ROLES.ROLE_ADMIN) &&
                          !item.isProcessed &&
                          item.processInitiated ? (
                          <>
                            <span className="heading">Type: </span>
                            <span className="file-text">
                              {item.processingType}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="button-container">
                      {roles?.includes(ROLES.ROLE_ADMIN) ? (
                        <>
                          {/* view result button */}
                          {item.isProcessed && item.processInitiated ? (
                            <Button
                              type="primary"
                              className="process-button bs-common-button"
                              onClick={(e) => {
                                viewResultDetails(e, index);
                              }}
                            >
                              View Results
                            </Button>
                          ) : !item.isProcessed && item.processInitiated ? (
                            <>
                              {/* Mark Processing Complete button */}
                            
                                <div className="dashboard-message in-process-msg">
                                  File is still in process.
                                </div>
                           
                            </>
                          ) : !item.isProcessed && !item.processInitiated ? (
                            !key[index] ? (
                              item.uploadFinished && item.uploadStarted ? (
                                <Button
                                  key={index}
                                  type="primary"
                                  className="process-button bs-common-button"
                                  onClick={(e) => dropdown(e, index)}
                                >
                                  Process
                                </Button>
                              ) : ( isFileInUpload?  <p className="dashboard-message">
                                                  The file is being uploaded.
                                                </p> :(
                                !item.uploadFinished && !item.uploadStarted && (
                                  <Popconfirm
                                    title="Are you sure to delete this file?"
                                    className="popcon-no-style"
                                    onConfirm={(e) =>
                                      deleteDashBoardRecord(
                                        item.id,
                                        item.fileLocation,
                                        item.userName,
                                        item.uploadedFilesSize,
                                        item.downloadedFilesSize,
                                        item.uploadedFiles
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                  >
                                    <Button
                                      key={index}
                                      type="primary"
                                      className="button-focus delete-button ant-btn css-1s3dcof ant-btn-dangerous"
                                    >
                                      Delete
                                    </Button>
                                  </Popconfirm>
                                )
                              )
                            )) : (
                              !item.processInitiated ? ( <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ borderColor: "#19335C" }}>
                                  <Select
                                    placeholder="Select Processing Type"
                                    style={{ width: "230px" }}
                                    key={index}
                                    value={dropdownValues[index] || undefined}
                                    onChange={(e) => {
                                      dropDownChange(e, index);
                                    }}
                                  >
                                    <Option
                                      value="Parallel Grid System"
                                      style={{
                                        borderBottom: "1px solid #6699CC",
                                      }}
                                    >
                                      <b>PARALLEL GRID SYSTEM</b>
                                    </Option>
                                    <Option
                                      value="Radial Grid System"
                                      style={{
                                        borderBottom: "1px solid #6699CC",
                                      }}
                                    >
                                      <b>RADIAL GRID SYSTEM</b>
                                    </Option>
                                  </Select>
                                </div>
                                <div>
                                  {" "}
                                   <Button
                                    type="primary"
                                    key={index}
                                    style={{
                                      borderColor: "#19335C",
                                      backgroundColor: "#19335C",
                                      cursor: "pointer",
                                      color: "white",
                                      top: "1px",
                                      margin: "0px 10px",
                                    }}
                                    onClick={(e) => {
                                      submit(e, index, item);
                                    }}
                                    disabled={processSubmitLoader}
                                  >
                                    {" "}
                                    Submit
                                  </Button> 
                                </div>
                              </div> ):(
                               ""
                              )
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {item.isProcessed && item.processInitiated ? (
                            <Button
                              type="primary"
                              className="process-button bs-common-button"
                              onClick={(e) => {
                                viewResultDetails(e, index);
                              }}
                            >
                              View Results
                            </Button>
                          ) : !item.isProcessed && item.processInitiated ? (
                            item.processStatus === "Limit-Exceeded" ? (
                              <div className="dashboard-message red-color-message">
                               File Download Limit Exceeded: File is not available for download. 
                               Please delete files from your account to free up space or request an 
                               upgrade of your subscription plan.
                              </div>
                            ) : (
                              <div className="dashboard-message">
                                The file will be processed in approximately 24
                                hrs. You will receive an email once the file is
                                available for download. Please refresh this page
                                periodically to check the updated status.
                                {/* The file will be processed within 24 hrs. You will receive an email once the file is available for download. */}
                              </div>
                            )
                          ) : !item.isProcessed && !item.processInitiated ? (
                            !key[index] ? (
                              item.uploadFinished && item.uploadStarted ? (
                                <Button
                                  key={index}
                                  type="primary"
                                  className="process-button bs-common-button button-focus"
                                  onClick={(e) => dropdown(e, index)}
                                >
                                  Process
                                </Button>
                              ) : ( isFileInUpload?  <p className="dashboard-message">
                                                  The file is being uploaded.
                                                </p> :(
                                !item.uploadFinished && !item.uploadStarted && (
                                  <Popconfirm
                                    title="Are you sure to delete this file?"
                                    className="popcon-no-style"
                                    onConfirm={(e) =>
                                      deleteDashBoardRecord(
                                        item.id,
                                        item.fileLocation,
                                        item.userName,
                                        item.uploadedFilesSize,
                                        item.downloadedFilesSize,
                                        item.uploadedFiles
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                  >
                                    <Button
                                      key={index}
                                      type="primary"
                                      className="button-focus delete-button ant-btn css-1s3dcof ant-btn-dangerous"
                                    >
                                      Delete
                                    </Button>
                                  </Popconfirm>
                                )
                              )
                            )) : (
                              !item.processInitiated ? (<div style={{ display: "flex", margin: "10px" }}>
                                <div>
                                  <Select
                                    placeholder="Select Processing Type"
                                    style={{ width: "230px" }}
                                    key={index}
                                    value={dropdownValues[index] || undefined}
                                    onChange={(e) => {
                                      dropDownChange(e, index);
                                    }}
                                  >
                                    <Option
                                      value="Parallel Grid System"
                                      style={{
                                        borderBottom: "1px solid #6699CC",
                                      }}
                                    >
                                      <b>PARALLEL GRID SYSTEM</b>
                                    </Option>
                                    <Option value="Radial Grid System">
                                      <b>RADIAL GRID SYSTEM</b>
                                    </Option>
                                    {/* <Option value="Parallel and Radial Grid System" style={{ borderBottom: "1px solid #6699CC" }}><b>PARALLEL AND RADIAL GRID SYSTEM</b></Option> */}
                                  </Select>
                                </div>
                                <div>
                                  {" "}
                                  <Button
                                    type="primary"
                                    key={index}
                                    className="bs-common-button submit-button"
                                    onClick={(e) => {
                                      submit(e, index, item);
                                    }}
                                    disabled={processSubmitLoader}
                                  >
                                    {" "}
                                    Submit
                                  </Button> 
                                </div>
                              </div>): ( "")
                            )
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {item.uploadFinished && item.uploadStarted && (
                        <Popconfirm
                          title="Are you sure to delete this file?"
                          className="popcon-no-style"
                          onConfirm={(e) =>
                            deleteDashBoardRecord(
                              item.id,
                              item.fileLocation,
                              item.userName,
                              item.uploadedFilesSize,
                              item.downloadedFilesSize,
                              item.uploadedFiles
                            )
                          }
                          okText="Yes"
                          cancelText="No"
                          placement="topLeft"
                        >
                          <Button
                            key={index}
                            type="primary"
                            className="button-focus delete-button ant-btn css-1s3dcof ant-btn-dangerous"
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      )}
                      {!item.isProcessed && !item.processInitiated && (
                        <div className="pre-process-main-container" key={item.id}>                
                          {item.isPreProcess && 
                            <Button
                              key={"view-pre-proces-button-demo-" + index}
                              type="primary"
                              className="process-button bs-common-button button-focus"
                              onClick={() => callPrePreprocessResults(item)}
                            >
                              View Pre-Processed Result
                            </Button>
                          }
                        { item.uploadFinished && item.uploadStarted && !item.processInitiated &&
                          <Button
                            key={"pre-proces-button-demo-" + index}
                            type="primary"
                            className="process-button bs-common-button button-focus"
                            onClick={() => callPrePreprocess(item)}
                            id={"preProcess_" + item.id}
                          >
                            Pre-Process
                          </Button>
                        }
                        </div>
                      )}
                       {/* { item.isProcessed && item.processInitiated && (
                        <div className="pre-process-main-container" key={item.id}>
                            {item.isPostProcess && 
                            <Button
                              key={"view-pre-proces-button-demo-" + index}
                              type="primary"
                              className="process-button bs-common-button button-focus"
                              onClick={() => callPostPreprocessResults(item)}
                            >
                              Download Data-Analysis Result
                            </Button>
                          }
                          <Button
                            key={"pre-proces-button-demo-" + index}
                            type="primary"
                            className="process-button bs-common-button button-focus"
                            onClick={() => callPostprocess(item)}
                            id={"postProcess_" + item.id}
                          >
                            Data Analysis Tools
                          </Button>
                        </div>
                       )
                        } */}
                    </div>
                  </div>
                </div>
              )
          )}
        {!loader && filteredBrainData && filteredBrainData.length <= 0 && (
          <div className="para">
            Records are not found. Kindly select "Upload New File" to start
            uploading your files for processing.
          </div>
        )}
        {/* pagination*/}
        {loader === false && filteredBrainData.length > 0 && (
          <div className="pagination-container">
            <Pagination
              pageSize={pageSize}
              showSizeChanger={false}
              current={current}
              total={filteredBrainData.length}
              onChange={handlePageChange}
              className="ant-pagination-dashboard"
            />
          </div>
        )}
      </div>
      {!loader && <Footer />}
    </>
  );
};

export default Dashboard;