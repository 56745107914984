/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { tifpreviewApi } from '../../api/login';
import { Spin } from 'antd';

const TIFFPreview = (tiffUrl: any) => {
  const item = tiffUrl.file;

  const [previewData, setPreviewData] = useState('');


  useEffect(() => {
    imageapi()
  }, tiffUrl)

  const imageapi = async () => {
    try {
      const request = {
        containerName: item.userName,
        fileLocation: item.fileLocation,
        imagePath: item.imagePath
      }
      const response = await tifpreviewApi(request);

      const previewData = response.base64URL;
      setPreviewData(previewData);

    } catch (error) {
      console.error('Error fetching TIFF preview:', error);
    }
  }

  return (
    <div>
      {!previewData && <Spin />}
      {previewData && <img src={previewData} alt="TIFF Preview" />}
    </div>

  );
};

export default TIFFPreview;


