import React from "react";
import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "../d_style.css"

const CDTMobileUsersRenderer = (is_cdt_user: any) => {
  return (
    <div style={{ width: "80px" }}>
      <Switch  style={{ backgroundColor: is_cdt_user ? '#00008B' : '#C0C0C0' }}
        size="small"
        disabled={true}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={is_cdt_user}
      />
    </div>
  );
};

export default CDTMobileUsersRenderer;
