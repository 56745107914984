/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.css"
import { loadState } from "../../duck/reducers/commonReducer";
import { Button, Col, Popconfirm, Row, Spin } from "antd";
import { IApplicationState } from "../../duck/reducers";
import { useDispatch, useSelector } from "react-redux";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import pdf from "../../assets/images/icons8-pdf.png";
import { deleteCDTFileOutput } from "../../duck/actions/dashboardActions";

const CDTFILESOUTPUTFILES = (props: any) => {
    const { item } = props;
    const dispatch = useDispatch()
    const [cdtOutputFiles, setCdtOutputFiles] = useState<any>([]);
    const [minIndexblob, setMinIndexblob] = useState<any>(0);
    const [maxIndexblob, setMaxIndexblob] = useState<any>(0);
    const blobpageSize = 10;

    const { itemCDTOUTPUTBlobs, itemCDTOUTPUTBlobsLoadState } = useSelector(
        (state) => (state as IApplicationState).app
    );

    useEffect(() => {
        getCDTOUTPUTBlobsData();
    }, item);

    useEffect(() => {
        if (itemCDTOUTPUTBlobsLoadState === loadState.SUCCESS) getCDTOUTPUTBlobsData();
    }, [itemCDTOUTPUTBlobsLoadState]);


    const getCDTOUTPUTBlobsData = async () => {

        setMinIndexblob(0);
        setMaxIndexblob(blobpageSize);
        let cdtoutputDataDetails = itemCDTOUTPUTBlobs;
        setCdtOutputFiles(cdtoutputDataDetails)
    };


    const handleDeleteConfirm = (item: any) => {
        const requestBody = {
            containerName: item.userName,
            folderPath: item.folder_path,
            item_id: item.id
        }
        props.onDelete();
        dispatch(deleteCDTFileOutput(requestBody))

    };

    return (
        <>
            {itemCDTOUTPUTBlobsLoadState === loadState.STARTED && (
                <div className="main_container spinner-container">
                    <Spin size={"large"} />
                    <p>Loading...</p>
                </div>
            )}
            {itemCDTOUTPUTBlobsLoadState === loadState.SUCCESS && (
                <div className="cdt-output-container">
                    <div className="cdt-no-output-files">
                        {
                            cdtOutputFiles.length === 0 &&
                            (<div className="result-page-message">
                                No output files uploaded for this CDT File.
                            </div>)
                        }
                    </div>
                    <div className="cdt-output-files">
                        {(cdtOutputFiles.length !== 0) &&
                            <div style={{ display: "block", flexWrap: "wrap" }}>
                                {cdtOutputFiles.map(
                                    (blob: any, index: any) =>

                                        index >= minIndexblob &&
                                        index < maxIndexblob && (
                                            <Row
                                                style={{ marginBottom: "5px", cursor: "auto" }}
                                                key={index}
                                            >
                                                <Col>
                                                    <span className="heading">Output Files: </span>
                                                    <span className="file-text">{blob}</span>
                                                    <img
                                                        alt="nii/pdf"
                                                        src={
                                                            blob.includes("nii") || blob.includes("tif") || blob.includes(".pdf")
                                                                ? (blob.includes("nii")
                                                                    ? nii
                                                                    : blob.includes("tif") ? tif : pdf)
                                                                : "https://mvpimagesa.blob.core.windows.net/" +
                                                                item.userName +
                                                                "/" +
                                                                blob
                                                        }
                                                        id="PDFimageStyle"
                                                    />

                                                </Col>

                                            </Row>
                                        )
                                )}
                            </div>
                        }

                    </div>
                    <div className="delete-btn-container">
                        {(cdtOutputFiles.length !== 0) &&
                            <div className="delete-output-btn">
                                {item.id && (
                                    <Popconfirm
                                        title="Are you sure to delete this file?"
                                        className="popcon-no-style"
                                        onConfirm={(e) =>
                                            handleDeleteConfirm(item)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                        placement="topLeft"
                                    >
                                        <Button
                                            key={"delete-output-button-" + item.id}
                                            type="primary"
                                            className="button-focus delete-button ant-btn css-1s3dcof ant-btn-dangerous"
                                        >
                                            Delete All
                                        </Button>
                                    </Popconfirm>
                                )}
                            </div>
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default CDTFILESOUTPUTFILES;
