/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, List, Row, Typography } from "antd";
import React, { useEffect } from "react";
import Right from "../../assets/logo/SubscriptionTickIcon.png";
import { useHistory } from "react-router-dom";
import "./su_style.css";
import logo from "../../assets/logo/logo-beta.png";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIPTION_CONFIG } from './upgradeSub'
import { createSessionCheckout, getCurrentUserProfile, cancelSubscriptionWebAtEnd, checkPlanAvailability } from '../../api/login';
import { IApplicationState } from "../../duck/reducers";
import { getAllUSERTransactions } from "../../duck/actions/commonActions";
import { loadState } from "../../duck/reducers/commonReducer";
import { Tooltip } from 'antd';


//import Title from 'antd/lib/skeleton/Title';
const { Title } = Typography;

const UpgradeAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [planStatus, setPlanStatus] = React.useState<any>('Buy Now');
  const [isLoading, setLoading] = React.useState<boolean>(true);


  const { userTransactionsLoadState, userTransactions, userData, } =
    useSelector((state) => (state as IApplicationState).app);

  React.useEffect(() => {
    let isRedirect = localStorage.getItem("paymentRedirect")
    if(isRedirect && JSON.stringify(isRedirect)){
      localStorage.removeItem('paymentRedirect')
    }

    if (userData.username) {
      initialApicall(userData.username)
    }

  }, [userData]);

  const initialApicall = async (username: any) => {
    setLoading(true)
    dispatch(getAllUSERTransactions({ username: userData.username }));

    try {
      let data = await checkPlanAvailability({ username: username });
      if (data.status === 'ok') {
        setPlanStatus(data?.plan_status)
      } else {
        setPlanStatus('Buy Now')
      }
      setLoading(false)
    } catch (error) {
      setPlanStatus('Buy Now')
      setLoading(false)
      console.log(error, 'error in check plan details')
    }

  }


  const purchase = async (eventData: any) => {
    setLoading(true)
    if (eventData === 'Buy Now') {
      const userDetails = await getCurrentUserProfile();
      if (userDetails.username) {
        const res = await createSessionCheckout({ username: userDetails.username, email: userDetails.email });

        if (res) {
          window?.location?.replace(res?.data?.url);
          setLoading(false)
        }
      }
    } else if (eventData === 'Cancel Premium Plan') {
      const userDetails = await getCurrentUserProfile();
      if (userDetails.username && userTransactionsLoadState === loadState.SUCCESS) {
        const res = await cancelSubscriptionWebAtEnd({ username: userDetails.username, subId: userTransactions[0]?.order_number });
        setLoading(false)
        await initialApicall(userData.username)
      }
    }

  };

  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isCopyPasted) {
      history.push("/login");
    }
  }, []);

  return (
    <>
      <div>
        <Row id="header_div">
          <Col style={{ padding: "10px" }} span={5}>
            <img alt="header-logo" id="header_logo" src={logo} />
          </Col>
        </Row>
        <hr style={{ marginTop: "20px" }} />
        <div className="breadcrumb-container">
          <div className="breadcrumb">
            <a
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </a>
            &nbsp;/ Upgrade Account

          </div>
        </div>
        {/* <div style={{fontSize:"18px", color: "#63666A", marginLeft:"20px"}}><a href="/login" ><img src={backArrow} /></a><strong >Subscription</strong></div> */}
        {/* <div style={{ fontSize: "18px", color: "#63666A", marginLeft: "20px" }}>
        <img alt="backArrow" src={backArrow} onClick={() => history.push("/login")} style={{cursor: "pointer",paddingRight:"15px" }}/>
        <strong >Subscription</strong>
      </div> */}

        <br />
        <div>
          <div style={{ margin: "10px" }}>
            <Row style={{ justifyContent: 'center' }}>
              {SUBSCRIPTION_CONFIG.plans.map((keyItem, keyIndex) => (
                <Col className="subscriptionRows" key={keyIndex}>
                  <Card
                    className="subscription-card"
                    key={keyIndex}
                    title={
                      <div>
                        <Title
                          level={1}
                          style={{
                            fontSize: "20px",
                            color: "white",
                            margin: "10px",
                          }}
                        >
                          {`${keyItem.plan} ${keyItem.planPrice}`}
                          {keyItem.strikeText !== "" &&
                            <>&nbsp;(<s>{keyItem.strikeText}</s>)</>
                          }
                        </Title>
                        <Title
                          level={3}
                          style={{
                            fontSize: "13px",
                            color: "white",
                            margin: "10px",
                          }}
                        >{`${keyItem.subplan}`}</Title>
                      </div>
                    }
                  >
                    <List
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      dataSource={keyItem.details}
                      renderItem={(item: any, index: any) => (
                        <List.Item>
                          {item && (
                            <div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details1}</span>
                              </div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details2}</span>
                              </div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details3}</span>
                              </div>
                              {item.details4 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details4}</span>
                                </div>
                              )}
                              {item.details5 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details5}</span>
                                </div>
                              )}
                              {item.details6 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details6}</span>
                                </div>
                              )}
                              <hr
                                style={{
                                  marginTop: "25px"
                                }} />
                              <div
                                style={{
                                  fontSize: "20px",
                                  color: "#3468B8",
                                  textAlign: "center",
                                }}
                              >
                                <strong>{keyItem.plan}</strong>
                              </div>
                              <hr />

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "25px 0px",
                                }}
                              >
                                {planStatus === 'Auto Payment Cancelled' ? (
                                  <Tooltip placement="bottom" title="Once billing cycle ends can upgrade account again.">
                                    <span style={{ textDecoration: "underline" }}>Auto Payment Cancelled</span>
                                  </Tooltip>
                                ) : (
                                  <Button
                                    key={keyIndex}
                                    loading={isLoading}
                                    disabled={isLoading}
                                    style={{
                                      backgroundColor: "#19335C",
                                      borderColor: "#19335C",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => purchase(planStatus)}
                                  >
                                    <span style={{ color: "white" }}>{planStatus}</span>
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                        </List.Item>
                      )}
                    ></List>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default UpgradeAccount;
