import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleWare from "redux-thunk";
import logger from "redux-logger"; // (Use when required)
import rootReducer, { IApplicationState } from "./reducers";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createHashHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
const initialState = (window as any).initialReduxState as IApplicationState;

export default function configureStore() {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [thunkMiddleWare, reactRouterMiddleware];

  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger); //(Use when required)
  }

  return createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};


