/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Row, Col, Spin, Tooltip } from "antd";
// @ts-ignore
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo/logo-beta.png";
import "./style.css";
import openNotification from "../../common/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../duck/actions/commonActions";
import { loadState } from "../../duck/reducers/commonReducer";
import { IApplicationState } from "../../duck/reducers";
import Auth from "../../utils/auth";
import { LOGIN_NOTSTARTED } from "../../duck/types/types";
import FooterLogin from "../footer";
import { API_FLASK_URL } from "../../constants/apiBaseUrl";
import { tiffoldersdelete } from "../../api/login";
import { isAuthenticated } from "../../ProtectedRoute";

const layout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 20,
  },
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [autologoutMesssage, setautologoutMesssage] = useState(false);
  let logoutMessage = <p>To keep your account secure, we have logged you out due to inactivity. Please log back again to continue.</p>

  const { loginLoadState, error, userData } = useSelector(
    (state) => (state as IApplicationState).app
  );
  useEffect(() => {
    if (Auth.isAuthenticated() && loginLoadState === loadState.SUCCESS) {
      const deletefolder = tiffoldersdelete();
      history.push("/dashboard");
      fetch(API_FLASK_URL + `/delete`, {
        method: "DELETE",
      }).then((res) => res);

    } else if (loginLoadState === loadState.FAILED) {
      let errorObj = {
        type: "error",
        message: "Error",
        description: error,
      };
      openNotification(errorObj);
      dispatch({ type: LOGIN_NOTSTARTED })
    }
  }, [loginLoadState]);

  useEffect(() => {
    if (localStorage.getItem('autologout') === "true") {
      setautologoutMesssage(true);
    }
  })

  const onLoginSubmit = (values: any) => {
    values.username = values.username.toLowerCase();
    dispatch(loginUser(values));
  };
  document.addEventListener("click", (evt) => {
    if (autologoutMesssage === true) {
      document.getElementById("logout-message");
      setautologoutMesssage(false);
      localStorage.removeItem('autologout')
    }

  });

  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isAuthenticated()){
      if (!isCopyPasted) {
        history.push("/dashboard");
      }
    }
}, []);

  return (
    <>
      <div className="login-box">
        <div className="login-box-inner">
          <div>
            <img alt="logo" src={logo} />
          </div>
          {loginLoadState === loadState.STARTED && (
            <Row>
              <Col style={{ marginBottom: "5px" }} span={20}>
                <div className="loading">
                  <Spin size={"large"} />
                </div>
              </Col>
            </Row>
          )}
          <div className="login-form">
            <h1>Login</h1>
            <Form
              className="loginFormSpacing"
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onLoginSubmit}
            >
              <Tooltip placement="rightBottom" color="red" title="required field">
                <label>Username*</label>
              </Tooltip>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please enter your username!" },
                ]}
              >
                <Input id="username" placeholder="Username" />
              </Form.Item>

              <Tooltip placement="rightBottom" color="red" title="required field">
                <label>Password*</label>
              </Tooltip>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password id="password" placeholder="Password" />
              </Form.Item>
              <div id="logout-message">{autologoutMesssage && logoutMessage}</div>
              <Form.Item className="sign-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  id="loginBtn"
                  className="signin-buttons"
                  disabled={loginLoadState === loadState.STARTED}
                >
                  Login
                </Button>

                <Form.Item style={{ marginBottom: "0px", textAlign: "center" }}>
                  <br></br>
                  <Button
                    type="primary"
                    htmlType="submit"
                    id="signUpBtn"
                    onClick={() => history.push("/subscription")}
                  >
                    Create New Account
                  </Button>
                  <p className="create-account-text">
                    By creating an account and using the ShapeGenie application by BrainScanology, you agree to our Terms of Service and Terms of Use.
                  </p>
                </Form.Item>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <FooterLogin />
    </>
  );
};

export default Login;
