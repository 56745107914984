import { AnyAction } from 'redux';
import { createZipPostProcess, createZipPreProcess, deleteCDTOUTPUTInAzure, deleteCDTRecord, deleteDashBoardRecord, fetchCDTFilesOfAUserBlobs, fetchPostProcessBlobs, fetchPreProcessBlobs, fetchSingleItemCDTBlobs, fetchSingleItemCDTOutputBlobs, getTotalRecordsCount, preprocessPythonApiCall } from '../../api/dashboardApi';
import {
   GET_TOTAL_RECORDS_COUNT,
   DELETE_DASHBOARD_SUCCESS,
   DELETE_DASHBOARD_STARTED,
   DELETE_DASHBOARD_FAILED,
   BLOBS_LOAD_STARTED,
   BLOBS_LOAD_SUCCESS,
   BLOBS_LOAD_FAILED,
   CREATE_ZIP_STARTED,
   CREATE_ZIP_SUCCESS,
   CREATE_ZIP_FAILED,
   DELETE_CDT_DASHBOARD_STARTED,
   DELETE_CDT_DASHBOARD_SUCCESS,
   DELETE_CDT_DASHBOARD_FAILED,  
   POST_PROCESS_BLOBS_LOAD_STARTED,
   POST_PROCESS_BLOBS_LOAD_SUCCESS,
   POST_PROCESS_BLOBS_LOAD_FAILED,
   CDT_ITEM_BLOBS_LOAD_STARTED,
   CDT_ITEM_BLOBS_LOAD_SUCCESS,
   CDT_ITEM_BLOBS_LOAD_FAILED,
   CDT_USER_FILES_BLOBS_LOAD_FAILED,
   CDT_USER_FILES_BLOBS_LOAD_SUCCESS,
   CDT_USER_FILES_BLOBS_LOAD_STARTED,
   CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED,
   CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS,
   CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED,
   PRE_PROCESS_API_CALL_STARTED,
   PRE_PROCESS_API_CALL_SUCCESS,
   PRE_PROCESS_API_CALL_FAILED,
} from '../types/types';


// @ts-ignore
export const getTotalCount = ():AnyAction => (dispatch:any, getState:any) => {

  getTotalRecordsCount().then((res)=>{
    dispatch(
      {type:GET_TOTAL_RECORDS_COUNT,payload: res?.totalRecords ?? 0,}
    )
  });

};

export const deleteDashBoard =
  (itemId: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type: DELETE_DASHBOARD_STARTED, payload: false });

    deleteDashBoardRecord(itemId)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: DELETE_DASHBOARD_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type: DELETE_DASHBOARD_FAILED, payload: error.message });
        /*else if (error.name === "SignupError")
          dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/ else
          dispatch({ type: DELETE_DASHBOARD_FAILED, payload: error.message });
      });
  };
  
  export const deleteCDTDashBoard =
  (item: any): AnyAction =>
  // @ts-ignore
  (dispatch: any, getState: any) => {
    dispatch({ type:  DELETE_CDT_DASHBOARD_STARTED, payload: false });
    deleteCDTRecord(item)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type:  DELETE_CDT_DASHBOARD_SUCCESS, payload: res });
      })
      .catch((error: any) => {
        if (error.name === "ServerError")
          dispatch({ type:  DELETE_CDT_DASHBOARD_FAILED, payload: error.message });
        else
          dispatch({ type:  DELETE_CDT_DASHBOARD_FAILED, payload: error.message });
      });
  };
  export const getBlobsPreProcess =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: BLOBS_LOAD_STARTED, payload: false });

      fetchPreProcessBlobs(requestBody)
        .then((res) => {
          //Auth.login(res);
          dispatch({ type: BLOBS_LOAD_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: BLOBS_LOAD_FAILED, payload: error.message });
          /*else if (error.name === "SignupError")
            dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/ else
            dispatch({ type: BLOBS_LOAD_FAILED, payload: error.message });
        });
    };

    export const createZipFile =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: CREATE_ZIP_STARTED, payload: false });

      createZipPreProcess(requestBody)
        .then((res: { fileArray: any; }) => {
          //Auth.login(res);
          dispatch({ type: CREATE_ZIP_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: CREATE_ZIP_FAILED, payload: error.message });
           else
            dispatch({ type: CREATE_ZIP_FAILED, payload: error.message });
        });
    };

    export const getBlobsPostProcess =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: POST_PROCESS_BLOBS_LOAD_STARTED, payload: false });

      fetchPostProcessBlobs(requestBody)
        .then((res) => {
          //Auth.login(res);
          dispatch({ type: POST_PROCESS_BLOBS_LOAD_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: POST_PROCESS_BLOBS_LOAD_FAILED, payload: error.message });
          /*else if (error.name === "SignupError")
            dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.message });*/ else
            dispatch({ type: POST_PROCESS_BLOBS_LOAD_FAILED, payload: error.message });
        });
    };  
  
 export const createZipFileForPostProcess =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: CREATE_ZIP_STARTED, payload: false });

      createZipPostProcess(requestBody)
        .then((res: { fileArray: any; }) => {
          //Auth.login(res);
          dispatch({ type: CREATE_ZIP_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: CREATE_ZIP_FAILED, payload: error.message });
           else
            dispatch({ type: CREATE_ZIP_FAILED, payload: error.message });
        });
    };

    export const getCDTBlobsItem =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: CDT_ITEM_BLOBS_LOAD_STARTED, payload: false });

      fetchSingleItemCDTBlobs(requestBody)
        .then((res) => {
          //Auth.login(res);
          dispatch({ type: CDT_ITEM_BLOBS_LOAD_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: CDT_ITEM_BLOBS_LOAD_FAILED, payload: error.message });
             else
            dispatch({ type: CDT_ITEM_BLOBS_LOAD_FAILED, payload: error.message });
        });
    };

    //get cdt files of a user for admin
    export const getBlobsCDTFilesOfAUser =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: CDT_USER_FILES_BLOBS_LOAD_STARTED, payload: false });

      fetchCDTFilesOfAUserBlobs(requestBody)
        .then((res) => {
          //Auth.login(res);
          dispatch({ type: CDT_USER_FILES_BLOBS_LOAD_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: CDT_USER_FILES_BLOBS_LOAD_FAILED, payload: error.message });
          else
            dispatch({ type: CDT_USER_FILES_BLOBS_LOAD_FAILED, payload: error.message });
        });
    };

    export const getCDTOutputBlobsItem =
    (requestBody: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type: CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED, payload: false });

      fetchSingleItemCDTOutputBlobs(requestBody)
        .then((res) => {
          dispatch({ type: CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS, payload: res.fileArray });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type: CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED, payload: error.message });
             else
            dispatch({ type: CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED, payload: error.message });
        });
    };

    export const deleteCDTFileOutput =
    (item: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type:  DELETE_CDT_DASHBOARD_STARTED, payload: false });
      deleteCDTOUTPUTInAzure(item)
        .then((res) => {
          //Auth.login(res);
          dispatch({ type:  DELETE_CDT_DASHBOARD_SUCCESS, payload: res });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type:  DELETE_CDT_DASHBOARD_FAILED, payload: error.message });
          else
            dispatch({ type:  DELETE_CDT_DASHBOARD_FAILED, payload: error.message });
        });
    };

    export const preProcessPython =
    (item: any): AnyAction =>
    // @ts-ignore
    (dispatch: any, getState: any) => {
      dispatch({ type:  PRE_PROCESS_API_CALL_STARTED, payload: false });
      preprocessPythonApiCall(item)
        .then((res) => {
          dispatch({ type:  PRE_PROCESS_API_CALL_SUCCESS, payload: res });
        })
        .catch((error: any) => {
          if (error.name === "ServerError")
            dispatch({ type:  PRE_PROCESS_API_CALL_FAILED, payload: error.message });
          else
            dispatch({ type:  PRE_PROCESS_API_CALL_FAILED, payload: error.message });
        });
    };