/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import { Button, DatePicker, Input, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import openNotification from "../../common/Notifications";
import {
  getAllUsers, getAllUsersByDate,
} from "../../duck/actions/commonActions";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import { UPDATE_USER_NOTSTARTED, UPLOAD_NOTSTARTED } from "../../duck/types/types";
import Footer from "../footer/footer";
import Header from "../header";
import "./d_style.css";
import OutputUploadRenderer from "./renderers/OutputUploadRenderer";
import CdtResetRenderer from "./renderers/CdtReserRenderer";
import CdtUpgradeRenderer from "./renderers/CdtUpgradeRenderer";
import ViewFilesRenderer from "./renderers/ViewFilesRenderer";
import CDTMobileUsersRenderer from "./renderers/CdtMobileUsersRenderer";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import DateRenderer from "./renderers/DateRenderer";


const ManageCDTUsers = () => {

  const {
    uploadFileLoadState
  } = useSelector((state) => (state as IApplicationState).app);

  const [loader] = useState<any>(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  const [usersAll, setUsersAll] = useState(false)

  const CDTUserscolumns = [
    {
      title: 'Username',
      dataIndex: 'username', //  key of  data from Redux
      sorter: (a: { username: string; }, b: { username: string; }) => a.username.localeCompare(b.username),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search  UserName"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : "black" }} />,
      onFilter: (value: any, record: { username: any }) => record.username.toLowerCase().includes(value.toLowerCase()),
      // The onFilter function is used to perform the search based on the 'username' column
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Date',
      key: "createdat",
      dataIndex: 'createdAt',
      render: (date: any, record: any) => DateRenderer(date),
      sorter: (a: { createdAt: moment.MomentInput; }, b: { createdAt: moment.MomentInput; }) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },

    {
      title: 'CDT Users',
      dataIndex: 'is_cdt_user',
      render: (is_cdt_user: any) => CDTMobileUsersRenderer(is_cdt_user),
    },
    {
      title: 'CDT OpenMax',
      dataIndex: 'is_cdt_openmax',
      render: (cellValue: any, record: any) => CdtUpgradeRenderer(record),
    },
    {
      title: 'Reset',
      render: (cellValue: any, record: any) => CdtResetRenderer(record),
    },
    {
      title: 'Upload Scores Output',
      render: (cellValue: any, record: any) => OutputUploadRenderer(record),
    },
    {
      title: 'View Files',
      render: (cellValue: any, record: any) => ViewFilesRenderer(record),
    },

  ];


  const { updateUserLoadState, manageUsersLoadState, users, error } =
    useSelector((state) => (state as IApplicationState).app);

  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isCopyPasted) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(getAllUsers(""));
    if (usersAll) {
      setUsersAll(false)
    }
  }, [usersAll]);

  useEffect(() => {
    if (updateUserLoadState === loadState.SUCCESS) {
      let successObj = {
        type: "success",
        message: "User status was updated successfully.",
      };
      openNotification(successObj);
      dispatch({ type: UPDATE_USER_NOTSTARTED });
    } else if (updateUserLoadState === loadState.FAILED) {
      let errorObj = {
        type: "error",
        message: error,
      };
      openNotification(errorObj);
      dispatch({ type: UPDATE_USER_NOTSTARTED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserLoadState]);

  useEffect(() => {
    if (uploadFileLoadState === loadState.SUCCESS) {

      let successObj = {
        type: "success",
        message: "File Uploaded Successfully. Kindly refresh the page",
      };
      openNotification(successObj);
      dispatch({ type: UPLOAD_NOTSTARTED });

    } else if (uploadFileLoadState === loadState.FAILED) {

      let errorObj = {
        type: "error",
        message: error,
      };
      openNotification(errorObj);
      dispatch({ type: UPLOAD_NOTSTARTED });
    }
  }, [uploadFileLoadState]);

  const { RangePicker } = DatePicker;

  const handleSubmit = () => {

    let startDate = dates[0];
    let endDate: Date = dates[1];
    let datePlusone = new Date(endDate);
    datePlusone.setDate(datePlusone.getDate() + 1);
    let b = new Date(datePlusone)
    endDate = b;

    if (startDate === endDate) {
      let dateDet = new Date(endDate);
      dateDet.setDate(dateDet.getDate() + 1);
      let b = new Date(dateDet)
      endDate = b;
    }

    let obj3 = {
      Fromdate: startDate,
      Todate: endDate
    }
    if (dates.length === 0) {
      setUsersAll(true)
    } else {
      dispatch(getAllUsersByDate(obj3));
    }

  }


  return (
    <>
      <Header prop={loader} />
      <div className="users-container">
        <div className="breadcrumb-container">
          <div className="breadcrumb">
            <a
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </a>
            &nbsp;/ Manage CDT Users
            <div className="user-count">Total Users: {users.length}</div>
          </div>
          <div className="calendar-container">
            <div>
              <RangePicker
                onChange={(values: any) => {
                  if (values) {
                    setDates(values.map((item: moment.MomentInput) => {
                      return moment(item).format('YYYY-MM-DD')
                    }))
                  }
                  else setDates([])
                }}
              />
            </div>
            <div className="calendar-button">
              <Button type="primary" id="calendarBtn" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>

        </div>
        {(uploadFileLoadState === loadState.STARTED) && (
          <div className="loading-cdt-upload-text">File is getting uploaded. Please do not refresh the page.
            <div className="spin-cdt-upload">
              <Spin
                size={"large"}
                spinning={
                  uploadFileLoadState === loadState.STARTED
                }
              /></div>
          </div>
        )}
        {(manageUsersLoadState === loadState.STARTED ||
          updateUserLoadState === loadState.STARTED) && (
            <div className="loading">
              <Spin size={"large"} />
              <div className="loading-text">Loading...</div>
            </div>
          )}

        <div className="grid-container">
          {manageUsersLoadState === loadState.SUCCESS && users.length > 0 && (
            <Table
              className="users-table"
              scroll={{ x: true }}
              pagination={{ showSizeChanger: true }}
              columns={CDTUserscolumns}
              dataSource={users}
            />
          )}

          {manageUsersLoadState === loadState.SUCCESS && users.length === 0 && (
            <span className="not-found-error-msg">User not found</span>
          )}
        </div>
      </div>
      {manageUsersLoadState === loadState.SUCCESS &&
        <Footer />
      }
    </>
  );
};

export default ManageCDTUsers;