import React from "react";


const PDFPreview = ({ pdfURL }: any) => {
  

  return (
    <div>
      PDF preview is not available.You can download and check the PDF.
      
    </div>
  );
};

export default PDFPreview;
