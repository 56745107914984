import React from "react";
import logo from "../../assets/logo/logo-beta.png";
import Footer from "../footer/footer";
import "./TermsOfService.css";

function TermsOfService() {
    return (
        <div className="parent-container">
            <header className="header loggedout-header">
                <div className="header-container">
                    <div className="logo">
                        <img alt="header-logo" src={logo} />
                    </div>
                </div>
            </header>
            <div className="body-heading">
            <h1>Terms Of Service</h1>
                <div className="coming-soon-text">
                    <h1>Coming Soon</h1>
                </div>
            </div>
            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
}

export default TermsOfService;
