/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const FooterLogin = () => {
  return (
    <>
      <div className="footer-shades"></div>
      <div className="footer-container">
        <div className="footer-links">
          <ul>
            <li>
              <a
                href="https://www.brainscanology.com/tutorials"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Demo
              </a>
            </li>
            <li>
              <a href="mailto:feedback@brainscanology.com">Contact Us</a>
            </li>
            <li>
            <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>           
           </li>
            {/* <li>
              <a>Terms of Use</a>
            </li>
            <li>
              <a>Terms of Service</a>
            </li> */}
          </ul>
          <ul className="ccpa-container">
            <li>
            <a href="mailto:support@brainscanology.com?subject=New CCPA Request&body=Thanks for reaching out. Please provide the following information for us to process your request.%0D%0A
%0D%0A
1. Please confirm that you are a resident of the state of California.
%0D%0A%0D%0A
2. Your email address: Prefill the email address, if available.
%0D%0A%0D%0A
3. Type of CCPA Request:
%0D%0A%0D%0A
Right to Know %0D%0A%0D%0A
Right to Delete: %0D%0A%0D%0A
Unsubscribe: %0D%0A%0D%0A

We will process your request within the allowed days for processing CCPA requests. %0D%0A%0D%0A
">CA: Do Not Sell My Personal Info</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default FooterLogin;
