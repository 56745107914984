/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getProfile } from "./duck/actions/commonActions";

let jwt = require('jsonwebtoken');

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false
    }
    if (sessionStorage.getItem('user_details')) {
      // @ts-ignore
        let data = JSON.parse(sessionStorage.getItem('user_details')) 
        var decodedToken=jwt.decode(data.accessToken, {complete: true});
        document.addEventListener("click", (evt) => {
          if(Date.now() > (decodedToken.payload.exp *1000)){
            localStorage.setItem('autologout',"true")
         }
        });
        if(Date.now() > (decodedToken.payload.exp *1000)){
           localStorage.setItem('autologout',"true")
           return false;
        }
        else {
          return true;
      }
    } else {
        return true;
    }
  
}
// @ts-ignore
function ProtectedRoute({ component: Component, ...restOfProps }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated()) dispatch(getProfile());
  }, []);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
       isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;
