import { Button, Select } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./s_style.css";
import { updateSubscription } from "../../duck/actions/commonActions";
import openNotification from "../../common/Notifications";
import { SUBSCRIPTION_CONFIG } from "../../constants/commonConstants";

const SubcriptionUpdate = (props: any) => {
  const { row } = props;
  const dispatch = useDispatch();
  const subscriptionRef = useRef(0);
  const [subsType, setSubsType] = useState(row?.subscription_type);

  useEffect(() => {
    setSubsType(row?.subscription_type)
  }, [row])

  const handleSubscriptionPlanChange = (e: any) => {
    subscriptionRef.current = e;
    setSubsType(e);
  };

  const subscriptionDetailsConfig = SUBSCRIPTION_CONFIG;

  const handleSubmitSubscriptionPlan = (id: number) => {
    console.log(subscriptionRef.current, id);
    const userSubscriptionDetails: any = subscriptionDetailsConfig.plans.find(
      ({ subscriptionType }) => subscriptionType === subscriptionRef.current
    );
    if (subscriptionRef.current === 0) {
      let successObj = {
        type: "success",
        message: "User is already using the same plan",
      };
      openNotification(successObj);
    }
    else {
      let obj = { subscription_type: subscriptionRef.current, id: id, subscription: userSubscriptionDetails?.plan };
      dispatch(updateSubscription(obj));
    }
  };

  const subscriptionOptions: any = [];
  // eslint-disable-next-line array-callback-return
  subscriptionDetailsConfig.plans.map((item) => {
    subscriptionOptions.push({ value: item.subscriptionType, label: item.plan });
  });
  return (
    <div style={{ display:"flex"}}>
      <Select
        value={subsType ? subsType : 1}
        onChange={(e) => handleSubscriptionPlanChange(e)}
        className="subscription-dropdown"
        options={subscriptionOptions}
      />
      <Button className="active-button" onClick={() => handleSubmitSubscriptionPlan(row.id)}>Update</Button>
    </div>
  );
};

export default SubcriptionUpdate;
