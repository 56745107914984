import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Row, Col, Spin } from "antd";
// @ts-ignore
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo/logo-beta.png";
import "./style.css";
import openNotification from "../../common/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../duck/actions/commonActions";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import { SIGNUP_NOTSTARTED } from "../../duck/types/types";

const layout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 20,
  },
};

const Signup = () => {
  const [userMessage, setUserMessage] = useState("Enter a valid username");
  const [userName, setUserName] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(
    "Enter a valid password"
  );
  const [password, setPassword] = useState("");
  const [emailMessage, setEmailMessage] = useState("Enter a valid email");
  const [email, setEmail] = useState("");
  const [organizationMessage, setOrganizationMessage] = useState(
    "Enter a valid organization"
  );
  const [organization, setOrganization] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { signupLoadState, error } = useSelector(
    (state) => (state as IApplicationState).app
  );
  const { subscription } = useSelector(
    (state) => (state as IApplicationState).app.userData
  );

  const userSubscription = subscription ? subscription : sessionStorage.getItem('subscription');
  useEffect(() => {
    if (signupLoadState === loadState.SUCCESS) {
      let successObj = {
        type: "success",
        message: "Account Created",
        description: "Kindly login to explore more features",
      };
      sessionStorage.removeItem("subscription");
      openNotification(successObj);
      dispatch({ type: SIGNUP_NOTSTARTED });
      history.push("/login");
    } else if (signupLoadState === loadState.FAILED) {
      let errorObj = {
        type: "error",
        message: "Error",
        description: error,
      };
      openNotification(errorObj);
      dispatch({ type: SIGNUP_NOTSTARTED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupLoadState]);

  const onSignupSubmit = (values: any) => {
    if (
      userMessage === "" &&
      passwordMessage === "" &&
      emailMessage === "" &&
      organizationMessage === ""
    ) {
      if (password === values["confirm password"]) {
        let serviceRequest = {
          username:
            values.username && values.username !== ""
              ? values.username.trim().toLowerCase()
              : "",
          password: password,
          subscription: "Basic Account",// userSubscription, Update Premium Account subscription details after creating account else Basic Account
          email: email.trim(),
          organization: organization,
        };
        if(userSubscription === 'Premium Account'){
          localStorage.setItem("paymentRedirect",JSON.stringify(true));
        }
        dispatch(signupUser(serviceRequest));
      } else {
        let errorObj = {
          type: "error",
          message: "Error",
          description:
            "Passwords do not match.Make sure your password is not empty.",
        };
        openNotification(errorObj);
      }
    } else {
      let errorObj = {
        type: "error",
        message: "Error",
        description: "Kindly validate the fields.",
      };
      openNotification(errorObj);
    }
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: any) => {
    if (id === "username") {
      let regex = new RegExp("^[a-zA-Z][a-zA-Z0-9]{2,}$");
      if (e.target.value !== "") {
        if (regex.test(e.target.value) === false) {
          setUserName(e.target.value);
          setUserMessage(
            "Username should be more than two characters.Username should not contain special characters.It should not start with digits.Spacing is not allowed."
          );
        } else {
          setUserName(e.target.value);
          setUserMessage("");
        }
      } else {
        setUserMessage("Username cannot be empty");
        setUserName(e.target.value);
      }
    } else if (id === "password") {
      let regexPassword = new RegExp(
        "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$#!%*^?&-])[A-Za-z0-9@$#^!%*?&-]{6,}$"
      );
      if (e.target.value !== "") {
        if (regexPassword.test(e.target.value) === false) {
          setPassword(e.target.value);
          setPasswordMessage(
            "Password must contain at least: one lowercase letter, one uppercase letter, one number, and one special character not including parenthesis (!@#$%^&*). The minimum length is six characters."
          );
        } else {
          setPassword(e.target.value);
          setPasswordMessage("");
        }
      } else {
        setPassword(e.target.value);
        setPasswordMessage("Password cannot be empty");
      }
    } else if (id === "email") {
      let regexemail = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$");
      if (e.target.value !== "") {
        if (regexemail.test(e.target.value) === false) {
          setEmail(e.target.value);
          setEmailMessage("Enter a valid Email.");
        } else {
          setEmail(e.target.value);
          setEmailMessage("");
        }
      } else {
        setEmailMessage("Email cannot be empty");
        setEmail(e.target.value);
      }
    } else {
      if (e.target.value !== "") {
        setOrganization(e.target.value);
        setOrganizationMessage("");
      } else {
        setOrganization(e.target.value);
        setOrganizationMessage("Organization cannot be empty");
      }
    }
  };

  const checkUsernamePassword = (e: React.ChangeEvent<HTMLInputElement>, id:any)=>{
    if(id === "username")
        if(e.target.value === userName)
        setPasswordMessage(
          "Password shouldn't be same as username."
        );
    else if(id === "password")
      if(e.target.value === password)
      setUserMessage(
        "Username shouldn't be same as password."
      );
  }

  return (
    <div>
      <Card id="signupCard">
        <Row>
          <Col span={24}>
            <img alt="logo" src={logo} className="signup-logo" />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginBottom: "5px" }} span={20}>
            <span id="signin">Signup</span>
          </Col>
        </Row>
        {signupLoadState === loadState.STARTED && (
          <Row>
            <Col style={{ marginBottom: "5px" }} span={20}>
              <div className="loading">
                <Spin size={"large"} />
              </div>
            </Col>
          </Row>
        )}
        <Form {...layout} name="basic" onFinish={onSignupSubmit}>
          <label>Username</label>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                pattern: new RegExp("^[a-zA-Z][a-zA-Z0-9]{2,}$"),
                message: userMessage,
              }
            ]}
          >
            <Input
              id="username"
              placeholder="Enter Your Username Here..."
              value={userName}
              onBlur={(e) => {
                checkUsernamePassword(e, "password");
              }}
              onChange={(e) => {
                onInputChange(e, "username");
              }}
            />
          </Form.Item>
          <label>Password</label>
          <Form.Item
            name="password"
            rules={[
              {
                // min:6,
                required: true,
                message: passwordMessage,
                //If you need a specific pattern type in Password used the below Regular Expression
                pattern: new RegExp(
                  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$#!%*^?&-])[A-Za-z0-9@$#^!%*?&-]{6,}$"
                ),
              }
            ]}
          >
            <Input.Password
              id="password"
              placeholder="Enter Your Password Here..."
              value={password}
              onBlur={(e) => {
                checkUsernamePassword(e, "username");
              }}
              onChange={(e) => {
                onInputChange(e, "password");
              }}
            />
          </Form.Item>
          <label>Confirm Password</label>
          <Form.Item
            name="confirm password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Confirm Password Should Match Password!",
              },
              () => ({
                validator(_, value) {
                  if (password === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              id="confirm password"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <label>Email</label>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: emailMessage,
                pattern: new RegExp(
                  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
                ),
              },
            ]}
          >
            <Input
              id="email"
              placeholder="Enter Your E-mail"
              value={email}
              onChange={(e) => {
                onInputChange(e, "email");
              }}
            />
          </Form.Item>
          <label>Organization</label>
          <Form.Item
            name="organization"
            rules={[
              {
                required: true,
                message: organizationMessage,
              },
              () => ({
                validator(_, value) {
                  let regexOrganization = new RegExp(
                    '^[`!@#$%^&*_+-={};"|,.<>?~ ]*$'
                  );
                  let regexNumbers = new RegExp("^[0-9]*$");
                  if (
                    regexOrganization.test(organization.charAt(0)) === false ||
                    regexNumbers.test(organization.charAt(0))
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Organization should not start with a special character or with a space.Characters like [] and () are allowed"
                    )
                  );
                },
              }),
            ]}
          >
            <Input
              id="organization"
              placeholder="Enter Your Organization"
              value={organization}
              onChange={(e) => {
                onInputChange(e, "organization");
              }}
            />
          </Form.Item>

          <Form.Item className="sign-btn">
            <Button type="primary" htmlType="submit" id="loginBtn">
              Signup
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Signup;
