import React from "react";
import { Switch, Popconfirm } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { EMAILS } from "../../../constants/commonConstants";
import { capitalizeFirstLetter } from "../../../utils/commonUtils";
import { sendMail } from "../../../api/login";
import { useDispatch } from "react-redux";
import { CDTUserUpgrade } from "../../../duck/actions/commonActions";

const CdtUpgradeRenderer = (row: any) => {
  const item = row;
  const dispatch = useDispatch();

  const handleCDTUpgradeOfaUser = async () => {

    let obj = { is_cdt_openmax: !item.is_cdt_openmax, id: item.id };
    if (obj.is_cdt_openmax) {
      let mailRequest = {};
      mailRequest = {
        from: EMAILS.Notifications_id,
        to: item.email,
        subject:
          "CDT Account Upgrade ",
        text: "Hello world ",
        html:
          "<p>Dear " +
          capitalizeFirstLetter(item.username) +
          ",</p><p>Your CDT Account upgrade request has been processed. " +
          "You can login to your account and check the benefits. </p><p>Best Regards</p><p>CDT Open Team</p>",
      };
      //api for sending mail
      await sendMail(mailRequest);
    }

    dispatch(CDTUserUpgrade(obj));
  };

  return (
    <div style={{ width: "100px" }}>
      <Popconfirm
        className="popcon-no-style"
        title={`Are you sure to ${!row.is_cdt_openmax ? 'upgrade' : 'degrade'} CDT account of this user`}
        onConfirm={handleCDTUpgradeOfaUser}
        okText="Yes"
        cancelText="No"
        placement="topLeft"
      >
        <Switch style={{ backgroundColor:  !!row.is_cdt_openmax ? '#32CD32' : '#C0C0C0'  }}
          size="small"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!row.is_cdt_openmax}
        />
      </Popconfirm>
    </div>
  );
};

export default CdtUpgradeRenderer;
