export const USERS:any={

"demo":"demo@123",
"ahmed":"Ahmed@123$",
"pradeep":"Pradeep@123",
"daniel":"Daniel@123",
"jonathan":"Jonathan@123",
"amit":"Amit@123"


}

export const ROLES:any = {
    "ROLE_ADMIN": "ROLE_ADMIN"
}