import { Button, Card, Col, List, Row, Typography } from "antd";
import React from "react";
import Right from "../../assets/logo/SubscriptionTickIcon.png";
import backArrow from "../../assets/logo/BackArrow.png";
import { useHistory } from "react-router-dom";
import "./su_style.css";
import logo from "../../assets/logo/logo-beta.png";
import { updateSubcription } from "../../duck/actions/commonActions";
import { useDispatch } from "react-redux";
import { SUBSCRIPTION_CONFIG } from '../../constants/commonConstants'
//import Title from 'antd/lib/skeleton/Title';
const { Title } = Typography;

const Subscription = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const purchase = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    keyIndex: any
  ) => {
    sessionStorage.setItem("subscription",keyIndex.plan );
    dispatch(updateSubcription(keyIndex.plan));
    history.push("/signup");
  };
  return (
    <>
    <div>
      <Row id="header_div">
        <Col style={{ padding: "10px" }} span={5}>
          <img alt="header-logo" id="header_logo" src={logo} />
        </Col>
      </Row>
      <hr style={{ marginTop: "20px" }} />
      {/* <div style={{fontSize:"18px", color: "#63666A", marginLeft:"20px"}}><a href="/login" ><img src={backArrow} /></a><strong >Subscription</strong></div> */}
      <div style={{ fontSize: "18px", color: "#63666A", marginLeft: "20px" }}>
        <img alt="backArrow" src={backArrow} onClick={() => history.push("/login")} style={{cursor: "pointer",paddingRight:"15px" }}/>
        <strong >Subscription</strong>
      </div>

        <br />
        <div>
          <div style={{ margin: "10px" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {SUBSCRIPTION_CONFIG.plans.map((keyItem, keyIndex) => (
                <Col span={8} className="subscriptionRows" key={keyIndex}>
                  <Card
                    className="subscription-card"
                    key={keyIndex}
                    title={
                      <div>
                        <Title
                          level={1}
                          style={{
                            fontSize: "20px",
                            color: "white",
                            margin: "10px",
                          }}
                        >
                          {`${keyItem.plan} ${keyItem.planPrice}`}
                          { keyItem.strikeText !== "" &&
                            <>(<s>{keyItem.strikeText}</s>)</>
                          }
                        </Title>
                        <Title
                          level={3}
                          style={{
                            fontSize: "13px",
                            color: "white",
                            margin: "10px",
                          }}
                        >{`${keyItem.subplan}`}</Title>
                      </div>
                    }
                  >
                    <List
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      dataSource={keyItem.details}
                      renderItem={(item: any, index: any) => (
                        <List.Item>
                          {item && (
                            <div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details1}</span>
                              </div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details2}</span>
                              </div>
                              <div className="list">
                                <img alt="right" src={Right}></img>
                                <span>{item.details3}</span>
                              </div>
                              {item.details4 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details4}</span>
                                </div>
                              )}
                              {item.details5 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details5}</span>
                                </div>
                              )}
                              {item.details6 && (
                                <div className="list">
                                  <img alt="right" src={Right}></img>
                                  <span>{item.details6}</span>
                                </div>
                              )}
                              <hr
                                style={{
                                  marginTop:"25px"}} />
                              <div
                                style={{
                                  fontSize: "20px",
                                  color: "#3468B8",
                                  textAlign: "center",
                                }}
                              >
                                <strong>{keyItem.plan}</strong>
                              </div>
                              <hr />

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "25px 0px",
                                }}
                              >
                                <Button
                                  key={keyIndex}
                                  disabled={keyItem.disabled}
                                  style={{
                                    backgroundColor: keyItem.disabled
                                      ? "gray"
                                      : "#19335C",
                                    borderColor: "#19335C",
                                    cursor: keyItem.disabled
                                      ? "none"
                                      : "pointer",
                                  }}
                                  onClick={(e) => {
                                    purchase(e, keyItem);
                                  }}
                                >
                                  <span style={{color: "white"}}>Buy Now</span>
                                </Button>
                              </div>
                            </div>
                          )}
                        </List.Item>
                      )}
                    ></List>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default Subscription;
