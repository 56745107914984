import commonReducer, { IAppState } from './commonReducer';
import { combineReducers } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history';

const createRootReducer = (history: History<unknown>) => combineReducers<IApplicationState>({
  router: connectRouter(history),
  app: commonReducer
})

export interface IApplicationState {
  app: IAppState;
  router: RouterState;
}

export default createRootReducer