/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useState , useEffect} from "react";
// @ts-ignore
import logo from "../../assets/logo/logo-beta.png";
import "./style.css";
import {  useSelector,useDispatch } from "react-redux";
// import { capitalizeFirstLetter } from "../../utils/commonUtils";
import { IApplicationState } from "../../duck/reducers";
import Account from "../../assets/images/User.svg";
import PremiumLogo from "../../assets/logo/brandplus.png";
import PremiumUser from "../../assets/logo/avatar_logo.png";
import Infobox from "../../common/InfoBox";
import { Modal } from "antd";
import { getAllUSERTransactions } from "../../duck/actions/commonActions";
import { loadState } from "../../duck/reducers/commonReducer";

const Header = (props: any): JSX.Element => {
  const { username ,subscriptionType } = useSelector(
    (state) => (state as IApplicationState).app.userData
  );
  const dispatch = useDispatch();

  const [divDisplay, setDivDisplay] = useState(false);
  const [sessionModalShow , setSessionModalShow ] = useState(false);


  const { userTransactionsLoadState, userTransactions } =
      useSelector((state) => (state as IApplicationState).app);

  React.useEffect(() => {
    initialCall()
  }, [username]);

  const initialCall= async() =>{
    if(username){
        await dispatch(getAllUSERTransactions({username:username}));
    }
  }


  const handleOutsideClick = () => {
    setDivDisplay(false);
  };

  useEffect(() => {
  document.addEventListener("click", (evt) => {
    if(localStorage.getItem("autologout") === "true"){
      setSessionModalShow(true)
   }
  });
 },[])

  /*
  useEffect(()=>{
    if(profileFetchLoadState == loadState.SUCCESS){
      if(is_account_active != true){
        history.push("/login");
        window.location.reload();
      }
    }
  },[profileFetchLoadState]);*/

  return (
    <header className="header">
      <div className="header-container">
      <Modal
        title="Session Expired"
        visible={sessionModalShow}
        footer={null}
        closable={false}
        width="60%"
        centered
        bodyStyle={{ height: "auto" }}
      >
      Your session has expired. Please reload the page and login again.
     
      </Modal>
        <div className="logo">
          <img alt="header-logo" style={{height:'80px'}} src={subscriptionType === 2 ? PremiumLogo : logo} />
        </div>
        <div className="user-info">
          {!props.loader && (
            <>
              <div className="user-icon">
                {subscriptionType === 2 ? (
                <img
                alt="logout"
                src={PremiumUser }
                style={{
                    height: '50px',
                    width: '118px'
                }}
                className="logout"
                onClick={() => setDivDisplay(!divDisplay)}
              ></img>
                ):(
                  <img
                  alt="logout"
                  src={Account}
                  className="logout"
                  onClick={() => setDivDisplay(!divDisplay)}
                ></img>
                )}
              </div>
              <div className="welcome-user-section">
                Welcome{" "}
                <span className="username">
                  {username}
                  {/* {capitalizeFirstLetter(username)} */}
                </span>
              </div>
              {divDisplay && (
                <Infobox
                  show={divDisplay}
                  showBillingDetails={userTransactionsLoadState === loadState.SUCCESS && userTransactions.length > 0}
                  onClickOutside={() => {
                    handleOutsideClick();
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
