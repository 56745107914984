import React, { useState } from "react";
import { Button, Modal } from "antd";
import UploadCDTOutPutFile from "../cdtFiles/uploadcdtoutput";
import "./s_style.css"

const CDTOutputUpload = (props: any) => {
    const { row } = props;
    const [isUploadOutputVisible, setIsUploadOutputModalVisible] = useState<any>(false)
    const [uploadPath, setUploadPath] = useState<any>({});
    const [uploading,setUploading] = useState("");


    const handleUploadClockScores = (row: any) => {
        console.log(row, "ppppppppppp")
        setUploadPath(row)
        setIsUploadOutputModalVisible(true);
        setUploading("clock-score-output")
    
    }

    const handleUploadNumberingScores = (row: any) => {
         setUploadPath(row)
        setIsUploadOutputModalVisible(!isUploadOutputVisible);
        setUploading("Numbering-score-output")
    }


    const handleCancel = () => {
  
        setIsUploadOutputModalVisible(false);
        const inputDetails = document.getElementById(
            "fileDetails"
        ) as HTMLInputElement | null;
        if (inputDetails != null) {
            inputDetails.value = "";
        }
    };

    const handleOk = () => {
        setIsUploadOutputModalVisible(false);
    };

    const fileUploadSubmitHandler = () => {
        setIsUploadOutputModalVisible(false);
    }


    return (
        <div style={{ display:"flex"}}>

            <Modal
                title="Upload CDT OUTPUT File"
                visible={isUploadOutputVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <UploadCDTOutPutFile onSubmit={fileUploadSubmitHandler} item={uploadPath} uploading={uploading} />
            </Modal>

            <Button className="active-button"
                onClick={() => handleUploadClockScores(row)}
                disabled={!row.is_cdt_openmax}>Upload Clocks Scores
            </Button>
            <Button className="active-button"
                disabled={!row.is_cdt_openmax}
                onClick={() => handleUploadNumberingScores(row)}>Upload Numbering Scores
            </Button>

        </div>
    );
};

export default CDTOutputUpload;
