/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Pagination, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import "./style.css";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import parallel from "../../assets/logo/parallel.jpeg";
import zipIcon from "../../assets/images/zip-icon-dark.svg";
import { checkPreProcessBlobSZip } from "../../api/login";

const PreProcessResults = (props: any) => {
  const { item } = props;
  const [enlargeImage, setEnlargeImage] = useState<string>();
  const blobpageSize = 10;
  const [currentblob, setCurrentblob] = useState<any>(1);
  const [minIndexblob, setMinIndexblob] = useState<any>(0);
  const [maxIndexblob, setMaxIndexblob] = useState<any>(0);
  const [zipPath, setZipPath] = useState<string>();
  const [zipData, setZipData] = useState<any>([]);
 
  const { preProcessBlobs, preprocessBlobLoadState } = useSelector(
    (state) => (state as IApplicationState).app
  );

  useEffect(() => {
    if (preprocessBlobLoadState === loadState.SUCCESS) getBlobsData();
  }, [preprocessBlobLoadState]);

  const getBlobsData =  async  () => {

    const  response = await checkPreProcessBlobSZip({
        fileLocation: item.fileLocation,
        containerName: item.userName,
      });

    let zipDataDetails = response.fileArray;
    
    setMinIndexblob(0);
    setMaxIndexblob(blobpageSize);
    setEnlargeImage(" ");
    setCurrentblob(1);
    setZipData(zipDataDetails);
    
    if(preProcessBlobs.length !== 0){
      let enlargedImage = preProcessBlobs[0].includes("nii")
      ? nii
      : preProcessBlobs[0].includes("tif")
      ? tif
      : preProcessBlobs[0].includes("pdf")
      ? parallel
      : preProcessBlobs && preProcessBlobs.length > 0
      ? "https://mvpimagesa.blob.core.windows.net/" +
        item.userName +
        "/" +
        preProcessBlobs[0]
      : parallel;
    setEnlargeImage(enlargedImage);
    setZipPath(
      "https://mvpimagesa.blob.core.windows.net/" +
        item.userName +
        "/" +
        item.fileLocation +
        "_preprocess/zip/" +
        item.fileLocation +
        "_preprocess.zip"
    );
    }
   
  };

  const onImageClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: any
  ) => {
    setEnlargeImage(" ");
    let imagePath: string = event.currentTarget.getAttribute("src") || "";
    setEnlargeImage(imagePath);
  };

  const handlePageChangeBlob = (page: any) => {
    setCurrentblob(page + 0);
    setMinIndexblob((page - 1) * blobpageSize);
    setMaxIndexblob(page * blobpageSize);
  };

  return (
    <>
      {preprocessBlobLoadState === loadState.STARTED && (
        <div className="main_container spinner-container">
          <Spin size={"large"} />
           <p>Loading...</p>
        </div>
      )}
      {preprocessBlobLoadState === loadState.SUCCESS && (
        <div className="main_container preview-pre-process-container">
          <div className="msg-zip-container">
            <div className="result-page-message">
            In this preview window, images may look skewed and not look exactly as they are.
            </div>
           {zipData.length === 0 && (<div className="zip-message">
             Please check after some time to download zip file
            </div>)}
            {enlargeImage !== " " && (
                <div className="zipdownload-container">
                  <a
                    title="Download Zip"
                    href={zipPath}
                    download={
                      item.fileLocation +
                      "_preprocess/zip/" +
                      item.fileLocation +
                      "_preprocess.zip"
                    }
                    style={{
                      display:
                        zipData && zipData.length > 0 ? "block" : "none",
                    }}
                    target="_self"
                  >
                       <img
                        alt="downloadIcon"
                        style={{ width: "30px", height: "28px" }}
                        src={zipIcon}
                      ></img>
                  </a>
                </div>
            )}
          </div>
          <Row id="main_page_div">
            <Col
              style={{ border: "1px solid #63666A" }}
              id="first_main_Card"
              span={8}
            >
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#63666A",
                  }}
                  className="textStyle textDarkGary"
                >
                  Pre-processed Images
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {preProcessBlobs.map(
                    (blob: any, index: any) =>
                      index >= minIndexblob &&
                      index < maxIndexblob && (
                        <Row
                          style={{ marginBottom: "5px", cursor: "pointer" }}
                          key={index}
                        >
                          <Col>
                            <img
                              alt="nii"
                              src={
                                blob.includes("nii") || blob.includes("tif")
                                  ? blob.includes("nii")
                                    ? nii
                                    : tif
                                  : "https://mvpimagesa.blob.core.windows.net/" +
                                    item.userName +
                                    "/" +
                                    blob
                              }
                              id="imageStyle"
                              onClick={(e) => {
                                onImageClick(e, index);
                              }}
                            />
                          </Col>
                        </Row>
                      )
                  )}
                </div>
              </div>
              <div>
                <Pagination
                  pageSize={blobpageSize}
                  showSizeChanger={false}
                  current={currentblob}
                  total={preProcessBlobs.length}
                  onChange={handlePageChangeBlob}
                  style={{ bottom: "0px" }}
                />
              </div>
            </Col>

            {/*2nd  card column */}
            <Col id="secondCardPreprocess" span={15}>
              <div style={{ padding: "11px", textAlign: "center" }}>
                {enlargeImage && enlargeImage !== " " && (
                  <img
                    alt="enlargeImage"
                    src={enlargeImage}
                    id="enlargeImage"
                  />
                )}
                {!enlargeImage && <Spin size={"large"} />}
                {enlargeImage === " " && (
                  <span>
                    No Pre-Processed Images found. Please Pre-Process again.
                  </span>
                )}
                &emsp;
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PreProcessResults;
