const SHOW_LOADING = "SHOW_LOADING";
const HIDE_LOADING = "HIDE_LOADING";
const SHOW_ROLES = "SHOW_ROLES";
const LOGIN_STARTED = "LOGIN_STARTED";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGIN_NOTSTARTED = "LOGIN_NOTSTARTED";
const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
const ERROR = "ERROR";
const VIEW_FILE_DETAILS = "VIEW_FILE_DETAILS";
const GET_ALL_FILES_DETAILS = "GET_ALL_FILES_DETAILS";
const SIGNUP_STARTED = "SIGNUP_STARTED";
const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
const SIGNUP_FAILED = "SIGNUP_FAILED";
const SIGNUP_NOTSTARTED = "SIGNUP_NOTSTARTED";
const GET_TOTAL_RECORDS_COUNT = "GET_TOTAL_RECORDS_COUNT";
const USER_PROFILE_FETCH_SUCCESS = "USER_PROFILE_FETCH_SUCCESS";
const USER_PROFILE_FETCH_FAILED = "USER_PROFILE_FETCH_FAILED";
const UPLOAD_STARTED = "UPLOAD_STARTED";
const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
const UPLOAD_FAILED = "UPLOAD_FAILED";
const UPLOAD_NOTSTARTED = "UPLOAD_NOTSTARTED";
const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
const CHANGE_PASSWORD_NOTSTARTED = "CHANGE_PASSWORD_NOTSTARTED";
const DELETE_DASHBOARD_FAILED = "DELETE_DASHBOARD_FAILED";
const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";
const DELETE_DASHBOARD_STARTED = "DELETE_DASHBOARD_STARTED";
const DELETE_DASHBOARD_NOTSTARTED = "DELETE_DASHBOARD_NOTSTARTED";
const GET_USERS_STARTED = "GET_USERS_STARTED";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_USERS_FAILED = "GET_USERS_FAILED";
const GET_USERS_NOTSTARTED = "GET_USERS_NOTSTARTED";
const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
const UPDATE_USER_NOTSTARTED = "UPDATE_USER_NOTSTARTED";
const UPDATE_USER_ACTIVE_STATUS_STARTED = "UPDATE_USER_ACTIVE_STATUS_STARTED";
const UPDATE_USER_ACTIVE_STATUS_SUCCESS = "UPDATE_USER_ACTIVE_STATUS_SUCCESS";
const UPDATE_USER_ACTIVE_STATUS_FAILED = "UPDATE_USER_ACTIVE_STATUS_FAILED";
const UPDATE_USER_ACTIVE_STATUS_NOTSTARTED = "UPDATE_USER_ACTIVE_STATUS_NOTSTARTED";
const UPDATE_REDUX_ACTIVE_STATE = "UPDATE_REDUX_ACTIVE_STATE";
const IFRAME_LOAD_STARTED = "IFRAME_LOAD_STARTED";
const IFRAME_LOAD_SUCCESS = "IFRAME_LOAD_SUCCESS";
const IFRAME_LOAD_NOTSTARTED = "IFRAME_LOAD_NOTSTARTED";
const BLOBS_LOAD_STARTED = "BLOBS_LOAD_STARTED";
const BLOBS_LOAD_SUCCESS = "BLOBS_LOAD_SUCCESS";
const BLOBS_LOAD_FAILED = "BLOBS_LOAD_FAILED";
const CREATE_ZIP_STARTED = "CREATE_ZIP_STARTED";
const CREATE_ZIP_SUCCESS = "CREATE_ZIP_SUCCESS";
const CREATE_ZIP_FAILED = "CREATE_ZIP_FAILED";
const UPDATE_USER_ASADMIN_STARTED = "UPDATE_USER_ASADMIN_STARTED";
const UPDATE_USER_ASADMIN_SUCCESS = "UPDATE_USER_ASADMIN_SUCCESS";
const UPDATE_USER_ASADMIN_FAILED = "UPDATE_USER_ASADMIN_FAILED";
const UPDATE_USER_ASADMIN_NOTSTARTED = "UPDATE_USER_ASADMIN_NOTSTARTED";
const REMOVE_USER_ASADMIN_STARTED = "REMOVE_USER_ASADMIN_STARTED";
const REMOVE_USER_ASADMIN_SUCCESS = "REMOVE_USER_ASADMIN_SUCCESS";
const REMOVE_USER_ASADMIN_FAILED = "REMOVE_USER_ASADMIN_FAILED";
const REMOVE_USER_ASADMIN_NOTSTARTED = "REMOVE_USER_ASADMIN_NOTSTARTED";
const UPDATE_REDUX_USER_SUBSCRIPTION = "UPDATE_REDUX_USER_SUBSCRIPTION";
const DELETE_CDT_DASHBOARD_FAILED = "DELETE_CDT_DASHBOARD_FAILED";
const DELETE_CDT_DASHBOARD_SUCCESS = "DELETE_CDT_DASHBOARD_SUCCESS";
const DELETE_CDT_DASHBOARD_STARTED = "DELETE_CDT_DASHBOARD_STARTED";
const DELETE_CDT_DASHBOARD_NOTSTARTED = "DELETE_CDT_DASHBOARD_NOTSTARTED";
const POST_PROCESS_BLOBS_LOAD_STARTED = "POST_PROCESS_BLOBS_LOAD_STARTED";
const POST_PROCESS_BLOBS_LOAD_SUCCESS = "POST_PROCESS_BLOBS_LOAD_SUCCESS";
const POST_PROCESS_BLOBS_LOAD_FAILED = "POST_PROCESS_BLOBS_LOAD_FAILED";
const CDT_ITEM_BLOBS_LOAD_STARTED = "CDT_ITEM_BLOBS_LOAD_STARTED";
const CDT_ITEM_BLOBS_LOAD_SUCCESS = "CDT_ITEM_BLOBS_LOAD_SUCCESS";
const CDT_ITEM_BLOBS_LOAD_FAILED = "CDT_ITEM_BLOBS_LOAD_FAILED";
const UPDATE_USER_CDT_ACCOUNT_STARTED = "UPDATE_USER_CDT_ACCOUNT_STARTED";
const UPDATE_USER_CDT_ACCOUNT_SUCCESS = "UPDATE_USER_CDT_ACCOUNT_SUCCESS";
const UPDATE_USER_CDT_ACCOUNT_FAILED = "UPDATE_USER_CDT_ACCOUNT_FAILED";
const UPDATE_USER_CDT_ACCOUNT_NOTSTARTED = "UPDATE_USER_CDT_ACCOUNT_NOTSTARTED";
const UPDATE_REDUX_CDT_ACCOUNT_STATE = "UPDATE_REDUX_CDT_ACCOUNT_STATE";
const CDT_USER_FILES_BLOBS_LOAD_STARTED = "CDT_USER_FILES_BLOBS_LOAD_STARTED";
const CDT_USER_FILES_BLOBS_LOAD_SUCCESS = "CDT_USER_FILES_BLOBS_LOAD_SUCCESS";
const CDT_USER_FILES_BLOBS_LOAD_FAILED = "CDT_USER_FILES_BLOBS_LOAD_FAILED";
const CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED = "CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED";
const CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED = "CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED";
const CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS = "CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS";
const CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED = "CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED";
const GET_CDT_TRANSACTIONS_STARTED = "GET_CDT_TRANSACTIONS_STARTED";
const GET_CDT_TRANSACTIONS_SUCCESS = "GET_CDT_TRANSACTIONS_SUCCESS";
const GET_CDT_TRANSACTIONS_FAILED = "GET_CDT_TRANSACTIONS_FAILED";
const GET_CDT_TRANSACTIONS_NOTSTARTED = "GET_CDT_TRANSACTIONS_NOTSTARTED";

const GET_WEB_TRANSACTIONS_STARTED = "GET_WEB_TRANSACTIONS_STARTED";
const GET_WEB_TRANSACTIONS_SUCCESS = "GET_WEB_TRANSACTIONS_SUCCESS";
const GET_WEB_TRANSACTIONS_FAILED = "GET_WEB_TRANSACTIONS_FAILED";
const GET_WEB_TRANSACTIONS_NOTSTARTED = "GET_WEB_TRANSACTIONS_NOTSTARTED";

const GET_USER_TRANSACTIONS_STARTED = "GET_USER_TRANSACTIONS_STARTED";
const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
const GET_USER_TRANSACTIONS_FAILED = "GET_USER_TRANSACTIONS_FAILED";
const GET_USER_TRANSACTIONS_NOTSTARTED = "GET_USER_TRANSACTIONS_NOTSTARTED";

const PRE_PROCESS_API_CALL_STARTED = "PRE_PROCESS_API_CALL_STARTED";
const PRE_PROCESS_API_CALL_SUCCESS = "PRE_PROCESS_API_CALL_SUCCESS";
const PRE_PROCESS_API_CALL_FAILED = "PRE_PROCESS_API_CALL_FAILED";
const PRE_PROCESS_API_CALL_NOTSTARTED = "PRE_PROCESS_API_CALL_NOTSTARTED";

export {
  SHOW_ROLES,
  SHOW_LOADING,
  HIDE_LOADING,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_NOTSTARTED,
  ERROR,
  UPDATE_SUBSCRIPTION,
  VIEW_FILE_DETAILS,
  GET_ALL_FILES_DETAILS,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  GET_TOTAL_RECORDS_COUNT,
  USER_PROFILE_FETCH_SUCCESS,
  USER_PROFILE_FETCH_FAILED,
  SIGNUP_NOTSTARTED,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  UPLOAD_NOTSTARTED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_NOTSTARTED,
  DELETE_DASHBOARD_NOTSTARTED,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_STARTED,
  DELETE_DASHBOARD_FAILED,
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_NOTSTARTED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_NOTSTARTED,
  UPDATE_USER_ACTIVE_STATUS_STARTED,
  UPDATE_USER_ACTIVE_STATUS_SUCCESS,
  UPDATE_USER_ACTIVE_STATUS_FAILED,
  UPDATE_USER_ACTIVE_STATUS_NOTSTARTED,
  UPDATE_REDUX_ACTIVE_STATE,
  UPDATE_REDUX_USER_SUBSCRIPTION,
  IFRAME_LOAD_STARTED,
  IFRAME_LOAD_SUCCESS,
  IFRAME_LOAD_NOTSTARTED,
  BLOBS_LOAD_STARTED,
  BLOBS_LOAD_SUCCESS,
  BLOBS_LOAD_FAILED,
  CREATE_ZIP_STARTED,
  CREATE_ZIP_SUCCESS,
  CREATE_ZIP_FAILED,
  UPDATE_USER_ASADMIN_STARTED,
  UPDATE_USER_ASADMIN_SUCCESS,
  UPDATE_USER_ASADMIN_FAILED,
  UPDATE_USER_ASADMIN_NOTSTARTED,
  REMOVE_USER_ASADMIN_STARTED,
  REMOVE_USER_ASADMIN_SUCCESS,
  REMOVE_USER_ASADMIN_FAILED,
  REMOVE_USER_ASADMIN_NOTSTARTED,
  DELETE_CDT_DASHBOARD_NOTSTARTED,
  DELETE_CDT_DASHBOARD_SUCCESS,
  DELETE_CDT_DASHBOARD_STARTED,
  DELETE_CDT_DASHBOARD_FAILED,
  POST_PROCESS_BLOBS_LOAD_STARTED,
  POST_PROCESS_BLOBS_LOAD_SUCCESS,
  POST_PROCESS_BLOBS_LOAD_FAILED,
  CDT_ITEM_BLOBS_LOAD_STARTED,
  CDT_ITEM_BLOBS_LOAD_SUCCESS,
  CDT_ITEM_BLOBS_LOAD_FAILED,
  UPDATE_USER_CDT_ACCOUNT_STARTED,
  UPDATE_USER_CDT_ACCOUNT_SUCCESS,
  UPDATE_USER_CDT_ACCOUNT_FAILED,
  UPDATE_USER_CDT_ACCOUNT_NOTSTARTED,
  UPDATE_REDUX_CDT_ACCOUNT_STATE,
  CDT_USER_FILES_BLOBS_LOAD_STARTED,
  CDT_USER_FILES_BLOBS_LOAD_SUCCESS,
  CDT_USER_FILES_BLOBS_LOAD_FAILED,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED,
  CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED,
  GET_CDT_TRANSACTIONS_STARTED,
  GET_CDT_TRANSACTIONS_SUCCESS,
  GET_CDT_TRANSACTIONS_FAILED,
  GET_CDT_TRANSACTIONS_NOTSTARTED,
  GET_WEB_TRANSACTIONS_STARTED,
  GET_WEB_TRANSACTIONS_SUCCESS,
  GET_WEB_TRANSACTIONS_FAILED,
  GET_WEB_TRANSACTIONS_NOTSTARTED,
  GET_USER_TRANSACTIONS_STARTED,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILED,
  GET_USER_TRANSACTIONS_NOTSTARTED,
  PRE_PROCESS_API_CALL_STARTED,
  PRE_PROCESS_API_CALL_SUCCESS,
  PRE_PROCESS_API_CALL_FAILED,
  PRE_PROCESS_API_CALL_NOTSTARTED
};
