/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.css";
import { IApplicationState } from "../../duck/reducers";
import openNotification from "../../common/Notifications";
import { changePassword } from "../../duck/actions/commonActions";
import { loadState } from "../../duck/reducers/commonReducer";
import { CHANGE_PASSWORD_NOTSTARTED } from "../../duck/types/types";
import Header from "../header";
import Footer from "../footer/footer";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(
    "Enter a valid password"
  );
  const [loader] = useState<any>(true);

  const { changePasswordLoadState, error } = useSelector(
    (state) => (state as IApplicationState).app
  );
  const { username } = useSelector(
    (state) => (state as IApplicationState).app.userData
  );
  const history = useHistory();
  const dispatch = useDispatch();

  
  useEffect(() => {
    const isCopyPasted = sessionStorage.getItem('user_details') !== null ? false : true;
    if (isCopyPasted) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (changePasswordLoadState === loadState.SUCCESS) {
      let successObj = {
        type: "success",
        message: "Password is updated successfully",
      };
      openNotification(successObj);
      dispatch({ type: CHANGE_PASSWORD_NOTSTARTED });
    } else if (changePasswordLoadState === loadState.FAILED) {
      let errorObj = {
        type: "error",
        message: error,
      };
      openNotification(errorObj);
      dispatch({ type: CHANGE_PASSWORD_NOTSTARTED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordLoadState]);

  const changePasswordSubmit = (values: any) => {
    if (passwordMessage === "") {
      if (password === values["confirm password"]) {
        let serviceRequest = {
          password: password,
          username: username,
        };

        dispatch(changePassword(serviceRequest));
      } else {
        let errorObj = {
          type: "error",
          message: "Error",
          description:
            "Passwords do not match.Make sure your password is not empty.",
        };
        openNotification(errorObj);
      }
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: any) => {
    if (id === "password") {
      let regexPassword = new RegExp(
        "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$#!%*^?&-])[A-Za-z0-9@$#^!%*?&-]{6,}$"
      );
      if (e.target.value !== "") {
        if (regexPassword.test(e.target.value) === false) {
          setPassword(e.target.value);
          setPasswordMessage(
            "Password must contain at least: one lowercase letter, one uppercase letter, one number, and one special character not including parenthesis (!@#$%^&*). The minimum length is six characters."
          );
        } else {
          setPassword(e.target.value);
          setPasswordMessage("");
        }
      } else {
        setPassword(e.target.value);
        setPasswordMessage("Password cannot be empty");
      }
    }
  };
  return (
    <>
      <Header prop={loader} />
      <div className="dashboard-container">
        <div className="breadcrumb-container">
          <div className="breadcrumb">
            <a
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </a>
            &nbsp;/ Change Password
          </div>
        </div>
        <div className="change-password-container">
          <Form name="basic" onFinish={changePasswordSubmit}>
            <label>Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  // min:6,
                  required: true,
                  message: passwordMessage,
                  //If you need a specific pattern type in Password used the below Regular Expression
                  pattern: new RegExp(
                    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$#!%*^?&-])[A-Za-z0-9@$#^!%*?&-]{6,}$"
                  ),
                },
              ]}
            >
              <Input.Password
                id="password"
                placeholder="Enter Your Password Here..."
                value={password}
                onChange={(e) => {
                  onInputChange(e, "password");
                }}
              />
            </Form.Item>
            <label>Confirm Password</label>
            <Form.Item
              name="confirm password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Confirm Password Should Match Password!",
                },
                () => ({
                  validator(_, value) {
                    if (password === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                id="confirm password"
                placeholder="Confirm Password"
              />
            </Form.Item>

            <Form.Item className="sign-btn">
              <Button
                type="primary"
                htmlType="submit"
                id="loginBtn"
                name="password-change"
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
