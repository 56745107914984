/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lone-blocks */
import { Card, Progress } from "antd";
import React, { useState } from "react";
import "./storage.css";
import { ProgressBar } from "./progressBar";
import storageLogo from "../../assets/images/InputStorage.svg";
import analysisLogo from "../../assets/images/ImageAnalysisLimit.svg";
import { IApplicationState } from "../../duck/reducers";
import { SUBSCRIPTION_CONFIG } from "../../constants/commonConstants";
import { useSelector } from "react-redux";

const Storage = () => {
  const {
    used_input_limit,
    used_output_limit,
    used_analysis_limit,
    subscriptionType,
    createdAt,
    previousMonthDate,
  } = useSelector((state) => (state as IApplicationState).app.userData);

  const subscriptionDetailsConfig = SUBSCRIPTION_CONFIG;
  const userSubscription = subscriptionType;
  const userLimitDetails = subscriptionDetailsConfig.plans.find(
    ({ subscriptionType }) => subscriptionType === userSubscription
  );
  const totalInputStorage = userLimitDetails?.totalInputLimit.split(" ")[1] === "GB" ? parseInt(userLimitDetails.totalInputLimit.split(" ")[0]) * 1000 : userLimitDetails ? parseInt(userLimitDetails.totalInputLimit.split(" ")[0]) : 0;
  const totalOutputStorage = userLimitDetails?.totalOutputLimit.split(" ")[1] === "GB" ? parseInt(userLimitDetails.totalOutputLimit.split(" ")[0]) * 1000 : userLimitDetails ? parseInt(userLimitDetails.totalOutputLimit.split(" ")[0]) : 0;
  const totalAnalysisLimit = userLimitDetails?.totalAnalysisLimit === "Unlimited" ? "Unlimited" : userLimitDetails ? parseInt(userLimitDetails.totalAnalysisLimit) : 0;

  const [showProTip,setShowProTip] = useState(false);

  
  const showProTipHandler = () => {
    setShowProTip(true);
  }

   const hideProTipHandler = () => {
    setShowProTip(false);
  }

  const proTipPoints = <span>
<ul>
<li>Do not upload masks that have less than 10-pixels of white space on all four sides. [We have a pre-processing tool that adds 200-pixels of margin space to each image. See Toolbox 2.]</li>

<li> Do not upload blank images or images with only a speck of debris. And, check for blank images and images that are just specks of debris AFTER using our pre-processing tools, but BEFORE requesting Parallel Grid or Radial Grid Processing.</li>

<li> Do not request Parallel Grid or Radial Grid processing on mask images that are just a small speck*.</li>

<li> For image files, do not upload files other than .jpeg, .png, and .tif.</li>
<li>
Lines that are not at least 5-pixels thick will not be detected properly. In the output files, check the images that have the world "STENCIL" at the end of their file names to see if your lines were detected properly throughout your entire shape.
</li>
</ul>
<p>*Mask images that are just small specks can result when you use the pre-processing tool called "Save Each Object as Its Own Mask" in Toolbox 3. 
An image containing multiple outlines can have specks (unwanted debris) that the user did not see. 
When this tool isolates each object, even the specks are placed onto their own white canvases.
These speck images will cause a processing failure for the whole batch if the user requests Parallel Grid or Radial Grid processing, 
so remove these images and re-upload the good images for Parallel Grid or Radial Grid processing.<a onClick={hideProTipHandler}> View less</a></p>
    
  </span> 

  let usedInputLimitWithSplit = used_input_limit
    ? parseFloat(used_input_limit.split(" ")[0])
    : 0;
  let usedOutputLimitWithSplit = used_output_limit
    ? parseFloat(used_output_limit.split(" ")[0])
    : 0;
  let usedAnalysisLimitWithSplit = used_analysis_limit
    ? parseFloat(used_analysis_limit.split(" ")[0])
    : 0;
  usedAnalysisLimitWithSplit =
    usedAnalysisLimitWithSplit > 0 ? usedAnalysisLimitWithSplit : 0;
  usedOutputLimitWithSplit =
    usedOutputLimitWithSplit > 0 ? usedOutputLimitWithSplit : 0;
  usedInputLimitWithSplit =
    usedInputLimitWithSplit > 0 ? usedInputLimitWithSplit : 0;

  const availableInputStorage = () => {
    return totalInputStorage - usedInputLimitWithSplit;
  };
  const availableOutputStorage = () => {
    return totalOutputStorage - usedOutputLimitWithSplit;
  };

  let resetDay;
  if (previousMonthDate)
    resetDay = new Date(
      new Date(previousMonthDate).setDate(
        new Date(previousMonthDate).getDate() + 30
      )
    ).toLocaleDateString("en-US");
  else
    resetDay = new Date(
      new Date(createdAt).setDate(new Date(createdAt).getDate() + 30)
    ).toLocaleDateString("en-US");

  const inpuStorage = [
    {
      containerbgcolor: "#50a923",
      total: totalInputStorage,
      available: availableInputStorage().toFixed(2),
    },
  ];
  const outputStorage = [
    {
      containerbgcolor: "#50a923",
      total: totalOutputStorage,
      available: availableOutputStorage().toFixed(2),
    },
  ];
  const analysisLimit = [{ containerbgcolor: "#50a923" }];

  return (
    <div className="storage-container">
    <div className="row progress-bar-row">
      <div className="column">
        <Card className="progress-bar">
          <div className="header-div">
            <img src={storageLogo} alt="logo"></img>
            Total Input Storage
          </div>
          {inpuStorage.map((item, idx) => (
            <ProgressBar
              key={idx}
              containerbgcolor={item.containerbgcolor}
              usedLimit={usedInputLimitWithSplit.toFixed(2)}
              total={item.total}
              available={item.available}
            />
          ))}
          <div className="totalspace">
            <span>{totalInputStorage + "MB"}</span>
          </div>
          <div className="row-in-card">
            <div className="input-column">
              <div className="input">
                <div> Available Storage </div>
                <div className="color-text1">
                  {availableInputStorage() > 0 ? availableInputStorage().toFixed(2) + "MB" : 0 + "MB"}
                </div>
              </div>
              <hr></hr>
              <div className="input">
                <div> Used Storage </div>
                <div className="color-text2">
                  {usedInputLimitWithSplit.toFixed(2) + "MB"}
                </div>
              </div>
              <hr></hr>
              <div className="input">
                <div> Total </div>
                <div className="color-text3"> {totalInputStorage + "MB"}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="column">
        <Card className="progress-bar1">
          <div className="header-div">
            <img src={storageLogo} alt="logo "></img>
            Total Output Storage
          </div>
          {outputStorage.map((item, idx) => (
            <ProgressBar
              key={idx}
              containerbgcolor={item.containerbgcolor}
              usedLimit={usedOutputLimitWithSplit.toFixed(2)}
              total={item.total}
              available={item.available}
            />
          ))}
          <div className="totalspace">
            <span>{totalOutputStorage + "MB"}</span>
          </div>
          <div className="row-in-card">
            <div className="input-column">
              <div className="input">
                <div> Available Storage</div>
                <div className="color-text1">
                  {availableOutputStorage() > 0 ? availableOutputStorage().toFixed(2) + "MB" : 0 + "MB"}
                </div>
              </div>
              <hr></hr>
              <div className="input">
                <div> Used Storage </div>
                <div className="color-text2">
                  {usedOutputLimitWithSplit.toFixed(2) + "MB"}
                </div>
              </div>
              <hr></hr>
              <div className="input">
                <div> Total </div>
                <div className="color-text3"> {totalOutputStorage + "MB"}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="column">
        {" "}
        <Card className="progress-bar3">
          <div className="header-div3">
            <img src={analysisLogo} alt="logo "></img>
            Total Image Analysis Limit
          </div>
          <div className="column-in">
            {totalAnalysisLimit !== "Unlimited" ? <span className="column-in"> {analysisLimit.map((item, idx) => (
              <Progress
                type="circle"
                percent={
                  (usedAnalysisLimitWithSplit / totalAnalysisLimit) * 100
                }
                format={() =>
                  `${usedAnalysisLimitWithSplit}/${totalAnalysisLimit}`
                }
                strokeColor="#e74f2b"
                trailColor={item.containerbgcolor}
              />
            ))} </span>
              : <span className="column-in">
                {analysisLimit.map((item, idx) => (
                  <Progress
                    type="circle"
                    percent={100}
                    format={() =>
                      `${usedAnalysisLimitWithSplit}/${totalAnalysisLimit}`
                    }
                    strokeColor="#50a923"
                  />
                ))}
              </span>}

          </div>
          <div className="analysis-column">
            <div className="analysis">
              <div> Analysis Limit</div>
              <div className="color-text1">
                {totalAnalysisLimit + " Images per/month"}{" "}
              </div>
            </div>
            <hr></hr>
            <div className="analysis">
              <div> Used Limit </div>
              <div className="color-text2"> {usedAnalysisLimitWithSplit}</div>
            </div>
            <hr></hr>
            <div className="analysis">
              <div> Reset on </div>
              <div className="color-text3"> {resetDay}</div>
            </div>
          </div>
        </Card>
      </div>
    </div>
      <div className="pro-tip">
      <h3>Pro Tips: To save your time, please follow the best practices outlined below. { !showProTip  && <a onClick={showProTipHandler}>View more</a> }</h3>
     
       { showProTip && proTipPoints}
      </div>
      </div>
  );
};

export default Storage;
