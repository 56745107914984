/* eslint-disable no-throw-literal */
import { API_BRAINSCANOLOGY_URL, API_PRE_PROCESS_URL } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

//Api for fetching count of total files available in db
export const getTotalRecordsCount = async (_queryParam?: any) => {
  try{
    const response = await AjaxService.get(
      API_BRAINSCANOLOGY_URL + "/api/brainscanology/getTotalRecordsCount")
    return response?.data ?? 0
  }
  catch (error: any) {
    throw new Error("Error",error);
  }
}
export const deleteDashBoardRecord = (_queryParam?: any) => {
  return AjaxService.delete(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/"+_queryParam
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};

export const deleteCDTRecord = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/deleteCDTRecord",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};

export const createPreprocessFolder = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/createPreprocessFolder",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

export const createPostprocessFolder = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/createPostprocessFolder",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

// api for getting the specific file details
export const getSpecificFile = (_queryParam:any)=>{
  return AjaxService.get(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/"+_queryParam
  ).then((res: { data: any }) => res.data,
  (error: { response: { data: any } }) => {
    throw { name: "ServerError", message: error?.response?.data?.message }
  })
}

//api for getting preprocessblobs
export const fetchPreProcessBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/preprocessedBlobs",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

//API to create zip file
 export const createZipPreProcess = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/createZip",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

//api for getting postprocessblobs
export const fetchPostProcessBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/postprocessedBlobs",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

//API to create zip file of post-process
export const  createZipPostProcess = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/createZipPostProcess",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

//api for getting preprocessblobs
export const fetchSingleItemCDTBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/listofCDTInputBlobs",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 


//api for getting preprocessblobs
export const fetchCDTFilesOfAUserBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/userCDTFilesBlobsForAdmin",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
}; 

//api for getting output blobs of single cdt file to admin
export const fetchSingleItemCDTOutputBlobs = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/getUserOutputFilesofAOneCDTFile",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};

//api to delete output file of a single cdt file in admin
export const deleteCDTOUTPUTInAzure = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/api/brainscanology/deleteCDTOutputFileInAzure",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};

//python api call for preprocess
export const preprocessPythonApiCall = (_queryParam?: any) => {
  const endPoint = _queryParam.selectedOptionAPI
  return AjaxService.post(
    API_PRE_PROCESS_URL + `/${endPoint}`,
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: "Something Went Wrong." }
    }
  );
};

//api to delete output file of a single cdt file in admin
export const updateUserDetails = (_queryParam?: any) => {
  return AjaxService.post(
    API_BRAINSCANOLOGY_URL + "/update-names",
    JSON.stringify(_queryParam)
  ).then(
    (res: { data: any }) => res?.data,
    (error: { response: { data: any } }) => {
      throw { name: "ServerError", message: error?.response?.data?.message }
    }
  );
};