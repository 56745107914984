/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import { PRE_PROCESS_API_CALL_NOTSTARTED } from "../../duck/types/types";
import { Button, Row, Col, Divider, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import nii from "../../assets/images/NII-FE-Bscan.svg";
import tif from "../../assets/images/TIF-FE-Bscan.svg";
import parallel from "../../assets/logo/parallel.jpeg";
import { IApplicationState } from "../../duck/reducers";
import { preProcessPython } from "../../duck/actions/dashboardActions";
import { loadState } from "../../duck/reducers/commonReducer";
import openNotification from "../../common/Notifications";
import outline from "../../assets/images/outline extraction.svg"
import mask from "../../assets/images/mask manipulation.svg"
import rotate from "../../assets/images/rotate,resize, flip.svg"
import PreProcessImageConfig from "../../config/preProcessImageConfig";

const PreProcess = (propsFromDashboard: any) => {
    const { handlePreProcess, item } = propsFromDashboard

    const { id, username } =
        useSelector((state) => (state as IApplicationState).app.userData);

    const {
        preProcessApiLoadState,
        error,
    } = useSelector((state) => (state as IApplicationState).app);

    const dispatch = useDispatch();
    const [selectedOptionAPI, setSelectedOptionAPI] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedPreProcess, setSelectedPreProcess] = useState('');
    const [selectedWindowOption, setSelectedWindowOption] = useState('');
    const [enlargeImage, setEnlargeImage] = useState<string>();

    const [showOutlinesExtraction, setShowOutlinesExtraction] = useState(false);
    const [showMaskManipulation, setShowMaskManipulation] = useState(false);
    const [showRotateResizeFlip, setShowRotateResizeFlip] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string>('');

    const preProcessOptions = [
        //OutlinesExtraction 
        { label: 'Extract Red Color From Windows', value: 'redfromwin' },
        { label: 'Extract Red Color From Mac', value: 'redfromwin' },
        { label: 'Extract Blue Color', value: 'blue' },
        { label: 'Extract Green Color', value: 'extgreen' },
        { label: 'Extract Pink Color', value: 'extpink' },

        //MaskManipulation
        { label: 'Turn Black Lines into Blue Lines', value: 'blcktoblue' },
        { label: 'Save Each Object As its Own Mask', value: 'saveown' },
        { label: 'Extract Outlines From Filled-In Masks', value: 'extout' },
        { label: 'Crop Images To 50-pixel Margins', value: 'cropimg' },
        { label: 'Crop Images To 1-pixel Border ', value: 'cropimg1pix' },
        { label: 'Add 100-Pixel Margins', value: '200pix' },

        //Rotate,Resize,Flip
        { label: 'Resize Width to 400-pixels(Lock Aspect Ratio)', value: 'resimg' },
        { label: 'Rotate Images 90 Degree CW', value: 'rt90cw' },
        { label: 'Rotate Images 90 Degree CCW', value: 'rt90ccw' },
        { label: 'Rotate Images 180 Degree CW', value: 'rt180cw' },
        { label: 'Rotate Images 180 Degree CCW', value: 'rt180ccw' },
        { label: 'Flip Images Horizontally', value: 'fliphor' },
        { label: 'Flip Images Vertically', value: 'flipver' },
        { label: 'Batch Rotate Longest Length Horizontal', value: 'rthori' },
        { label: 'Rotate Green Line Horizontal', value: 'greenline' },
    ];

    const handleCancel = () => {
        handlePreProcess(item)
    };

    const handleGenerateImage = () => {
        if (selectedOptionAPI.length === 0) {
            let errorObj = {
                type: "error",
                message: 'Please select a option.',
            };
            openNotification(errorObj);
            return
        }

        let requestBody = {
            userId: id,
            username: username,
            location: `${item.fileLocation}`,
            selectedOptionAPI: selectedOptionAPI
        };
        dispatch(preProcessPython(requestBody))
    };

    const handleSlectedListOption = (selectedList: any) => {

        if (selectedList === "redfrommac") {
            setSelectedOptionAPI('redfrommac')
            setSelectedWindowOption('mac')
        } else if (selectedList === "redfromwin") {
            setSelectedWindowOption('win')
        } else {
            setSelectedWindowOption('')
        }
        const selectedOption = preProcessOptions.find(option => option.value === selectedList);

        // Check if the option is found
        if (selectedOption) {
            const { label, value } = selectedOption;
            setSelectedOptionAPI(value)
            setSelectedPreProcess(label)
        }

    };

    const handleOutlinesExtraction = () => {
        setShowOutlinesExtraction((prevState) => !prevState);
        setShowMaskManipulation(false);
        setShowRotateResizeFlip(false);
    };

    const handleMaskManipulation = () => {
        setShowMaskManipulation((prevState) => !prevState);
        setShowOutlinesExtraction(false);
        setShowRotateResizeFlip(false);
    };

    const handleRotateResizeFlip = () => {
        setShowRotateResizeFlip((prevState) => !prevState);
        setShowOutlinesExtraction(false);
        setShowMaskManipulation(false);
    };

    //setting image display
    useEffect(() => {
        setSelectedOptionAPI('')
        setSelectedPreProcess('')
        setShowOutlinesExtraction(false)
        setShowMaskManipulation(false)
        setShowRotateResizeFlip(false)
        let enlargedImage = item.imagePath
            ? item.imagePath.includes("nii") ||
                item.imagePath.includes("tif")
                ? item.imagePath.includes("nii")
                    ? nii
                    : tif
                : "https://mvpimagesa.blob.core.windows.net/" +
                item.userName +
                "/" +
                item.fileLocation +
                "_input" +
                "/" +
                item.imagePath
            : parallel
        setEnlargeImage(enlargedImage);
    }, [item])

    useEffect(() => {
        if (preProcessApiLoadState === loadState.SUCCESS) {
            let successObj = {
                type: "success",
                message: "File Pre-Processed successfully",
            };
            openNotification(successObj);
            dispatch({ type: PRE_PROCESS_API_CALL_NOTSTARTED });
        } else if (preProcessApiLoadState === loadState.FAILED) {
            let errorObj = {
                type: "error",
                message: error + ' Please try again.',
            };
            openNotification(errorObj);
            dispatch({ type: PRE_PROCESS_API_CALL_NOTSTARTED });
        }
    }, [preProcessApiLoadState]);


    useEffect(() => {
        setSelectedImage(selectedOptionAPI)
    }, [selectedOptionAPI]);

    return (
        <>
            <div className="iframe-container">
                <Row id="pre-process-main">
                    <Col style={{ border: "1px solid #63666A", backgroundColor: "#003366" }}
                        id="first_main_col"
                        span={7}>
                        <div className="pre-process-leftpart">
                            <div className="pre-process-options-bar">
                                <div>
                                    <div className="dropdown-pre-process">
                                        <img src={outline} alt="outline-icon"></img>
                                        <Button type="text" onClick={handleOutlinesExtraction}
                                            style={{ color: "white" }}
                                            className="options-text-btn">
                                            Toolbox 1: Outline Extraction {showOutlinesExtraction ? <CaretUpOutlined style={{ marginLeft: '50px' }} />
                                                : <CaretDownOutlined style={{ marginLeft: '50px' }} />}
                                        </Button>
                                        {showOutlinesExtraction && (
                                            <div className="dropdown-options">
                                                <ul >
                                                    <li
                                                        onClick={() => handleSlectedListOption('redfromwin')}
                                                        className={selectedWindowOption === 'win' ? 'selected' : ''}
                                                    >
                                                        Extract Red Color From Windows
                                                    </li>
                                                    <li
                                                        onClick={() => handleSlectedListOption('redfrommac')}
                                                        className={selectedWindowOption === 'mac' ? 'selected' : ''}
                                                    >
                                                        Extract Red Color From Mac
                                                    </li>
                                                    <li
                                                        onClick={() => handleSlectedListOption('blue')}
                                                        className={selectedOptionAPI === 'blue' ? 'selected' : ''}
                                                    >
                                                        Extract Blue Color
                                                    </li>
                                                    <li
                                                        onClick={() => handleSlectedListOption('extgreen')}
                                                        className={selectedOptionAPI === 'extgreen' ? 'selected' : ''}
                                                    >
                                                        Extract Green Color
                                                    </li>
                                                    <li
                                                        onClick={() => handleSlectedListOption('extpink')}
                                                        className={selectedOptionAPI === 'extpink' ? 'selected' : ''}
                                                    >
                                                        Extract Pink Color
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>

                                    <div className="dropdown-pre-process">
                                        <img src={mask} alt="mask-icon"></img>
                                        <Button type="text" onClick={handleMaskManipulation}
                                            style={{ color: "white" }}
                                            className="options-text-btn">
                                            Toolbox 2: Mask Manipulation {showMaskManipulation ? <CaretUpOutlined style={{ marginLeft: '43px' }} />
                                                : <CaretDownOutlined style={{ marginLeft: '43px' }} />}
                                        </Button>
                                        {showMaskManipulation && (
                                            <div className="dropdown-options">
                                                <ul>
                                                    <li onClick={() => handleSlectedListOption('blcktoblue')}
                                                        className={selectedOptionAPI === 'blcktoblue' ? 'selected' : ''}>
                                                        Turn Black Lines into Blue Lines
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('saveown')}
                                                        className={selectedOptionAPI === 'saveown' ? 'selected' : ''}>
                                                        Save Each Object As its Own Mask
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('extout')}
                                                        className={selectedOptionAPI === 'extout' ? 'selected' : ''}>
                                                        Extract Outlines From Filled-In Masks
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('cropimg')}
                                                        className={selectedOptionAPI === 'cropimg' ? 'selected' : ''}>
                                                        Crop Images To 50-pixel Margins
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('cropimg1pix')}
                                                        className={selectedOptionAPI === 'cropimg1pix' ? 'selected' : ''}>
                                                        Crop Images To 1-pixel Border
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('200pix')}
                                                        className={selectedOptionAPI === '200pix' ? 'selected' : ''}>
                                                        Add 100-Pixel Margins
                                                    </li>
                                                </ul>
                                            </div>

                                        )}
                                    </div>
                                    <div className="dropdown-pre-process">
                                        <img src={rotate} alt="rotate-icon"></img>
                                        <Button type="text" onClick={handleRotateResizeFlip}
                                            style={{ color: "white" }}
                                            className="options-text-btn">
                                            Toolbox 3: Rotate, Resize, Flip {showRotateResizeFlip ? <CaretUpOutlined style={{ marginLeft: '38px' }} />
                                                : <CaretDownOutlined style={{ marginLeft: '38px' }} />}
                                        </Button>
                                        {showRotateResizeFlip && (
                                            <div className="dropdown-options">
                                                <ul className="dropdown-options">
                                                    <li style={{ paddingTop: '10px' }} onClick={() => handleSlectedListOption('resimg')}
                                                        className={selectedOptionAPI === 'resimg' ? 'selected' : ''}>
                                                        Resize Width to 400-pixels(Lock Aspect Ratio)
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('rt90cw')}
                                                        className={selectedOptionAPI === 'rt90cw' ? 'selected' : ''}>
                                                        Rotate Images 90 Degree CW
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('rt90ccw')}
                                                        className={selectedOptionAPI === 'rt90ccw' ? 'selected' : ''}>
                                                        Rotate Images 90 Degree CCW
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('rt180cw')}
                                                        className={selectedOptionAPI === 'rt180cw' ? 'selected' : ''}>
                                                        Rotate Images 180 Degree CW
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('rt180ccw')}
                                                        className={selectedOptionAPI === 'rt180ccw' ? 'selected' : ''}>
                                                        Rotate Images 180 Degree CCW
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('fliphor')}
                                                        className={selectedOptionAPI === 'fliphor' ? 'selected' : ''}>
                                                        Flip Images Horizontally
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('flipver')}
                                                        className={selectedOptionAPI === 'flipver' ? 'selected' : ''}>
                                                        Flip Images Vertically
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('rthori')}
                                                        className={selectedOptionAPI === 'rthori' ? 'selected' : ''}>
                                                        Batch Rotate Longest Length Horizontal
                                                    </li>
                                                    <li onClick={() => handleSlectedListOption('greenline')}
                                                        className={selectedOptionAPI === 'greenline' ? 'selected' : ''}>
                                                        Rotate Green Line Horizontal
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div >
                        </div>
                    </Col>
                    <Col id="second_main_col" span={17}>
                        {/* <div className="message-text"> Pre-Process is currently under construction.</div> */}
                        <Row id="second-row">
                            <Col id="second-row-first-col" span={11}>
                                <PreProcessImageConfig option={selectedImage} />
                            </Col>
                            <Col span={1}>
                                <Divider type="vertical" className="divider-style" style={{ height: "100%" }} />
                            </Col>
                            <Col id="second-row-second-col" span={12}>
                                <div className="pre-process-rightpart">
                                    {preProcessApiLoadState === loadState.STARTED && (
                                        <div className="main_container spinner-container pre-process">
                                            <Spin size={"large"} />
                                            <p>Pre-Processing...</p>
                                        </div>
                                    )}
                                    {preProcessApiLoadState === loadState.NOTSTARTED && (
                                        <div className="pre-process-img-display">
                                            {enlargeImage && enlargeImage !== " " && (
                                                <img
                                                    alt="enlargeImage"
                                                    src={enlargeImage}
                                                    id="enlargeImage"
                                                />
                                            )}
                                            {!enlargeImage && <Spin size={"large"} />}
                                            {enlargeImage === " " && (
                                                <span>
                                                    Issue in fetching image.
                                                </span>
                                            )}
                                            &emsp;

                                        </div>
                                    )}

                                </div>
                            </Col>
                        </Row>

                        <Divider
                            className="diver-style"
                            type="horizontal" />

                        <div className="sec-col-lower-half">
                            <div className="display-name-preprocess">
                                {item.fileLocation && <p>The first image of batch : {item.fileLocation} is displayed at the right.</p>}
                            </div>

                            <div className="pre-process-btns">
                                <Button
                                    type="primary"
                                    onClick={() => handleGenerateImage()}
                                    className="generate-img-btn"
                                    disabled={preProcessApiLoadState === loadState.STARTED}
                                    style={{
                                        backgroundColor: preProcessApiLoadState === loadState.STARTED ? 'gray' : undefined,
                                        color: preProcessApiLoadState === loadState.STARTED ? 'white' : undefined
                                    }}
                                >
                                    Generate
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => handleCancel()}
                                    className="cancel-img-btn"
                                    disabled={preProcessApiLoadState === loadState.STARTED}
                                    style={{
                                        backgroundColor: preProcessApiLoadState === loadState.STARTED ? 'gray' : '#003366 !important',
                                        color: preProcessApiLoadState === loadState.STARTED ? 'white' : undefined
                                    }}
                                >
                                    Close
                                </Button>
                            </div>

                        </div>
                    </Col>

                </Row>
            </div >
        </>
    );
};


export default PreProcess;

