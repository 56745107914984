import { Button, Select } from "antd";
import React, {useState} from "react";
import { useDispatch } from "react-redux";
import "./s_style.css";
import { CDTUserReset } from "../../duck/actions/commonActions";
import openNotification from "../../common/Notifications";


const CDTReset = (props: any) => {
  const { row } = props;
  const dispatch = useDispatch();
  const [resetvalueselected, setResetValueSelected] = useState<any>();
  const [resetoptionselected, setResetOptionSelected] = useState<any>();


  const handleSubmitReset = (item:any) => {
    console.log("submitreset", resetvalueselected, "===", resetoptionselected,item);

    let obj:any;
    if(resetoptionselected === "CDT Open upload"){
     obj = { last_uploaded_cdt: null, id: item.id };
    }else if(resetoptionselected === "Graphed Clock Score request"){
      obj = { last_request_clock_scores: null, id: item.id };
    }else if(resetoptionselected === "Graphed Numbering Score request"){
      obj = { last_request_number_writing_scores: null, id: item.id };
    }else if(resetoptionselected === "CDT OpenMax-Clock upload"){
      obj = { last_clock_uploaded_cdtopenmax: null, id: item.id };
    }else if(resetoptionselected === "CDT OpenMax-Numbering upload"){
      obj = { last_numbering_uploaded_cdtopenmax: null, id: item.id };
    }else{
      
      let errorObj = {
        type: "error",
        message: "Please Select a Plan to reset. ",
    };
    openNotification(errorObj);
    return
    }
  
  dispatch(CDTUserReset(obj));
  };

  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    // { value: "1", key: "1", label: "Cdt Open upload" }
    setResetValueSelected(value.value)
    setResetOptionSelected(value.label)

  };


  return (
    <div style={{ display:"flex"}}>

      <Select
        labelInValue
        className="reset-dropdown"
        defaultValue={{ value: '0', label: 'Select an Option' }}
        onChange={handleChange}
        options={[
          {
            value: '1',
            label: 'CDT Open upload',
          },
          {
            value: '2',
            label: 'Graphed Clock Score request',
          }, {
            value: '3',
            label: 'Graphed Numbering Score request',
          }, {
            value: '4',
            label: 'CDT OpenMax-Clock upload',
          },{
            value: '5',
            label: 'CDT OpenMax-Numbering upload',
          }
        ]}
      />
      <Button className="active-button" onClick={() => handleSubmitReset(row)}>Reset</Button>
    </div>
  );
};

export default CDTReset;
