import React from "react";

import nii from '../assets/images/NII-FE-Bscan.svg';
import pixel_100_margin from '../assets/preprocess-demo-images/all 100 pixels to margins.png';
import crop_margin_1pixel from '../assets/preprocess-demo-images/crop margins to 1 pixel.png';
import extract_outlines_filled from '../assets/preprocess-demo-images/extract outlines from filled in masks.png';
import isolate_each_object_onto_its_own_canvas from "../assets/preprocess-demo-images/isolate each object onto its own canvas.png"
import flip_horizontal from '../assets/preprocess-demo-images/flip horizontal.png';
import flip_vertical from '../assets/preprocess-demo-images/flip vertical.png';
import green_line_rotation from '../assets/preprocess-demo-images/green line rotation tool.png';
import horizontal_batch_rotation from '../assets/preprocess-demo-images/horizontal batch rotation tool 1.png';
import resize_width_to_400_pixel from '../assets/preprocess-demo-images/resize width to 400 pixels.png';
import rotate_180_deg_CCW from '../assets/preprocess-demo-images/rotate 180 deg CCW.png';
import rotate_180_deg_CW from '../assets/preprocess-demo-images/rotate 180 deg CW.png';
import rotate_90_deg_CCW from '../assets/preprocess-demo-images/rotate 90 deg CCW.png';
import rotate_90_deg_CW from '../assets/preprocess-demo-images/rotate 90 deg CW.png';
import crop_to_50_pixel_margins from '../assets/preprocess-demo-images/crop_to_50-pixel_margins.png';
import Turn_black_Lines_blue from '../assets/preprocess-demo-images/turn_black_lines_blue.png';
import Extarct_one_of_4_colors from '../assets/preprocess-demo-images/extract_one_of-four_colors.png';


interface ImageOption {
    [key: string]: string;
}

const PreProcessImageConfig = ({ option }: { option: string }) => {
    const ImageOptions: ImageOption[] = [
        { key: 'default', value: nii },

        //OutlinesExtraction 
        { key: 'redfromwin', value: Extarct_one_of_4_colors },
        { key: 'redfrommac', value: Extarct_one_of_4_colors },
        { key: 'blue', value: Extarct_one_of_4_colors },
        { key: 'extgreen', value: Extarct_one_of_4_colors },
        { key: 'extpink', value: Extarct_one_of_4_colors },

        //MaskManipulation
        { key: 'blcktoblue', value: Turn_black_Lines_blue },
        { key: 'saveown', value: isolate_each_object_onto_its_own_canvas },
        { key: 'extout', value: extract_outlines_filled },
        { key: 'cropimg', value: crop_to_50_pixel_margins },
        { key: 'cropimg1pix', value: crop_margin_1pixel },
        { key: '200pix', value: pixel_100_margin },

        //Rotate,Resize,Flip
        { key: 'resimg', value: resize_width_to_400_pixel },
        { key: 'rt90cw', value: rotate_90_deg_CW },
        { key: 'rt90ccw', value: rotate_90_deg_CCW },
        { key: 'rt180cw', value: rotate_180_deg_CW },
        { key: 'rt180ccw', value: rotate_180_deg_CCW },
        { key: 'fliphor', value: flip_horizontal },
        { key: 'flipver', value: flip_vertical },
        { key: 'rthori', value: horizontal_batch_rotation },
        { key: 'greenline', value: green_line_rotation }
    ];

    const selectedImage = ImageOptions.find(item => item.key === option)?.value || nii;

    return (
        <div className="demo-image-container" style={{ padding: '45px 10px 10px 15px' }}>
            <img
                alt="demoImage"
                src={selectedImage}
                id="selectedOption"
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            />
        </div>
    );
}

export default PreProcessImageConfig;
