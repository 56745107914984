/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import './style.css';
import { DATATURE_APP_POST_PROCESS_URL } from '../../config'
import { useDispatch } from "react-redux";
import { IFRAME_LOAD_SUCCESS } from "../../duck/types/types";


const PostProcessIFrame = () => {
    const dispatch = useDispatch();
    const onloadFunction = () =>{
        dispatch ({ type : IFRAME_LOAD_SUCCESS})
    }
    return (
        <div className="iframe-container">
            <div >
            <iframe src={DATATURE_APP_POST_PROCESS_URL}
            width='100%' height={500} 
            onLoad={onloadFunction}
            ></iframe>
            </div>
      </div> 
    );
};


export default PostProcessIFrame;

