import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./s_style.css";
import CDTFilesofaUserToAdmin from "../cdtfilesofauser";
import { getBlobsCDTFilesOfAUser } from "../../duck/actions/dashboardActions";


const ViewCDTfilesofaUser = (props: any) => {
  const { row } = props;
  const dispatch = useDispatch();
  const [selectedUserRow, setSelectedUserRow] = useState<any>();
  const [isviewFilesModalVisible, setViewFilesModalVisible] = useState(false);


  const handleViewFilesOpen = (item: any) => {
    try {
      setViewFilesModalVisible(true);
      const requestBody = {
        containerName: item.username,
      };
      setSelectedUserRow(item);
      dispatch(getBlobsCDTFilesOfAUser(requestBody));
    } catch (err) {
      console.log(err);
    }


  };

  const handleViewFilesCancel = () => {
    setViewFilesModalVisible(false)
  };

  return (
    <div>
      <Modal
        visible={isviewFilesModalVisible}
        onCancel={handleViewFilesCancel}
        footer={null}
        width="90%"
        centered
        bodyStyle={{ height: "60%" }}
      >
        <CDTFilesofaUserToAdmin item={selectedUserRow} />
      </Modal>

      <Button className="active-button" onClick={() => handleViewFilesOpen(row)}>View Files</Button>
    </div>
  );
};

export default ViewCDTfilesofaUser;
